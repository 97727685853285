import { combineEpics } from 'redux-observable'
import { of, merge } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { userEpics } from './user'
import { appEpics } from './app'
import { supportEpics } from './support'
import { quizEpics } from './quiz'
import { adminEpics } from './admin'

const epics = [userEpics, appEpics, supportEpics, quizEpics, adminEpics]

/**
 * The rootEpic constains global error handler. You can read more about it here:
 * redux-observable.js.org/docs/basics/SettingUpTheMiddleware#adding-global-error-handler
 * https://github.com/redux-observable/redux-observable/issues/591#issuecomment-439312570
 */
const rootEpic = (action$, state$) =>
  combineEpics(...epics)(action$, state$).pipe(
    catchError((error, caught) =>
      merge(of({ type: 'CRITICAL_ERROR', error }), caught)
    )
  )

export default rootEpic
