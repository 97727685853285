/**
 * Used for fetching statistic.
 */
export const REQUEST_GET_ADMIN_STATISTICS = 'REQUEST_GET_ADMIN_STATISTICS'
export const GET_ADMIN_STATISTICS_SUCCESS = 'GET_ADMIN_STATISTICS_SUCCESS'
export const GET_ADMIN_STATISTICS_FAILED = 'GET_ADMIN_STATISTICS_FAILED'

/**
 * Used for fetching admin invoices.
 */
export const REQUEST_GET_ADMIN_INVOICES = 'REQUEST_GET_ADMIN_INVOICES'
export const GET_ADMIN_INVOICES_SUCCESS = 'GET_ADMIN_INVOICES_SUCCESS'
export const GET_ADMIN_INVOICES_FAILED = 'GET_ADMIN_INVOICES_FAILED'

/**
 * Used for fetching admin all vendors.
 */
export const REQUEST_GET_VENDORS = 'REQUEST_GET_VENDORS'
export const GET_VENDORS_SUCCESS = 'GET_VENDORS_SUCCESS'
export const GET_VENDORS_FAILED = 'GET_VENDORS_FAILED'

/**
 * Used for getting vendor by id.
 */
export const REQUEST_GET_VENDOR_BY_ID = 'REQUEST_GET_VENDOR_BY_ID'
export const GET_VENDOR_BY_ID_SUCCESS = 'GET_VENDOR_BY_ID_SUCCESS'
export const GET_VENDOR_BY_ID_FAILED = 'GET_VENDOR_BY_ID_FAILED'

/**
 * Used for update vendor by id - notes + lock / unlock.
 */
export const REQUEST_UPDATE_VENDOR_BY_ID = 'REQUEST_UPDATE_VENDOR_BY_ID'
export const UPDATE_VENDOR_BY_ID_SUCCESS = 'UPDATE_VENDOR_BY_ID_SUCCESS'
export const UPDATE_VENDOR_BY_ID_FAILED = 'UPDATE_VENDOR_BY_ID_FAILED'

/**
 * Used for delete vendor by id.
 */
export const REQUEST_DELETE_VENDOR_BY_ID = 'REQUEST_DELETE_VENDOR_BY_ID'
export const DELETE_VENDOR_BY_ID_SUCCESS = 'DELETE_VENDOR_BY_ID_SUCCESS'
export const DELETE_VENDOR_BY_ID_FAILED = 'DELETE_VENDOR_BY_ID_FAILED'

/**
 * Used for update vendor trial date by id
 */
export const REQUEST_UPDATE_VENDOR_TRIAL_DATE_BY_ID =
  'REQUEST_UPDATE_VENDOR_TRIAL_DATE_BY_ID'
export const UPDATE_VENDOR_TRIAL_DATE_BY_ID_SUCCESS =
  'UPDATE_VENDOR_TRIAL_DATE_BY_ID_SUCCESS'
export const UPDATE_VENDOR_TRIAL_DATE_BY_ID_FAILED =
  'UPDATE_VENDOR_TRIAL_DATE_BY_ID_FAILED'

/**
 * Used for seding vendor email confirm reminder
 */
export const REQUEST_EMAIL_CONFIRM_REMINDER = 'REQUEST_EMAIL_CONFIRM_REMINDER'
export const SEND_EMAIL_CONFIRM_REMINDER_SUCCESS =
  'SEND_EMAIL_CONFIRM_REMINDER_SUCCESS'
export const SEND_EMAIL_CONFIRM_REMINDER_FAILED =
  'SEND_EMAIL_CONFIRM_REMINDER_FAILED'
