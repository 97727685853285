// TODO: Probably move questions to a separate reducer in order to avoid that
// bad nesting. It just looks awful.
import { persistReducer, createMigrate } from 'redux-persist'
import { omit } from 'ramda'

import * as types from 'core/redux/types/quiz'
import * as userTypes from 'core/redux/types/user'

import { getSessionStorage, isDebug } from 'utils/app'
import { CONTACTS_PER_PAGE } from 'constants/contacts'

const removeQuizFromList = (id, state) => state.list.filter(q => q._id !== id)

const updateQuizStatisticsInList = quiz => state =>
  state.statistics.map(q => (quiz._id === q._id ? quiz : q))

const updateRespondentInList = respondent => state =>
  state?.contacts?.list.map(r => (r?._id === respondent?._id ? respondent : r))

const updateQuizStatusOnList = (quiz, state) =>
  state.list.map(q => (q._id !== quiz._id ? q : { ...q, active: quiz.active }))

const deleteRespondentFromList = (id, state) =>
  state.contacts.list.map(respondent =>
    respondent._id === id
      ? {
          ...respondent,
          __deleted: true
        }
      : respondent
  )

const defaultState = {
  // Used for storing quizzes to edit.
  entries: {},
  // used for external flow.
  respondent: {
    uid: null,
    score: 0
  },
  contacts: {
    list: [],
    pagination: {
      offset: 0,
      total: 0,
      done: false
    },
    perPage: CONTACTS_PER_PAGE,
    // A map that stores answers to quiz questions given by respondents.
    entries: {}
  },
  external: {},
  // Used for quizzes list. This is different than entries.
  list: [],
  templates: [],
  // Used mostly for statistics page.
  statistics: [],
  statisticsEvaluation: {
    results: [],
    status: {
      done: false,
      offset: 0,
      total: 0
    }
  },
  // Used in payments page.
  transaction: {
    quiz: null,
    paymentIntent: ''
  },
  score: {
    active: false,
    showAnalysisBeforeAnswersInEmail: false,
    showAnalysisOnThankYouPage: false,
    showScoresBeforeAnswersInEmail: false,
    showScoresOnThankYouPage: false
  }
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.DELETE_RESPONDENT_SUCCESS:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          list: deleteRespondentFromList(action.id, state)
        }
      }
    case types.CREATE_QUIZ_SUCCESS:
      return {
        ...state,
        entries: {
          ...state.entries,
          [action.data._id]: {
            ...action.data,
            questions: [],
            answers: [],
            titles: []
          }
        }
      }
    case types.GET_RESPONDENT_QUIZ_SUCCESS:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          entries: {
            ...state.contacts.entries,
            [action.data.respondent._id]: action.data
          }
        }
      }
    case types.CREATE_RESPONDENT_SUCCESS:
      return {
        ...state,
        transaction: {
          ...state.transaction,
          paymentIntent: action.data
        }
      }
    case types.CREATE_RESPONDENT_BY_COUPON_SUCCESS:
      return {
        ...state,
        respondent: action.data
      }
    case types.GET_RESPONDENTS_SUCCESS:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          list: action.data.results,
          pagination: action.data.status
        }
      }
    case types.GET_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: action.data.results
      }
    case types.RESET_QUIZ_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: updateQuizStatisticsInList(action.data)(state)
      }
    case types.GET_STATISTIC_EVALUATION_SUCCESS:
      return {
        ...state,
        statisticsEvaluation: { ...action.data }
      }
    case types.GET_QUIZ_FOR_TRANSACTION_SUCCESS:
      return {
        ...state,
        transaction: {
          quiz: action.data
        }
      }
    case types.GET_QUIZZES_SUCCESS:
      return {
        ...state,
        list: action.data.results
      }
    case types.GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: action.data.results
      }
    case types.GET_QUIZ_FOR_FLOW_SUCCESS:
      return {
        ...state,
        external: {
          ...state.external,
          [action.data.quiz._id]: action.data.quiz
        },
        respondent: action.data.respondent
      }
    case types.MARK_QUIZ_AS_STARTED_SUCCESS:
      return {
        ...state,
        respondent: {
          ...state.respondent,
          quizStarted: true
        }
      }
    case types.MARK_QUIZ_AS_FINISHED_SUCCESS:
      return {
        ...state,
        respondent: {
          ...state.respondent,
          ...action.data,
          quizFinished: true
        }
      }
    case types.GET_PAID_QUIZ_BY_ID_SUCCESS:
      return {
        ...state,
        external: {
          ...state.external,
          [action.data.quiz._id]: action.data.quiz
        },
        respondent: {
          uid: action.data.uid
        }
      }
    case types.GET_QUIZ_BY_RESPONDENT_UID_SUCCESS:
      return {
        ...state,
        external: {
          ...state.external,
          [action.data.quiz._id]: action.data.quiz
        },
        respondent: {
          uid: action.data.respondentUid
        }
      }
    case types.DELETE_QUIZ_SUCCESS:
      return {
        ...state,
        entries: omit([action.id])(state.entries),
        list: removeQuizFromList(action.id, state)
      }
    case types.GET_QUIZ_BY_ID_SUCCESS:
      return {
        ...state,
        entries: {
          ...state.entries,
          [action.data._id]: action.data
        }
      }
    case types.UPDATE_OPT_IN_STEP_SUCCESS:
    case types.UPDATE_QUIZ_SUCCESS:
      return {
        ...state,
        entries: {
          ...state.entries,
          [action.data._id]: {
            ...state.entries[action.data._id],
            ...action.data
          }
        },
        list: updateQuizStatusOnList(action.data, state)
      }
    /**
     * Please note that all of these actions react the same because quiz is
     * fetched after every one of these actions.
     */
    case types.CREATE_QUESTION_SUCCESS:
    case types.UPDATE_QUESTION_SUCCESS:
    case types.UPDATE_QUESTION_WITH_ANSWERS_SUCCESS:
    case types.CREATE_GROUPED_QUESTION_WITH_SETTINGS_SUCCESS:
    case types.CREATE_GROUPED_QUESTION_SUCCESS:
    case types.UPDATE_GROUPED_QUESTION_SETTINGS_SUCCESS:
    case types.DELETE_QUESTION_SUCCESS:
    case types.CREATE_ANSWER_SUCCESS:
    case types.UPDATE_ANSWER_SUCCESS:
    case types.DELETE_ANSWER_SUCCESS:
    case types.CREATE_TITLE_SUCCESS:
    case types.UPDATE_TITLE_SUCCESS:
    case types.DELETE_TITLE_SUCCESS:
    case types.CREATE_SCORE_EMAIL_REPLY_FAILED:
    case types.UPDATE_EMAIL_FOR_SCORING_SUCCESS:
    case types.DELETE_SINGLE_EMAIL_FOR_SCORING_SUCCESS:
    case types.DELETE_ALL_EMAIL_FOR_SCORING_SUCCESS:
    case types.CREATE_DEFAULT_ANSWERS_FOR_GROUPED_QUESTION_SUCCESS:
    case types.BULK_QUIZ_UPDATE_SUCCESS:
    case types.UPDATE_OPT_IN_INFORMATION_TO_GATHER_SUCCESS: // fetch a quiz using getQuizById in epics
      return {
        ...state,
        entries: {
          ...state.entries,
          [action.data._id]: action.data
        }
      }
    // specific case when respondent notes are updated an contact results have
    // to be updated too.
    case types.UPDATE_RESPONDENT_SUCCESS:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          list: updateRespondentInList(action.data)(state)
        }
      }
    case userTypes.LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

const migrations = {
  0: state => {
    return {
      ...state,
      newQuiz: {}
    }
  },
  1: state => {
    return {
      entries: state.entries,
      list: state.list || []
    }
  }
}

const quizPersistConfig = {
  version: 1,
  key: 'quiz',
  storage: getSessionStorage(),
  migrate: createMigrate(migrations, { debug: isDebug() }),
  blacklist: [
    'entries',
    'list',
    'external',
    'contacts',
    'respondent',
    'statistics',
    'statisticsEvaluation',
    'transaction'
  ]
}

export default persistReducer(quizPersistConfig, reducer)
