import ApiClient from 'core/ApiClient'

/**
 *
 * @module services/SupportService
 * @description A service for handling requests connected with support enpdpoints.
 */
const SupportService = {
  /**
   * Send support ticket to quizchecks admins.
   * @name sendSupportTicket
   * @method
   * @static
   * @param {Object} payload Payload that is compatible with `SUPPORT_TICKET_MODEL`.
   * @param {String} payload.email
   * @param {String} payload.quizUrl
   * @param {String} payload.subject
   * @param {String} payload.message
   * @returns {Promise}
   */
  sendSupportTicket: payload =>
    ApiClient.post({
      url: '/support',
      data: payload
    })
}

export default SupportService
