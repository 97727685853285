export const REQUEST_LOGIN = 'REQUEST_LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'

export const REQUEST_LOGOUT = 'REQUEST_LOGOUT'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILED = 'LOGOUT_FAILED'

/**
 * Used for initializing the reset password flow.
 */
export const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED'

/**
 * Used for setting new new password.
 */
export const REQUEST_SET_NEW_PASSWORD = 'REQUEST_SET_NEW_PASSWORD'
export const SET_NEW_PASSWORD_SUCCESS = 'SET_NEW_PASSWORD_SUCCESS'
export const SET_NEW_PASSWORD_FAILED = 'SET_NEW_PASSWORD_FAILED'

/**
 * Used for updating user profile.
 */
export const REQUEST_UPDATE_PROFILE = 'REQUEST_UPDATE_PROFILE'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED'

/**
 * Used for updating password as authorized user.
 */
export const REQUEST_UPDATE_PASSWORD = 'REQUEST_UPDATE_PASSWORD'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAILED = 'UPDATE_PASSWORD_FAILED'

/**
 * Used for updating user email.
 */
export const REQUEST_UPDATE_EMAIL = 'REQUEST_UPDATE_EMAIL'
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS'
export const UPDATE_EMAIL_FAILED = 'UPDATE_EMAIL_FAILED'

/**
 * Used for user deletion.
 */
export const REQUEST_DELETE_ACCOUNT = 'REQUEST_DELETE_ACCOUNT'
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS'
export const DELETE_ACCOUNT_FAILED = 'DELETE_ACCOUNT_FAILED'
export const INITIALIZE_DELETE_ACCOUNT = 'INITIALIZE_DELETE_ACCOUNT'

/**
 * Used for getting profile details.
 */
export const REQUEST_GET_PROFILE = 'REQUEST_GET_PROFILE'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
export const GET_PROFILE_FAILED = 'GET_PROFILE_FAILED'

/**
 * Used for vendor plan subscription.
 */
export const REQUEST_GET_SUBSCRIPTION_PLAN_BY_ID =
  'REQUEST_GET_SUBSCRIPTION_PLAN_BY_ID'
export const GET_SUBSCRIPTION_PLAN_BY_ID_SUCCESS =
  'GET_SUBSCRIPTION_PLAN_BY_ID_SUCCESS'
export const GET_SUBSCRIPTION_PLAN_BY_ID_FAILED =
  'GET_SUBSCRIPTION_PLAN_BY_ID_FAILED'

/**
 * Used for creating vendor. Used in VendorRegistrationPage.
 */
export const REQUEST_CREATE_VENDOR = 'REQUEST_CREATE_VENDOR'
export const CREATE_VENDOR_SUCCESS = 'CREATE_VENDOR_SUCCESS'
export const CREATE_VENDOR_FAILED = 'CREATE_VENDOR_FAILED'

/**
 * Used for simple caching of vendor subscription plan request.
 * The caching is introduced so that VAT Layer API is not exhausted too quickly.
 */
export const SET_SUBSCRIPTION_PLAN = 'SET_SUBSCRIPTION_PLAN'

/**
 * Used in ChangeSubscriptionForm.
 */
export const REQUEST_GET_SUBSCRIPTION_PLANS = 'REQUEST_GET_SUBSCRIPTION_PLANS'
export const GET_SUBSCRIPTION_PLANS_SUCCESS = 'GET_SUBSCRIPTION_PLANS_SUCCESS'
export const GET_SUBSCRIPTION_PLANS_FAILED = 'GET_SUBSCRIPTION_PLANS_FAILED'

/**
 * Used for updating user subscription.
 */
export const REQUEST_UPDATE_SUBSCRIPTION_PLAN =
  'REQUEST_UPDATE_SUBSCRIPTION_PLAN'
export const UPDATE_SUBSCRIPTION_PLAN_SUCCESS =
  'UPDATE_SUBSCRIPTION_PLAN_SUCCESS'
export const UPDATE_SUBSCRIPTION_PLAN_FAILED = 'UPDATE_SUBSCRIPTION_PLAN_FAILED'

/**
 * Used for cancelling user subscription in profile settings.
 */
export const REQUEST_CANCEL_SUBSCRIPTION_PLAN =
  'REQUEST_CANCEL_SUBSCRIPTION_PLAN'
export const CANCEL_SUBSCRIPTION_PLAN_SUCCESS =
  'CANCEL_SUBSCRIPTION_PLAN_SUCCESS'
export const CANCEL_SUBSCRIPTION_PLAN_FAILED = 'CANCEL_SUBSCRIPTION_PLAN_FAILED'

/**
 * Used for fetching vendor invoices.
 */
export const REQUEST_GET_VENDOR_INVOICES = 'REQUEST_GET_VENDOR_INVOICES'
export const GET_VENDOR_INVOICES_SUCCESS = 'GET_VENDOR_INVOICES_SUCCESS'
export const GET_VENDOR_INVOICES_FAILED = 'GET_VENDOR_INVOICES_FAILED'

/**
 * Actions used for creating user account through registration form.
 */
export const REQUEST_CREATE_ACCOUNT = 'REQUEST_CREATE_ACCOUNT'
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS'
export const CREATE_ACCOUNT_FAILED = 'CREATE_ACCOUNT_FAILED'

/**
 * Used for updating subscription payment method. Called during new subscription
 * payment flow.
 *  Added: Tue, 29 Sep 2020 15:24:56 +0200
 */
export const REQUEST_UPDATE_PAYMENT_METHOD = 'REQUEST_UPDATE_PAYMENT_METHOD'
export const UPDATE_PAYMENT_METHOD_SUCCESS = 'UPDATE_PAYMENT_METHOD_SUCCESS'
export const UPDATE_PAYMENT_METHOD_FAILED = 'UPDATE_PAYMENT_METHOD_FAILED'

export const REQUEST_DELETE_PAYMENT_METHOD = 'REQUEST_DELETE_PAYMENT_METHOD'
export const DELETE_PAYMENT_METHOD_SUCCESS = 'DELETE_PAYMENT_METHOD_SUCCESS'
export const DELETE_PAYMENT_METHOD_FAILED = 'DELETE_PAYMENT_METHOD_FAILED'
