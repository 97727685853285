/**
 * @file Main entry point for the frontend application. Extends application's
 * capabilities with redux state, translations, theming etc.
 * @author Rafał Wyszomirski
 **/
import React, { Fragment } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import {
  ThemeProvider,
  THEME_ID,
  StyledEngineProvider
} from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { SnackbarProvider } from 'notistack'
import { ThemeProvider as StyledThemeProvider } from '@emotion/react'
import { Global } from '@emotion/react'
import { initTracking } from 'detrucker'

import useDefaultLanguage from 'hooks/useDefaultLanguage'

import ReduxStore from 'core/redux/store'
import { theme, styledTheme } from 'core/styles/theme'
import GlobalStyles from 'core/styles/GlobalStyles'

import Routes from 'core/Routes'

function App(_props) {
  useDefaultLanguage()

  initTracking({
    apiKeys: {
      amplitude: '3e0ced684bdfd7d6c3fff8861ae5c5bd'
    },
    limitations: {
      allowedDomainsRegex: /frontlead.io|^quizchecks/,
      onlyAuthenticatedUsersAllowed: true
    },
    userIdStorage: {
      key: 'FRONTLEAD_ID'
    }
  })

  return (
    <Fragment>
      <CssBaseline />
      <Global styles={GlobalStyles} />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Provider store={ReduxStore.store}>
          <PersistGate loading={null} persistor={ReduxStore.persistor}>
            <StyledThemeProvider theme={styledTheme}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={{ [THEME_ID]: theme }}>
                  <SnackbarProvider>
                    <Routes />
                  </SnackbarProvider>
                </ThemeProvider>
              </StyledEngineProvider>
            </StyledThemeProvider>
          </PersistGate>
        </Provider>
      </LocalizationProvider>
    </Fragment>
  )
}

export default App
