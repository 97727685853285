import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { STORAGE_KEYS } from 'constants/storage'
import { isEmbeddedExternally } from 'utils/dom'

import EN from './translations/en.json'
import DE from './translations/de.json'
import PL from './translations/pl.json'
import ES from './translations/es.json'
import FR from './translations/fr.json'
import HI from './translations/hi.json'
import IT from './translations/it.json'
import JA from './translations/ja.json'
import NL from './translations/nl.json'
import PT from './translations/pt.json'
import RU from './translations/ru.json'
import TR from './translations/tr.json'
import ZH from './translations/zh.json'

export const APP_LANGUAGES = [
  'de',
  'en',
  'es',
  'fr',
  'hi',
  'it',
  'ja',
  'nl',
  'pl',
  'pt',
  'ru',
  'tr',
  'zh'
]

const getDefaultLanguage = () => {
  const detectedLanguage =
    navigator?.language?.substr(0, 2) ||
    navigator?.browserLanguage?.substr(0, 2)
  return APP_LANGUAGES.includes(detectedLanguage) ? detectedLanguage : 'en'
}

const getStoredLanguage = () => {
  if (isEmbeddedExternally()) {
    return getDefaultLanguage()
  }

  const storedLanguage = window.localStorage.getItem(STORAGE_KEYS.I18N_LANGUAGE)

  return APP_LANGUAGES.includes(storedLanguage)
    ? storedLanguage
    : getDefaultLanguage()
}

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      de: DE,
      en: EN,
      es: ES,
      fr: FR,
      hi: HI,
      it: IT,
      ja: JA,
      nl: NL,
      pl: PL,
      pt: PT,
      ru: RU,
      tr: TR,
      zh: ZH
    },
    // This is a default key provided by language detector.
    lng: getStoredLanguage() || getDefaultLanguage(),
    fallbackLng: 'en', // use en if detected lng is not available
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n
