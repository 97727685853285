import * as types from 'core/redux/types/app'

export const changeAppLanguage = data => ({
  type: types.CHANGE_APP_LANGUAGE,
  data
})

export const appLanguageChangeSuccess = data => ({
  type: types.APP_LANGUAGE_CHANGE_SUCCESS,
  data
})

export const appLanguageChangeFailed = error => ({
  type: types.APP_LANGUAGE_CHANGE_FAILED,
  error
})
