/**
 * @module epics/user
 * @description Business logic connected with the user. It involes things like
 * login, update profile information and so on.
 */
import { combineEpics, ofType } from 'redux-observable'
import { of, from, merge } from 'rxjs'
import {
  concatMap,
  mergeMap,
  catchError,
  tap,
  delay,
  finalize,
  switchMap
} from 'rxjs/operators'

import ReduxStore from 'core/redux/store'
import * as types from 'core/redux/types/user'
import * as actions from 'core/redux/actions/user'
import * as appActions from 'core/redux/actions/app'

import ApiClient from 'core/ApiClient'

import AuthService from 'services/AuthService'
import UserService from 'services/UserService'

const requestCreateAccount = action$ =>
  action$.pipe(
    ofType(types.REQUEST_CREATE_ACCOUNT),
    mergeMap(action =>
      from(UserService.createAccount(action.data)).pipe(
        mergeMap(response =>
          of(actions.createAccountSuccess(response.data)).pipe(
            finalize(() => action?.meta?.onSuccess())
          )
        ),
        catchError(err =>
          of(actions.createAccountFailed(err)).pipe(
            finalize(() => action?.meta?.onError(err))
          )
        )
      )
    )
  )

/**
 * Simple epic for logging in. Relies on AuthService and ApiClient.
 * @method
 * @static
 * @name requestLogin
 */
const requestLogin = (action$, state$) =>
  action$.pipe(
    ofType(types.REQUEST_LOGIN),
    mergeMap(action =>
      from(AuthService.login(action.data.payload)).pipe(
        tap(_ => ApiClient.setStorageType(action.data.config.shouldPersist)),
        tap(response => ApiClient.setAuthorizationToken(response.data.token)),
        mergeMap(response =>
          of(actions.loginSuccess(response.data)).pipe(
            finalize(() => action?.meta?.onSuccess(response))
          )
        ),
        catchError(err =>
          of(actions.loginFailed(err)).pipe(
            finalize(() => action?.meta?.onError(err))
          )
        )
      )
    )
  )

/**
 * If user successfully logs in, automatically change their language.
 * @name onLoginSuccess
 * @method
 * @static
 */
const onLoginSuccess = action$ =>
  action$.pipe(
    ofType(types.LOGIN_SUCCESS),
    mergeMap(action =>
      of(appActions.changeAppLanguage(action.data.user.language))
    )
  )

/**
 * Simple epic for logging out. Most importantly, the successful logout is
 * handled in reducers in order to reset state to default.
 * @method
 * @static
 * @name requestLogout
 */
const requestLogout = action$ =>
  action$.pipe(
    ofType(types.REQUEST_LOGOUT),
    mergeMap(_ =>
      of(AuthService.logout()).pipe(
        mergeMap(_ =>
          of(actions.logoutSuccess()).pipe(
            finalize(() => ReduxStore.persistor.purge())
          )
        ),
        catchError(err => of(actions.logoutFailed(err)))
      )
    )
  )

const requestResetPassword = action$ =>
  action$.pipe(
    ofType(types.REQUEST_RESET_PASSWORD),
    mergeMap(action =>
      from(AuthService.requestResetPassword(action.data)).pipe(
        mergeMap(_ => of(actions.resetPasswordSuccess())),
        catchError(err => of(actions.resetPasswordFailed(err)))
      )
    )
  )

const requestSetNewPassword = action$ =>
  action$.pipe(
    ofType(types.REQUEST_SET_NEW_PASSWORD),
    mergeMap(action =>
      from(AuthService.setNewPassword(action.data)).pipe(
        mergeMap(data => of(actions.setNewPasswordSuccess(data))),
        catchError(err => of(actions.setNewPasswordFailed(err)))
      )
    )
  )

const requestGetProfile = action$ =>
  action$.pipe(
    ofType(types.REQUEST_GET_PROFILE),
    mergeMap(action =>
      from(UserService.getProfile()).pipe(
        mergeMap(response =>
          of(actions.getProfileSuccess(response.data)).pipe(
            finalize(() => action?.meta?.onSuccess(response.data))
          )
        ),
        catchError(err =>
          of(actions.getProfileFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

const requestUpdateProfile = action$ =>
  action$.pipe(
    ofType(types.REQUEST_UPDATE_PROFILE),
    mergeMap(action =>
      merge(
        from(UserService.updateProfile(action.data)).pipe(
          mergeMap(_ =>
            from(UserService.getProfile()).pipe(
              mergeMap(response =>
                of(actions.updateProfileSuccess(response.data)).pipe(
                  finalize(() => action?.meta?.onSuccess())
                )
              )
            )
          ),
          catchError(err =>
            of(actions.updateProfileFailed(err)).pipe(
              finalize(() => action?.meta?.onError())
            )
          )
        ),
        of(appActions.changeAppLanguage(action.data.language))
      )
    )
  )

const requestUpdatePassword = action$ =>
  action$.pipe(
    ofType(types.REQUEST_UPDATE_PASSWORD),
    mergeMap(action =>
      from(UserService.updatePassword(action.data)).pipe(
        mergeMap(response => of(actions.updatePasswordSuccess(response.data))),
        catchError(err => of(actions.updatePasswordFailed(err)))
      )
    )
  )

const requestUpdateEmail = action$ =>
  action$.pipe(
    ofType(types.REQUEST_UPDATE_EMAIL),
    mergeMap(action =>
      from(UserService.updateProfile(action.data)).pipe(
        mergeMap(response => of(actions.updateEmailSuccess(response.data))),
        catchError(err => of(actions.updateEmailFailed(err)))
      )
    )
  )

const requestGetSubscriptionPlanById = action$ =>
  action$.pipe(
    ofType(types.REQUEST_GET_SUBSCRIPTION_PLAN_BY_ID),
    switchMap(action =>
      from(UserService.getSubscriptionPlanById(action.data)).pipe(
        switchMap(response =>
          of(actions.getSubscriptionPlanByIdSuccess(response.data)).pipe(
            finalize(() => action?.meta?.onSuccess(response.data))
          )
        ),
        catchError(err =>
          of(actions.getSubscriptionPlanByIdFailed(err)).pipe(
            finalize(() => action?.meta?.onError(err))
          )
        )
      )
    )
  )

const requestGetSubscriptionPlans = action$ =>
  action$.pipe(
    ofType(types.REQUEST_GET_SUBSCRIPTION_PLANS),
    mergeMap(action =>
      from(UserService.getSubscriptionPlans()).pipe(
        mergeMap(response =>
          of(actions.getSubscriptionPlansSuccess(response.data)).pipe(
            finalize(() => action?.meta?.onSuccess(response.data))
          )
        ),
        catchError(err =>
          of(actions.getSubscriptionPlansFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

const requestUpdateSubscriptionPlan = action$ =>
  action$.pipe(
    ofType(types.REQUEST_UPDATE_SUBSCRIPTION_PLAN),
    mergeMap(action =>
      from(UserService.updateSubscriptionPlan(action.data)).pipe(
        mergeMap(response =>
          of(actions.updateSubscriptionPlanSuccess(response.data)).pipe(
            finalize(() => action?.meta?.onSuccess(response.data))
          )
        ),
        catchError(err =>
          of(actions.updateSubscriptionPlanFailed(err)).pipe(
            finalize(() => action?.meta?.onError(err?.response?.data))
          )
        )
      )
    )
  )

const requestCancelSubscriptionPlan = action$ =>
  action$.pipe(
    ofType(types.REQUEST_CANCEL_SUBSCRIPTION_PLAN),
    concatMap(action =>
      from(UserService.cancelSubscriptionPlan()).pipe(
        concatMap(_ => from(UserService.getProfile())),
        concatMap(response =>
          of(actions.cancelSubscriptionPlanSuccess(response.data)).pipe(
            finalize(() => action?.meta?.onSuccess())
          )
        ),
        catchError(err =>
          of(actions.cancelSubscriptionPlanFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

const requestUpdatePaymentMethod = action$ =>
  action$.pipe(
    ofType(types.REQUEST_UPDATE_PAYMENT_METHOD),
    mergeMap(action =>
      from(UserService.updatePaymentMethod(action.data)).pipe(
        mergeMap(response =>
          of(actions.updatePaymentMethodSuccess(response.data)).pipe(
            finalize(() => action?.meta?.onSuccess())
          )
        ),
        catchError(err =>
          of(actions.updatePaymentMethodFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

const requestDeletePaymentMethod = action$ =>
  action$.pipe(
    ofType(types.REQUEST_DELETE_PAYMENT_METHOD),
    mergeMap(action =>
      from(UserService.deletePaymentMethod()).pipe(
        mergeMap(_ =>
          from(UserService.getProfile()).pipe(
            mergeMap(response =>
              of(actions.deletePaymentMethodSuccess(response.data)).pipe(
                finalize(() => action?.meta?.onSuccess())
              )
            )
          )
        ),
        catchError(err =>
          of(actions.deletePaymentMethodFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

const requestCreateVendor = action$ =>
  action$.pipe(
    ofType(types.REQUEST_CREATE_VENDOR),
    mergeMap(action =>
      from(
        UserService.createVendor(action.data, { headers: { token: '' } })
      ).pipe(
        mergeMap(response =>
          of(actions.createVendorSuccess(response.data)).pipe(
            finalize(() => action?.meta?.onSuccess())
          )
        ),
        catchError(err =>
          of(actions.createVendorFailed(err)).pipe(
            finalize(() => action?.meta?.onError(err))
          )
        )
      )
    )
  )

const requestDeleteAccount = action$ =>
  action$.pipe(
    ofType(types.REQUEST_DELETE_ACCOUNT),
    mergeMap(action =>
      from(UserService.deleteAccount()).pipe(
        delay(2000),
        mergeMap(_ =>
          of(actions.deleteAccountSuccess()).pipe(
            finalize(() => action.meta.onSuccess())
          )
        ),
        catchError(err => of(actions.deleteAccountFailed(err)))
      )
    )
  )

const requestGetVendorInvoices = action$ =>
  action$.pipe(
    ofType(types.REQUEST_GET_VENDOR_INVOICES),
    mergeMap(action =>
      from(UserService.getVendorInvoices(action.data)).pipe(
        mergeMap(response =>
          of(actions.getVendorInvoicesSuccess(response.data)).pipe(
            finalize(() => action.meta?.onSuccess(response.data))
          )
        ),
        catchError(err =>
          of(actions.getVendorInvoicesFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

export const userEpics = combineEpics(
  onLoginSuccess,
  requestUpdatePaymentMethod,
  requestDeletePaymentMethod,
  requestGetVendorInvoices,
  requestCancelSubscriptionPlan,
  requestUpdateSubscriptionPlan,
  requestGetSubscriptionPlans,
  requestCreateVendor,
  requestGetSubscriptionPlanById,
  requestDeleteAccount,
  requestGetProfile,
  requestLogin,
  requestLogout,
  requestResetPassword,
  requestSetNewPassword,
  requestUpdateEmail,
  requestUpdatePassword,
  requestUpdateProfile,
  requestCreateAccount
)
