/**
 * @module constants/user
 * @description Set of constants for user-related logic.
 */

/**
 * Used for managing delete account flow.
 * @enum
 * @static
 * @name DELETE_ACCOUNT_STATUS
 */
export const DELETE_ACCOUNT_STATUS = {
  INITIAL: 0,
  IN_PROGRESS: 1,
  SUCCESS: 2,
  FAILED: 3
}

export const USER_ROLE = {
  ADMIN: 'Admin',
  VENDOR: 'VENDOR',
  RESPONDENT: 'RESPONDENT'
}

export const INVOICES_PER_PAGE = 10

export const VENDOR_REGISTRATION_FLOW_TYPE = {
  UPDATE_PAYMENT_METHOD: 'update_payment_method'
}

export const SUPPORT_CHAT_LINK = 'https://tawk.to/frontlead'
