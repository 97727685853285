/* eslint-disable prefer-promise-reject-errors */

import { chunk, forEach } from 'lodash'

import ApiClient from 'core/ApiClient'
import { paginateQuestions, pickEntitiesForUpdate } from 'utils/quiz'
import qs from 'qs'

import { logger } from 'utils/logger'
import { dissoc } from 'ramda'
import { CONSTANT_OPT_IN_TYPES, OPT_IN_TYPES } from 'constants/quiz'

/**
 *
 * @module services/QuizService
 * @description A service used for managing quiz-related endpoints.
 */
const QuizService = {
  logger: logger({ name: 'QuizService' }),
  /**
   * Creates quiz for further updates.
   * @name createQuiz
   * @method
   * @static
   * @param {Object} payload
   * @param {String} payload.name Name of the quiz.
   * @param {Number} payload.questionsPerPage How many questions should be
   * displayed per page in final quiz.
   * @param {Object} [payload.startPage] Optionally passed when user chooses to
   * use start page before quiz begins. Please note that when `startPage`
   * payload is passed, all fields all required.
   * @param {String} payload.startPage.title Start page title.
   * @param {String} payload.startPage.subtitle Start page's subtitle.
   * @param {String} payload.startPage.buttonText Text on button to start
   * questions.
   * @returns {Promise}
   */
  createQuiz: payload =>
    ApiClient.post({
      url: '/quiz',
      data: payload
    }),
  updateQuiz: (id, payload) => {
    return ApiClient.put({
      url: `/quiz/${id}`,
      data: payload
    })
  },
  createScoreEmailReply: payload =>
    ApiClient.post({
      url: '/quiz/score',
      data: payload
    }),
  updateEmailScoring: payload =>
    ApiClient.put({
      url: `/quiz/score/${payload.id}`,
      data: payload.data
    }),
  bulkUpdateSingleEmailScore: el =>
    ApiClient.put({
      url: `/quiz/score/${el._id}`,
      data: {
        active: el.active,
        content: el.content,
        maxPoints: parseInt(el.maxPoints),
        minPoints: parseInt(el.minPoints)
      }
    }),
  bulkUpdateEmailScore: payload => {
    const [...replies] = payload

    return new Promise((resolve, reject) => {
      return Promise.all(
        replies.map(el => {
          return QuizService.bulkUpdateSingleEmailScore(el)
        })
      )
        .then(resolve)
        .catch(err => reject(err))
    })
  },
  deleteSingleEmailScore: id =>
    ApiClient.delete({
      url: `/quiz/score/${id}`
    }),
  deleteAllEmailScore: payload => {
    return new Promise((resolve, reject) => {
      return Promise.all(
        payload.emailsId.map(el => {
          return QuizService.deleteSingleEmailScore(el)
        })
      )
        .then(resolve)
        .catch(err => reject(err))
    })
  },
  deleteQuiz: id =>
    ApiClient.delete({
      url: `/quiz/${id}`
    }),
  duplicateQuiz: id =>
    ApiClient.post({
      url: `/quiz/duplicate/${id}`
    }),
  createRespondent: payload =>
    ApiClient.post({
      url: '/respondent',
      data: payload,
      config: { headers: { token: '' } }
    }),
  /**
   * Updates respondent in external quiz flow and contact details page. Please
   * note that this method is used in private mode by default, i.e. public flag
   * is set to false.
   * @name updateRespondent
   * @method
   * @static
   * @param {Object} config
   * @param {String} config.uid unique id of respondent.
   * @param {Boolean} authorized Whether the call is made from external quiz or by
   * vendor (from dashboard).
   * @param {Object} payload Update data for respondent. Please refer to backend
   * documentation for available fields.
   * @returns {Promise}
   */
  updateRespondent: ({ uid, authorized = true, agreements }, payload) =>
    ApiClient.put({
      url: `/respondent/${uid}`,
      data: { ...payload, agreements },
      config: authorized ? {} : { headers: { token: '' } }
    }),
  deleteRespondent: id =>
    ApiClient.delete({
      url: `/respondent/${id}`
    }),
  deleteAllRespondents: id =>
    ApiClient.delete({
      url: `/respondent/all`
    }),
  exportRespondentsToCSV: () =>
    ApiClient.get({
      url: '/contacts/export',
      config: {
        responseType: 'blob'
      }
    }),
  exportStatisticsToJSON: quizId =>
    ApiClient.get({
      url: `/statistics/quiz/details/${quizId}/export`,
      config: {
        responseType: 'blob'
      }
    }),
  exportDetailesStatisticsToCSV: quizId =>
    ApiClient.get({
      url: `/statistics/quiz/details/${quizId}/export/details`,
      config: {
        responseType: 'blob'
      }
    }),
  getRespondentAnalysis: (id, config = {}) => {
    return ApiClient.get({
      url: `/respondent-quiz/${id}`,
      config
    })
  },
  getRespondents: params => {
    const defaultConfig = {
      limit: 1,
      page: 0,
      config: {}
    }
    const { limit, page, config } = {
      ...defaultConfig,
      ...params
    }

    return ApiClient.get({
      url: `/respondent/${limit}/${page}?${qs.stringify(config)}`
    })
  },

  getPaidQuizById: (quizId, uid, config = {}) => {
    return new Promise((resolve, reject) => {
      return ApiClient.get({
        url: `/quiz/${uid}`,
        config
      })
        .then(response => {
          // Make sure that this is the right quiz for that license key.
          if (response?.data?._id === quizId) {
            return resolve(response)
          }

          return reject(
            new Error(
              'Failed to GET quiz: License key does not match selected quiz.'
            )
          )
        })
        .catch(reject)
    })
  },

  getPaidQuizByIdUserWithCoupon: (quizId, uid, config = {}) => {
    const rawData = {
      quiz: quizId,
      couponKey: uid
    }

    return new Promise((resolve, reject) => {
      return ApiClient.post({
        url: '/respondent/coupon',
        data: rawData,
        config
      })
        .then(response => {
          return resolve(response)
        })
        .catch(reject)
    })
  },

  getQuizForFlow: (id, config = {}) => {
    return new Promise((resolve, reject) => {
      return ApiClient.get({
        url: `/quiz/${id}`,
        config
      })
        .then(res =>
          // If is payable, handle creating respondent and fetching quiz.
          res.data.licenseKeySettings.isPayable
            ? resolve({
                quiz: {
                  ...res.data,
                  _id: id
                }
              })
            : QuizService.createRespondent({
                quiz: id
              })
                .then(respondent =>
                  QuizService.getQuizById(respondent.data.uid, {
                    headers: { token: '' }
                  })
                    .then(quiz =>
                      resolve({
                        respondent: respondent.data,
                        quiz: quiz.data
                      })
                    )
                    .catch(err =>
                      reject({
                        message: 'GET /quiz for respondent failed.',
                        reason: err?.response?.data?.message,
                        data: res.data,
                        error: err
                      })
                    )
                )
                .catch(err =>
                  reject({
                    message: 'Create respondent failed.',
                    reason: err?.response?.data?.message,
                    data: res?.data,
                    error: err
                  })
                )
        )
        .catch(err =>
          reject({
            message: 'GET /quiz failed.',
            error: err,
            reason: err?.response?.data?.message
          })
        )
    })
  },
  deleteRespondentAnswer: (uid, answerId) => {
    return ApiClient.delete({
      url: `/respondent/answers/${uid}/${answerId}`
    })
  },
  deleteRespondentAnswers: async (uid, list) => {
    /**
     * Respondent answers deletion have to be sent one after another cuz it prevent to calculate statistic correctly
     */
    const responses = []
    for (const id of list) {
      responses.push(await QuizService.deleteRespondentAnswer(uid, id))
    }

    return responses
  },
  createRespondentAnswer: (uid, payload, config = {}) => {
    const formData = new FormData()

    const files = payload.files

    const jsonData = {
      data: payload.data,
      respondentData: payload.respondentData
    }

    files.forEach(file => {
      const key = Object.keys(file)[0]
      const value = file[key]
      formData.append(`${key}`, value)
    })

    formData.append('jsonData', JSON.stringify(jsonData))

    return ApiClient.post({
      url: `/respondent/answers/${uid}`,
      data: formData,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    })
  },
  postRespondentQuizData: async ({ uid, payload }) => {
    const payloads = chunk(payload.answers, 150)

    return Promise.all(
      payloads.map(async answers => {
        return await QuizService.createRespondentAnswer(
          uid,
          { data: answers },
          { headers: { token: '' } }
        )
      })
    )
  },
  getQuizById: (id, config = {}) => {
    return ApiClient.get({
      url: `/quiz/${id}`,
      config
    })
  },
  getQuizzes: ({ limit, page } = { limit: 20, page: 0 }) =>
    ApiClient.get({
      url: `/quiz/${limit}/${page}`
    }),
  getStatistics: ({ limit = 10, page = 0, query = {} }) =>
    ApiClient.get({
      url: `/statistics/quiz/${limit}/${page}?${qs.stringify(query)}`
    }),
  getStatisticsEvaluationById: ({ id, limit, page }) => {
    return ApiClient.get({
      url: `/statistics/quiz/details/${id}/${limit}/${page - 1}`
    })
  },
  getTemplates: ({ limit, page } = { limit: 20, page: 0 }) =>
    ApiClient.get({
      url: `/quiz/${limit}/${page}/templates`
    }),
  createQuestion: payload =>
    ApiClient.post({
      url: '/question',
      data: payload
    }),
  deleteQuestion: id =>
    ApiClient.delete({
      url: `/question/${id}`
    }),
  updateQuestion: ({ id, payload }) => {
    return ApiClient.put({
      url: `/question/${id}`,
      data: payload
    })
  },
  createQuestionGroupSetting: payload =>
    ApiClient.post({
      url: '/question/group/setting',
      data: payload
    }),
  updateQuestionGroupSetting: payload =>
    ApiClient.put({
      url: `/question/group/setting/${payload?._id}`,
      data: payload
    }),
  bulkUpdateQuestion: payload =>
    ApiClient.put({
      url: '/question',
      data: payload
    }),
  bulkUpdateAnswer: payload =>
    ApiClient.put({
      url: '/answer',
      data: payload
    }),
  bulkUpdateTitle: payload =>
    ApiClient.put({
      url: '/title',
      data: payload
    }),
  repositionQuizQuestions: (perPage, questions) => {
    const paginated = paginateQuestions(perPage)(questions)

    return new Promise((resolve, reject) => {
      return Promise.all(
        paginated.map(question =>
          QuizService.updateQuestion({
            id: question.id,
            payload: {
              page: question.page
            }
          })
        )
      )
        .then(responses => {
          const data = responses.map(response => response.data)

          return resolve(data)
        })
        .catch(err => reject(err))
    })
  },
  /**
   * Creates new information to gather for the selected quiz.
   * Added: Thu, 21 Jan 2021 13:07:21 +0100
   * @name createInformationToGather
   * @author Rafał Wyszomirski <ralf@desmart.com>
   * @method
   * @static
   * @param {Object} payload
   * @param {String} payload.quiz
   * @param {String} payload.name
   * @param {boolean} payload.required
   * @returns {Promise}
   */
  createInformationToGather: payload => {
    return ApiClient.post({
      url: '/informationToGather',
      data: payload
    })
  },
  /**
   * Similar to `createInformationToGather`, but it maps `informationToGather`
   * object to `Promise.all`.
   * Added: Thu, 21 Jan 2021 13:13:27 +0100
   * @name bulkCreateInformationToGather
   * @author Rafał Wyszomirski <ralf@desmart.com>
   * @method
   * @static
   * @param {String} quizId
   * @param {Object} payload - Object, with keys of `OPT_IN_TYPES` and values
   * compatible with `createInformationToGather` payload.
   * @returns {Promise}
   */
  bulkCreateInformationToGather: (quizId, payload, settings) => {
    const _checked = Object.values(payload).filter(information =>
      information ? information.checked : false
    )

    const bulkPayload = []

    for (const information of _checked) {
      let payload = {
        name: information.name,
        required: information.required
      }

      if (information.name === OPT_IN_TYPES.PHONE) {
        payload = {
          ...payload,
          settings: settings.phone
        }
      } else if (information.name === OPT_IN_TYPES.COUNTRY) {
        payload = {
          ...payload,
          settings: settings.country
        }
      }

      bulkPayload.push(payload)
    }

    return QuizService.createInformationToGather({
      quiz: quizId,
      data: bulkPayload
    })
  },
  createAnswer: payload =>
    ApiClient.post({
      url: '/answer',
      data: payload
    }),
  deleteAnswer: id =>
    ApiClient.delete({
      url: `/answer/${id}`
    }),
  updateAnswer: ({ id, payload }) =>
    ApiClient.put({
      url: `/answer/${id}`,
      data: payload
    }),
  createTitle: payload =>
    ApiClient.post({
      url: '/title',
      data: payload
    }),
  updateTitle: ({ id, payload }) =>
    ApiClient.put({
      url: `/title/${id}`,
      data: payload
    }),
  deleteTitle: id =>
    ApiClient.delete({
      url: `/title/${id}`
    }),
  bulkQuizUpdate: payload => {
    const matrix = {
      answer: QuizService.bulkUpdateAnswer,
      question: QuizService.bulkUpdateQuestion,
      title: QuizService.bulkUpdateTitle,
      groupedQuestionSettings: QuizService.updateQuestionGroupSetting,
      emailScore: QuizService.bulkUpdateEmailScore
    }
    return new Promise((resolve, reject) => {
      return Promise.all(pickEntitiesForUpdate(payload, matrix))
        .then(values => {
          return resolve(values.map(v => v.data))
        })
        .catch(err => reject(err))
    })
  },
  /**
   * Please note that `token` header has to be empty so that the request is
   * treated as unauthorized.
   * @name markQuizAsFinished
   * @method
   * @static
   * @param {string} respondentUid
   * @returns {Promise}
   */
  markQuizAsFinished: respondentUid =>
    ApiClient.put({
      url: `/respondent/${respondentUid}`,
      config: {
        headers: {
          token: ''
        }
      },
      data: {
        quizFinished: true
      }
    }),
  markQuizAsStarted: data =>
    ApiClient.put({
      url: `/respondent/${data.uid}`,
      config: {
        headers: {
          token: ''
        }
      },
      data: {
        quizStarted: true,
        accessedThroughUrl: data?.url || ''
      }
    }),
  validateEmailConfiguration: id =>
    ApiClient.get({
      url: `/email/test/${id}`
    }),
  validateExternalProviderConfiguration: id =>
    ApiClient.get({
      url: `/marketing-tool/test/${id}`
    }),
  downloadInvoice: query =>
    ApiClient.get({
      url: `/invoice?${qs.stringify(query)}`,
      config: {
        responseType: 'blob'
      }
    }),
  /**
   * Triggers download for respodent's invoice.
   * @name downloadRespondentInvoice
   * @method
   * @static
   * @param {Object} query
   * @param {string} query.respondentId
   * @param {boolean} query.refund
   * @returns {Promise}
   */
  downloadRespondentInvoice: ({ respondentId, refund }) =>
    QuizService.downloadInvoice({
      respondentId,
      refund
    }),
  resetEvaluation: payload =>
    ApiClient.post({
      url: '/statistics/reset',
      data: payload
    }),

  getQuentnTags: ({ quizId }) =>
    ApiClient.get({
      url: `/quiz/${quizId}/marketing-tool/quentn`
    })
}

export default QuizService
