import * as types from 'core/redux/types/user'
/**
 * Action creators for login action.
 */
export const requestLogin = (data, meta) => ({
  type: types.REQUEST_LOGIN,
  data,
  meta
})

export const loginSuccess = data => ({
  type: types.LOGIN_SUCCESS,
  data
})

export const loginFailed = error => ({
  type: types.LOGIN_FAILED,
  error
})

/**
 * Action creators for logging out of the app.
 */
export const requestLogout = () => ({
  type: types.REQUEST_LOGOUT
})

export const logoutSuccess = () => ({
  type: types.LOGOUT_SUCCESS
})

export const logoutFailed = error => ({
  type: types.LOGOUT_FAILED,
  error
})

/**
 * Action creators for initializing new password flow.
 */
export const requestResetPassword = data => ({
  type: types.REQUEST_RESET_PASSWORD,
  data
})

export const resetPasswordSuccess = data => ({
  type: types.RESET_PASSWORD_SUCCESS,
  data
})

export const resetPasswordFailed = error => ({
  type: types.RESET_PASSWORD_FAILED,
  error
})

/**
 * Action creators for set new password flow.
 */
export const requestSetNewPassword = data => ({
  type: types.REQUEST_SET_NEW_PASSWORD,
  data
})

export const setNewPasswordSuccess = data => ({
  type: types.SET_NEW_PASSWORD_SUCCESS,
  data
})

export const setNewPasswordFailed = error => ({
  type: types.SET_NEW_PASSWORD_FAILED,
  error
})

/**
 * Action creators for updating user profile.
 */
export const requestUpdateProfile = (data, meta) => ({
  type: types.REQUEST_UPDATE_PROFILE,
  data,
  meta
})

export const updateProfileSuccess = data => ({
  type: types.UPDATE_PROFILE_SUCCESS,
  data
})

export const updateProfileFailed = error => ({
  type: types.UPDATE_PROFILE_FAILED,
  error
})

/**
 * Action creators for updating password as authorized user.
 */
export const requestUpdatePassword = data => ({
  type: types.REQUEST_UPDATE_PASSWORD,
  data
})

export const updatePasswordSuccess = data => ({
  type: types.UPDATE_PASSWORD_SUCCESS,
  data
})

export const updatePasswordFailed = error => ({
  type: types.UPDATE_PASSWORD_FAILED,
  error
})

/**
 * Action creators for updating email. Please mind that updating email is
 * separated from updating the profile.
 */
export const requestUpdateEmail = (data, onSuccess = () => {}) => ({
  type: types.REQUEST_UPDATE_EMAIL,
  data,
  onSuccess
})

export const updateEmailSuccess = data => ({
  type: types.UPDATE_EMAIL_SUCCESS,
  data
})

export const updateEmailFailed = error => ({
  type: types.UPDATE_EMAIL_FAILED,
  error
})

/**
 * Action creators for deleting user's account.
 */
export const requestDeleteAccount = meta => ({
  type: types.REQUEST_DELETE_ACCOUNT,
  meta
})

export const deleteAccountSuccess = () => ({
  type: types.DELETE_ACCOUNT_SUCCESS
})

export const deleteAccountFailed = error => ({
  type: types.DELETE_ACCOUNT_FAILED,
  error
})

export const initializeDeleteAccount = () => ({
  type: types.INITIALIZE_DELETE_ACCOUNT
})

/**
 * Action creators for handling the request for getting user profile.
 */
export const requestGetProfile = meta => ({
  type: types.REQUEST_GET_PROFILE,
  meta
})

export const getProfileSuccess = data => ({
  type: types.GET_PROFILE_SUCCESS,
  data
})

export const getProfileFailed = error => ({
  type: types.GET_PROFILE_FAILED,
  error
})

/**
 * Action creators for fetching vendor subscription plan.
 */
export const requestGetSubscriptionPlanById = (data, meta) => ({
  type: types.REQUEST_GET_SUBSCRIPTION_PLAN_BY_ID,
  data,
  meta
})

export const getSubscriptionPlanByIdSuccess = data => ({
  type: types.GET_SUBSCRIPTION_PLAN_BY_ID_SUCCESS,
  data
})

export const getSubscriptionPlanByIdFailed = error => ({
  type: types.GET_SUBSCRIPTION_PLAN_BY_ID_FAILED,
  error
})

/**
 * Action creators for creating vendor.
 */
export const requestCreateVendor = (data, meta) => ({
  type: types.REQUEST_CREATE_VENDOR,
  data,
  meta
})

export const createVendorSuccess = data => ({
  type: types.CREATE_VENDOR_SUCCESS,
  data
})

export const createVendorFailed = error => ({
  type: types.CREATE_VENDOR_FAILED,
  error
})

/**
 * This action does not call API - it is used as a simple caching mechanism.
 * Please see VendorRegistrationPage for usage.
 */
export const setSubscriptionPlan = data => ({
  type: types.SET_SUBSCRIPTION_PLAN,
  data
})

/**
 * Used for fetching all available subscription plans. Used in
 * ChangeSubscriptionForm.
 */
export const requestGetSubscriptionPlans = meta => ({
  type: types.REQUEST_GET_SUBSCRIPTION_PLANS,
  meta
})

export const getSubscriptionPlansSuccess = data => ({
  type: types.GET_SUBSCRIPTION_PLANS_SUCCESS,
  data
})

export const getSubscriptionPlansFailed = error => ({
  type: types.GET_SUBSCRIPTION_PLANS_FAILED,
  error
})

/**
 * Used for updating user subscription.
 */
export const requestUpdateSubscriptionPlan = (data, meta) => ({
  type: types.REQUEST_UPDATE_SUBSCRIPTION_PLAN,
  data,
  meta
})

export const updateSubscriptionPlanSuccess = data => ({
  type: types.UPDATE_SUBSCRIPTION_PLAN_SUCCESS,
  data
})

export const updateSubscriptionPlanFailed = error => ({
  type: types.UPDATE_SUBSCRIPTION_PLAN_FAILED,
  error
})

/**
 * Cancel vendor subscription. Used in profile settings.
 */
export const requestCancelSubscriptionPlan = meta => ({
  type: types.REQUEST_CANCEL_SUBSCRIPTION_PLAN,
  meta
})

export const cancelSubscriptionPlanSuccess = data => ({
  type: types.CANCEL_SUBSCRIPTION_PLAN_SUCCESS,
  data
})

export const cancelSubscriptionPlanFailed = error => ({
  type: types.CANCEL_SUBSCRIPTION_PLAN_FAILED,
  error
})

/**
 * Used for fetching vendor invoices.
 */
export const requestGetVendorInvoices = (data, meta) => ({
  type: types.REQUEST_GET_VENDOR_INVOICES,
  data,
  meta
})

export const getVendorInvoicesSuccess = data => ({
  type: types.GET_VENDOR_INVOICES_SUCCESS,
  data
})

export const getVendorInvoicesFailed = error => ({
  type: types.GET_VENDOR_INVOICES_FAILED,
  error
})

export const requestCreateAccount = (data, meta) => ({
  type: types.REQUEST_CREATE_ACCOUNT,
  data,
  meta
})

export const createAccountSuccess = data => ({
  type: types.CREATE_ACCOUNT_SUCCESS,
  data
})

export const createAccountFailed = error => ({
  type: types.CREATE_ACCOUNT_FAILED,
  error
})

export const requestUpdatePaymentMethod = (data, meta) => ({
  type: types.REQUEST_UPDATE_PAYMENT_METHOD,
  data,
  meta
})

export const updatePaymentMethodSuccess = data => ({
  type: types.UPDATE_PAYMENT_METHOD_SUCCESS,
  data
})

export const updatePaymentMethodFailed = error => ({
  type: types.UPDATE_PAYMENT_METHOD_FAILED,
  error
})

export const requestDeletePaymentMethod = meta => ({
  type: types.REQUEST_DELETE_PAYMENT_METHOD,
  meta
})

export const deletePaymentMethodSuccess = data => ({
  type: types.DELETE_PAYMENT_METHOD_SUCCESS,
  data
})

export const deletePaymentMethodFailed = error => ({
  type: types.DELETE_PAYMENT_METHOD_FAILED,
  error
})
