import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { track } from 'detrucker'

import { DYNAMIC_ROUTES, ROUTES } from 'constants/navigation'

import AuthService from 'services/AuthService'
import { useSelector } from 'react-redux'
import { selectUserSubscriptionState } from './redux/selectors/user'

export const RequireAuth = ({ children }) => {
  const currentLocation = useLocation()

  track({
    name: 'Page Viewed',
    properties: {
      path: currentLocation.pathname
    }
  })

  return AuthService.isAuthenticated() ? (
    children
  ) : (
    <Navigate
      to={{ pathname: ROUTES.LOGIN }}
      state={{ from: currentLocation }}
      replace
    />
  )
}

export const RequireSubscription = ({ children }) => {
  const currentLocation = useLocation()
  const isSubscriptionExpired = useSelector(selectUserSubscriptionState)
  return isSubscriptionExpired ? (
    <Navigate
      to={{ pathname: ROUTES.SUBSCRIPTION }}
      state={{ from: currentLocation }}
      replace
    />
  ) : (
    children
  )
}

export const RequireLicenseKey = ({ children, id, respondent }) => {
  const currentLocation = useLocation()
  return !respondent ? (
    <Navigate
      to={{ pathname: DYNAMIC_ROUTES.FORM.LICENSE_KEY(id) }}
      state={{ from: currentLocation }}
      replace
    />
  ) : (
    children
  )
}
