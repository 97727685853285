import * as types from 'core/redux/types/support'

export const requestSendSupportTicket = (data, meta) => ({
  type: types.REQUEST_SEND_SUPPORT_TICKET,
  data,
  meta
})

export const sendSupportTicketSuccess = data => ({
  type: types.SEND_SUPPORT_TICKET_SUCCESS,
  data
})

export const sendSupportTicketFailed = error => ({
  type: types.SEND_SUPPORT_TICKET_FAILED,
  error
})
