/**
 * @module epics/support
 * @description Business logic connected with the support endpoint.
 */
import { combineEpics, ofType } from 'redux-observable'
import { of, from } from 'rxjs'
import { mergeMap, catchError, finalize } from 'rxjs/operators'

import * as types from 'core/redux/types/support'
import * as actions from 'core/redux/actions/support'

import SupportService from 'services/SupportService'

const requestSendSupportTicket = action$ =>
  action$.pipe(
    ofType(types.REQUEST_SEND_SUPPORT_TICKET),
    mergeMap(action =>
      from(SupportService.sendSupportTicket(action.data)).pipe(
        mergeMap(response =>
          of(actions.sendSupportTicketSuccess(response.data)).pipe(
            finalize(() => action?.meta?.onSuccess())
          )
        ),
        catchError(err =>
          of(actions.sendSupportTicketFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

export const supportEpics = combineEpics(requestSendSupportTicket)
