// TODO: Think about more generic solution for this reducer, since shape of the
// state tree is the same for all forms. wed, 18 mar 2020, 11:16:09 CET

// TODO: Think about whether support shouldn't be a part of 'app' logic.

/**
 * This reducer is reponsible for handling flags and errors for the forms. This
 * data is used to trigger side effects in the components.
 * @module Redux#Reducer#Forms
 * @author Rafał Wyszomirski
 */

import * as types from 'core/redux/types/user'

import * as supportTypes from 'core/redux/types/support'
import * as formTypes from 'core/redux/types/forms'

const defaultState = {
  forgotPasswordForm: {
    error: null,
    success: null,
    isSubmitting: false
  },
  setNewPasswordForm: {
    error: null,
    success: null,
    isSubmitting: false
  },
  profileSettingsForm: {
    error: null,
    success: null,
    isSubmitting: false
  },
  updatePasswordForm: {
    error: null,
    success: null,
    isSubmitting: false
  },
  updateEmailForm: {
    error: null,
    success: null,
    isSubmitting: false
  },
  applyCouponForm: {
    error: null,
    success: null,
    isSubmitting: false
  },
  supportForm: {
    error: null,
    success: null,
    isSubmitting: false
  }
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.REQUEST_RESET_PASSWORD: {
      return {
        ...state,
        forgotPasswordForm: {
          ...state.forgotPasswordForm,
          error: null,
          isSubmitting: true
        }
      }
    }
    case types.RESET_PASSWORD_FAILED: {
      return {
        ...state,
        forgotPasswordForm: {
          ...state.forgotPasswordForm,
          isSubmitting: false,
          error: action.error
        }
      }
    }
    case types.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        forgotPasswordForm: {
          ...state.forgotPasswordForm,
          success: true,
          isSubmitting: false
        }
      }
    }
    case types.REQUEST_SET_NEW_PASSWORD: {
      return {
        ...state,
        setNewPasswordForm: {
          ...state.setNewPasswordForm,
          error: null,
          isSubmitting: true
        }
      }
    }
    case types.SET_NEW_PASSWORD_FAILED: {
      return {
        ...state,
        setNewPasswordForm: {
          ...state.setNewPasswordForm,
          isSubmitting: false,
          error: action.error
        }
      }
    }
    case types.SET_NEW_PASSWORD_SUCCESS: {
      return {
        ...state,
        setNewPasswordForm: {
          ...state.setNewPasswordForm,
          success: true,
          isSubmitting: false
        }
      }
    }
    case types.REQUEST_UPDATE_PROFILE: {
      return {
        ...state,
        profileSettingsForm: {
          ...state.profileSettingsForm,
          error: null,
          success: null,
          isSubmitting: true
        }
      }
    }
    case types.UPDATE_PROFILE_FAILED: {
      return {
        ...state,
        profileSettingsForm: {
          ...state.profileSettingsForm,
          isSubmitting: false,
          error: action.error
        }
      }
    }
    case types.UPDATE_PROFILE_SUCCESS: {
      return {
        ...state,
        profileSettingsForm: {
          ...state.profileSettingsForm,
          success: true,
          isSubmitting: false
        }
      }
    }
    case types.REQUEST_UPDATE_PASSWORD: {
      return {
        ...state,
        updatePasswordForm: {
          ...state.updatePasswordForm,
          error: null,
          isSubmitting: true
        }
      }
    }
    case types.UPDATE_PASSWORD_FAILED: {
      return {
        ...state,
        updatePasswordForm: {
          ...state.updatePasswordForm,
          isSubmitting: false,
          error: action.error
        }
      }
    }
    case types.UPDATE_PASSWORD_SUCCESS: {
      return {
        ...state,
        updatePasswordForm: {
          ...state.updatePasswordForm,
          success: true,
          isSubmitting: false
        }
      }
    }
    case types.REQUEST_UPDATE_EMAIL: {
      return {
        ...state,
        updateEmailForm: {
          ...state.updateEmailForm,
          error: null,
          success: null,
          isSubmitting: true
        }
      }
    }
    case types.UPDATE_EMAIL_FAILED: {
      return {
        ...state,
        updateEmailForm: {
          ...state.updateEmailForm,
          isSubmitting: false,
          error: action.error
        }
      }
    }
    case types.UPDATE_EMAIL_SUCCESS: {
      return {
        ...state,
        updateEmailForm: {
          ...state.updateEmailForm,
          success: true,
          isSubmitting: false
        }
      }
    }
    case supportTypes.REQUEST_SEND_SUPPORT_TICKET: {
      return {
        ...state,
        supportForm: {
          ...state.supportForm,
          error: null,
          success: null,
          isSubmitting: true
        }
      }
    }
    case supportTypes.SEND_SUPPORT_TICKET_FAILED: {
      return {
        ...state,
        supportForm: {
          ...state.supportForm,
          isSubmitting: false,
          error: action.error
        }
      }
    }
    case supportTypes.SEND_SUPPORT_TICKET_SUCCESS: {
      return {
        ...state,
        supportForm: {
          ...state.supportForm,
          success: true,
          isSubmitting: false
        }
      }
    }
    case formTypes.RESET_FORM: {
      return {
        ...state,
        [action.formName]: {
          success: null,
          error: null,
          isSubmitting: false
        }
      }
    }
    case types.LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default reducer
