import * as types from 'core/redux/types/admin'

/**
 * Used for fetching statistic.
 */
export const requestGetAdminStatistics = (data, meta) => ({
  type: types.REQUEST_GET_ADMIN_STATISTICS,
  data,
  meta
})

export const getAdminStatisticsSuccess = data => ({
  type: types.GET_ADMIN_STATISTICS_SUCCESS,
  data
})

export const getAdminStatisticsFailed = error => ({
  type: types.GET_ADMIN_STATISTICS_FAILED,
  error
})

/**
 * Used for fetching admin invoices.
 */
export const requestGetAdminInvoices = (data, meta) => ({
  type: types.REQUEST_GET_ADMIN_INVOICES,
  data,
  meta
})

export const getAdminInvoicesSuccess = data => ({
  type: types.GET_ADMIN_INVOICES_SUCCESS,
  data
})

export const getAdminInvoicesFailed = error => ({
  type: types.GET_ADMIN_INVOICES_FAILED,
  error
})

/**
 * Used for fetching list of vendors.
 */
export const requestGetVendors = (config, meta) => ({
  type: types.REQUEST_GET_VENDORS,
  config,
  meta
})

export const getVendorsSuccess = data => ({
  type: types.GET_VENDORS_SUCCESS,
  data
})

export const getVendorsFailed = error => ({
  type: types.GET_VENDORS_FAILED,
  error
})

/**
 * Used for getting vendor by id.
 */
export const requestGetVendorById = (data, meta) => ({
  type: types.REQUEST_GET_VENDOR_BY_ID,
  data,
  meta
})

export const getVendorByIdSuccess = data => ({
  type: types.GET_VENDOR_BY_ID_SUCCESS,
  data
})

export const getVendorByIdFailed = error => ({
  type: types.GET_VENDOR_BY_ID_FAILED,
  error
})

/**
 * Used for update vendor by id - notes + lock / unlock.
 */
export const requestUpdateVendorById = (data, meta) => ({
  type: types.REQUEST_UPDATE_VENDOR_BY_ID,
  data,
  meta
})

export const updateVendorByIdSuccess = data => ({
  type: types.UPDATE_VENDOR_BY_ID_SUCCESS,
  data
})

export const updateVendorByIdFailed = error => ({
  type: types.UPDATE_VENDOR_BY_ID_FAILED,
  error
})

/**
 * Used for delete vendor by id.
 */
export const requestDeleteVendorById = (data, meta) => ({
  type: types.REQUEST_DELETE_VENDOR_BY_ID,
  data,
  meta
})

export const deleteVendorByIdSuccess = data => ({
  type: types.DELETE_VENDOR_BY_ID_SUCCESS,
  data
})

export const deleteVendorByIdFailed = error => ({
  type: types.DELETE_VENDOR_BY_ID_FAILED,
  error
})

/**
 * Used for update vendor trial date by id
 */
export const requestUpdateVendorTrialDateById = (data, meta) => ({
  type: types.REQUEST_UPDATE_VENDOR_TRIAL_DATE_BY_ID,
  data,
  meta
})

export const updateVendorTrialDateByIdSuccess = data => ({
  type: types.UPDATE_VENDOR_TRIAL_DATE_BY_ID_SUCCESS,
  data
})

export const updateVendorTrialDateByIdFailed = error => ({
  type: types.UPDATE_VENDOR_TRIAL_DATE_BY_ID_FAILED,
  error
})

/**
 * Used for sending email confirm reminder to vendor
 */
export const requestEmailConfirmReminder = (data, meta) => ({
  type: types.REQUEST_EMAIL_CONFIRM_REMINDER,
  data,
  meta
})

export const sendEmailConfirmReminderSuccess = data => ({
  type: types.SEND_EMAIL_CONFIRM_REMINDER_SUCCESS,
  data
})

export const sendEmailConfirmReminderFailed = error => ({
  type: types.SEND_EMAIL_CONFIRM_REMINDER_FAILED,
  error
})
