import { isAfter } from 'date-fns'

/**
 * Returns boolean value that determines whether user has to buy/prolong
 * subscription.
 * @name checkSubscriptionState
 * @method
 * @static
 * @param {Object} profile
 * @returns {boolean}
 */
export const checkUserSubscriptionState = profile => {
  const { subscription } = profile

  const endDate = new Date(subscription?.end)

  return subscription?.expired || isAfter(Date.now(), endDate)
}

/**
 * Returns boolean value that determines whether user have paid subscription active or not.
 * @name isPaidSubscriptionActive
 * @method
 * @static
 * @param {Object} profile
 * @returns {boolean}
 */
export const isPaidSubscriptionActive = profile => {
  const { subscription } = profile

  const endDate = new Date(subscription?.end)

  if (subscription?.trial) {
    return false
  }

  return !(subscription?.expired || isAfter(Date.now(), endDate))
}
