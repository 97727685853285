/**
 * @module epics/admin
 * @description Business logic with managing admin.
 */
import { combineEpics, ofType } from 'redux-observable'
import { of, from } from 'rxjs'
import { catchError, finalize, mergeMap } from 'rxjs/operators'
import { propOr } from 'ramda'
import { isEmpty } from 'lodash'

import * as types from 'core/redux/types/admin'
import * as actions from 'core/redux/actions/admin'

import { getQuizzesListPageLimitByUserProfile } from 'utils/quiz'

import AdminService from 'services/AdminService'
import UserService from 'services/UserService'

import { logger } from 'utils/logger'

const l = logger({ name: 'EPIC::ADMIN' })

const requestGetAdminStatistics = (action$, state$) =>
  action$.pipe(
    ofType(types.REQUEST_GET_ADMIN_STATISTICS),
    mergeMap(action => {
      const _profile = state$.value.user.profile

      l.log('Attempting to fetch statistics', { currentProfile: _profile })

      const statistics$ = profile =>
        from(
          AdminService.getAdminStatistics({
            ...action.data,
            limit: getQuizzesListPageLimitByUserProfile(profile)
          })
        ).pipe(
          mergeMap(response =>
            of(actions.getAdminStatisticsSuccess(response.data)).pipe(
              finalize(() => action?.meta?.onSuccess())
            )
          ),
          catchError(err =>
            of(actions.getAdminStatisticsFailed(err)).pipe(
              finalize(() => action?.meta?.onError())
            )
          )
        )

      if (isEmpty(propOr({}, 'user', _profile))) {
        l.log(
          'Profile not available, fetching it from API to determine email address...'
        )
        return from(UserService.getProfile()).pipe(
          mergeMap(profileResponse => {
            l.log('Profile data fetched, attempting to fetch statistics...')
            return statistics$(profileResponse.data)
          })
        )
      }

      l.log('Profile data is already loaded, fetching the statistics')

      return statistics$(_profile)
    })
  )

const requestGetAdminInvoices = action$ =>
  action$.pipe(
    ofType(types.REQUEST_GET_ADMIN_INVOICES),
    mergeMap(action =>
      from(AdminService.getAdminInvoices(action.data)).pipe(
        mergeMap(response =>
          of(actions.getAdminInvoicesSuccess(response.data)).pipe(
            finalize(() => action.meta?.onSuccess(response.data))
          )
        ),
        catchError(err =>
          of(actions.getAdminInvoicesFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

const requestGetVendors = action$ =>
  action$.pipe(
    ofType(types.REQUEST_GET_VENDORS),
    mergeMap(action =>
      from(AdminService.getVendors(action.config)).pipe(
        mergeMap(response =>
          of(actions.getVendorsSuccess(response.data)).pipe(
            finalize(() => action.meta?.onSuccess(response.data))
          )
        ),
        catchError(err =>
          of(actions.getVendorsFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

const requestGetVendorById = action$ =>
  action$.pipe(
    ofType(types.REQUEST_GET_VENDOR_BY_ID),
    mergeMap(action =>
      from(AdminService.getVendorById(action.data)).pipe(
        mergeMap(vendroResponse =>
          from(AdminService.getVendorQuizesById(action.data)).pipe(
            mergeMap(quizResponse =>
              of(
                actions.getVendorByIdSuccess({
                  vendorDetails: vendroResponse.data,
                  vendorQuzzes: quizResponse.data
                })
              ).pipe(finalize(() => action?.meta?.onSuccess()))
            ),
            catchError(err =>
              of(actions.getVendorByIdFailed(err)).pipe(
                finalize(() => action?.meta?.onError())
              )
            )
          )
        )
      )
    )
  )

const requestUpdateVendorById = action$ =>
  action$.pipe(
    ofType(types.REQUEST_UPDATE_VENDOR_BY_ID),
    mergeMap(action =>
      from(AdminService.updateUser(action.data)).pipe(
        mergeMap(response =>
          of(actions.updateVendorByIdSuccess(action.data)).pipe(
            finalize(() => action.meta?.onSuccess(response.data))
          )
        ),
        catchError(err =>
          of(actions.updateVendorByIdFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

const requestDeleteVendorById = action$ =>
  action$.pipe(
    ofType(types.REQUEST_DELETE_VENDOR_BY_ID),
    mergeMap(action =>
      from(AdminService.deleteUser(action.data)).pipe(
        mergeMap(response =>
          of(actions.deleteVendorByIdSuccess(action.data)).pipe(
            finalize(() => action.meta?.onSuccess(response.data))
          )
        ),
        catchError(err =>
          of(actions.deleteVendorByIdFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

const requestUpdateVendorTrialDateById = action$ =>
  action$.pipe(
    ofType(types.REQUEST_UPDATE_VENDOR_TRIAL_DATE_BY_ID),
    mergeMap(action =>
      from(AdminService.changeVendorTrialDate(action.data)).pipe(
        mergeMap(response =>
          of(actions.updateVendorTrialDateByIdSuccess(action.data)).pipe(
            finalize(() => action.meta?.onSuccess(response.data))
          )
        ),
        catchError(err =>
          of(actions.updateVendorTrialDateByIdFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

const requestEmailConfirmReminder = action$ =>
  action$.pipe(
    ofType(types.REQUEST_EMAIL_CONFIRM_REMINDER),
    mergeMap(action =>
      from(AdminService.sendEmailConfirmReminder(action.data)).pipe(
        mergeMap(response =>
          of(actions.sendEmailConfirmReminderSuccess(action.data)).pipe(
            finalize(() => action.meta?.onSuccess(response.data))
          )
        ),
        catchError(err =>
          of(actions.sendEmailConfirmReminderFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

export const adminEpics = combineEpics(
  requestGetAdminStatistics,
  requestGetAdminInvoices,
  requestGetVendors,
  requestGetVendorById,
  requestUpdateVendorById,
  requestDeleteVendorById,
  requestUpdateVendorTrialDateById,
  requestEmailConfirmReminder
)
