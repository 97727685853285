import { combineReducers } from 'redux'

import app from 'core/redux/reducers/app'
import user from 'core/redux/reducers/user'
import forms from 'core/redux/reducers/forms'
import quiz from 'core/redux/reducers/quiz'
import question from 'core/redux/reducers/question'
import admin from 'core/redux/reducers/admin'

const rootReducer = combineReducers({
  app,
  user,
  forms,
  question,
  quiz,
  admin
})

export default rootReducer
