import ApiClient from 'core/ApiClient'

/**
 *
 * @module services/EmailService
 * @description A service for handling email analysis-related requests.
 */
const EmailService = {
  /**
   * Send email with analysis to selected email. The email configuration from the quiz will be used.
   * @name sendAnalysisToEmail
   * @method
   * @static
   * @param {Object} payload
   * @param {String} payload.id ID of the respondent.
   * @param {String} payload.email Destination email.
   * @returns {Promise}
   */
  sendAnalysisToEmail: ({ id, email }) =>
    ApiClient.post({
      url: `/respondent-quiz/send/${id}/${email}`
    })
}

export default EmailService
