/* eslint-disable prefer-promise-reject-errors */

import ApiClient from 'core/ApiClient'
import qs from 'qs'

import { INVOICES_PER_PAGE } from 'constants/user'

import { logger } from 'utils/logger'

const AdminService = {
  logger: logger({ name: 'AdminService' }),

  getAdminStatistics: ({ query = {} }) =>
    ApiClient.get({
      url: `/admin/statistics?${qs.stringify(query)}`
    }),

  downloadAdminInvoice: data =>
    ApiClient.get({
      url: `/invoice/?${qs.stringify(data)}`,
      config: {
        responseType: 'blob'
      }
    }),

  getAdminInvoices: params => {
    const defaultData = {
      limit: INVOICES_PER_PAGE,
      page: 0
    }
    const { limit, page, data } = {
      ...defaultData,
      ...params
    }

    return ApiClient.get({
      url: `/invoice/${limit}/${page}?${data}`
    })
  },

  downloadAdminInvoicesCSV: data =>
    ApiClient.get({
      url: `/invoice/export?${qs.stringify(data)}`,
      config: {
        responseType: 'blob'
      }
    }),

  getVendors: params => {
    const defaultConfig = {
      limit: 1,
      page: 0,
      config: {}
    }
    const { limit, page, config } = {
      ...defaultConfig,
      ...params
    }

    return ApiClient.get({
      url: `/user/${limit}/${page}?${qs.stringify(config)}`
    })
  },

  getVendorById: data =>
    ApiClient.get({
      url: `/user/${data.id}`
    }),

  getVendorQuizesById: data =>
    ApiClient.get({
      url: `/quiz/10/0?ownerId=${data.id}`
    }),

  updateUser: data =>
    ApiClient.put({
      url: `/user/${data.id}`,
      data: data.payload
    }),

  deleteUser: data =>
    ApiClient.delete({
      url: `/user/${data}`
    }),

  changeVendorTrialDate: data =>
    ApiClient.put({
      url: `/user/${data.id}`,
      data: {
        subscription: {
          end: data.date
        }
      }
    }),

  sendEmailConfirmReminder: data =>
    ApiClient.get({
      url: `/user/email/confirm/resend/${data.email}`
    })
}

export default AdminService
