import * as types from 'core/redux/types/admin'
import { INVOICES_PER_PAGE } from 'constants/admin'

const defaultState = {
  statistics: {
    vendorsCounter: {
      all: 0,
      withSubscription: 0,
      withExpiredSubscription: 0,
      deleted: 0,
      trial: 0
    },
    respondentsCounter: { all: 0, active: 0 },
    quizzesCounter: { all: 0, active: 0 }
  },
  invoices: {
    list: [],
    pagination: {
      offset: 0,
      total: 0,
      done: false
    },
    perPage: INVOICES_PER_PAGE,
    // A map that stores answers to quiz questions given by respondents.
    entries: {}
  },
  management: {
    results: [],
    status: {
      done: false,
      offset: 0,
      total: 0
    }
  }
}

const deleteVendorFromList = (id, state) =>
  state.management.results.map(vendor =>
    vendor.userId === id
      ? {
          ...vendor,
          __deleted: true
        }
      : vendor
  )

const mergeVendorData = data => state => {
  const _management = { ...state.management }
  const index = _management.results.findIndex(
    el => el._id === data.vendorDetails._id
  )

  // if searched vendor is found in DB, we will put his details and his quizzes into his object
  if (index !== -1) {
    _management.results[index] = {
      ..._management.results[index],
      vendorDetails: data.vendorDetails,
      vendorQuzzes: data.vendorQuzzes
    }
  }

  return { ..._management }
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_ADMIN_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: action.data
      }
    case types.GET_ADMIN_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          list: action.data.results,
          pagination: action.data.status
        }
      }
    case types.GET_VENDORS_SUCCESS:
      return {
        ...state,
        management: action.data
      }
    case types.GET_VENDOR_BY_ID_SUCCESS:
      return {
        ...state,
        management: mergeVendorData(action.data)(state)
      }
    case types.DELETE_VENDOR_BY_ID_SUCCESS:
      // we have to check whitch vendor was deleted and juś add only him '__deleted: true'
      return {
        ...state,
        management: {
          ...state.management,
          results: deleteVendorFromList(action.data, state)
        }
      }

    default:
      return state
  }
}

export default reducer
