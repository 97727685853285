import { prop, propOr } from 'ramda'
import { createSelector } from 'reselect'

import UserService from 'services/UserService'
import {
  checkUserSubscriptionState,
  isPaidSubscriptionActive
} from 'utils/user'
import {
  isSubscriptionPlanTrial,
  isRespondentLimitExhausted,
  isStripeConnectionAllowed
} from 'utils/subscription'

export const selectUserProfile = state => state.user.profile

export const selectUserSettings = state => state.user.profile.user

export const selectUserIsLocked = createSelector(
  selectUserSettings,
  user => user.isLocked
)

export const selectDeleteAccountStatus = state => state.user.deleteAccountStatus

export const isDeleteAccountInProgress = createSelector(
  selectDeleteAccountStatus,
  status => UserService.isDeleteAccountInProgress(status)
)

export const selectSubscriptionPlanForPayment = state =>
  state.user.subscription.payment

export const selectIsUserConnectedToStripe = createSelector(
  selectUserProfile,
  profile => Boolean(profile?.stripeAccountId)
)

export const selectAllowMarketingTools = createSelector(
  selectUserProfile,
  profile => Boolean(profile?.subscription?.allowMarketingTools)
)

export const selectUserCurrency = createSelector(selectUserProfile, profile =>
  String(profile?.currency)
)

export const selectSubscriptionPlans = state => state.user.subscription.plans

export const selectUserSubscriptionPlan = createSelector(
  selectUserProfile,
  propOr({}, 'subscription')
)

export const selectIsUserOnTrialPeriod = createSelector(
  selectUserSubscriptionPlan,
  isSubscriptionPlanTrial
)

export const selectRespondentLimitExhausted = createSelector(
  selectUserSubscriptionPlan,
  isRespondentLimitExhausted
)

export const selectStripeAvailabilityStatus = createSelector(
  selectUserSubscriptionPlan,
  isStripeConnectionAllowed
)

export const selectUserPaymentMethod = createSelector(
  selectUserProfile,
  prop('paymentMethod')
)

export const selectVendorInvoices = state => state.user.invoices.list
export const selectVendorInvoicesPagination = state =>
  state.user.invoices?.pagination

export const selectUserLanguage = createSelector(
  selectUserProfile,
  prop('language')
)

export const selectUserSubscriptionState = createSelector(
  selectUserProfile,
  checkUserSubscriptionState
)

export const selectIsPaidSubscriptionActive = createSelector(
  selectUserProfile,
  isPaidSubscriptionActive
)
