/**
 * @module constants/navigation
 * @description Set of constants connected with routing, menus, navigation and
 * so on.
 */

import { CHATGPT_LINK, TEMPLATES_LINK, TUTORIAL_LINK } from 'constants/app'
import { getLinkByLocale } from 'utils/app'

export const FORM_PATH = '/form'
export const PREVIEW_FORM_PATH = '/form-preview'
export const CONFIGURE_FORM_PATH = '/configure-form'

/**
 * Used as a single source of truth for route names in the app. Remember that
 * all routes have to start with forward slash.
 * @constant
 * @name ROUTES
 */
export const ROUTES = {
  CONTACTS: {
    LIST: '/contacts',
    VIEW: '/contacts/:id/view'
  },
  INVOICES: {
    LIST: '/invoices'
  },
  EMBEDDED: {
    REGISTRATION: '/embed/register/:planId/form'
  },
  FORGOT_PASSWORD: '/forgot-password',
  LOGIN: '/login',
  LOGOUT: '/logout',
  PROFILE: '/profile',
  PROJECTS: '/projects',
  TEMPLATES: '/templates',
  STRIPE_CALLBACK: '/callback/stripe-connect',
  PAYMENTS: {
    START: '/transaction/:id',
    CONFIRMATION: '/transaction/:id/confirmation'
  },
  VENDOR: {
    REGISTRATION: '/register/:planId/form',
    REGISTRATION_CONFIRMATION: '/register/:planId/confirm',
    CONFIRM_EMAIL: '/confirm/email/:email/:hash',
    BUY_PLAN: '/buy-plan/:planId/:coupon?/form',
    BUY_PLAN_CONFIRMATION: '/buy-plan/:planId/confirm',
    UPDATE_PAYMENT_METHOD: '/update-payment-method'
  },
  FORM: {
    ROOT: `${FORM_PATH}/:id/*`,
    VIEW: `${FORM_PATH}/:id/view`,
    START_PAGE: `${FORM_PATH}/:id/view/start`,
    PAGE: `${FORM_PATH}/:id/view/page/:page`,
    INFORMATION: `${FORM_PATH}/:id/view/information`,
    SUMMARY: `${FORM_PATH}/:id/view/summary`,
    LICENSE_KEY: `${FORM_PATH}/:id/view/license`,
    NOT_ACTIVE: `${FORM_PATH}/:id/view/not-active`,
    SUB_PATH: {
      VIEW: 'view',
      START_PAGE: 'view/start',
      PAGE: 'view/page/:page',
      INFORMATION: 'view/information',
      SUMMARY: 'view/summary',
      LICENSE_KEY: 'view/license',
      NOT_ACTIVE: 'view/not-active'
    }
  },
  FORM_PREVIEW: {
    ROOT: `${PREVIEW_FORM_PATH}/:id/*`,
    VIEW: `${PREVIEW_FORM_PATH}/:id/view`,
    START_PAGE: `${PREVIEW_FORM_PATH}/:id/view/start`,
    PAGE: `${PREVIEW_FORM_PATH}/:id/view/page/:page`,
    INFORMATION: `${PREVIEW_FORM_PATH}/:id/view/information`,
    SUMMARY: `${PREVIEW_FORM_PATH}/:id/view/summary`,
    LICENSE_KEY: `${PREVIEW_FORM_PATH}/:id/view/license`,
    NOT_ACTIVE: `${PREVIEW_FORM_PATH}/:id/view/not-active`,
    SUB_PATH: {
      VIEW: 'view',
      START_PAGE: 'view/start',
      PAGE: 'view/page/:page',
      INFORMATION: 'view/information',
      SUMMARY: 'view/summary',
      LICENSE_KEY: 'view/license',
      NOT_ACTIVE: 'view/not-active'
    }
  },
  CONFIGURE_FORM: {
    ROOT: `${CONFIGURE_FORM_PATH}/*`,
    CREATE_FORM: `${CONFIGURE_FORM_PATH}/start`,
    EDIT_FORM: `${CONFIGURE_FORM_PATH}/start/:id/edit`,
    QUESTIONS: `${CONFIGURE_FORM_PATH}/questions/:id/edit`,
    OPT_IN: `${CONFIGURE_FORM_PATH}/opt-in/:id/edit`,
    THANK_YOU: `${CONFIGURE_FORM_PATH}/thank-you/:id/edit`,
    DESIGN: `${CONFIGURE_FORM_PATH}/design/:id/edit`,
    EMAIL: `${CONFIGURE_FORM_PATH}/email/:id/edit`,
    CONNECTION: `${CONFIGURE_FORM_PATH}/connection/:id/edit`,
    PAYMENT: `${CONFIGURE_FORM_PATH}/payment/:id/edit`,
    SUB_PATH: {
      CREATE_FORM: 'start',
      EDIT_FORM: 'start/:id/edit',
      QUESTIONS: 'questions/:id/edit',
      OPT_IN: 'opt-in/:id/edit',
      THANK_YOU: 'thank-you/:id/edit',
      DESIGN: 'design/:id/edit',
      EMAIL: 'email/:id/edit',
      CONNECTION: 'connection/:id/edit',
      PAYMENT: 'payment/:id/edit'
    }
  },
  STATISTICS: '/statistics',
  STATISTICS_EVALUATION: '/statistics/:id/evaluation/page/:page',
  SUBSCRIPTION: '/subscription',
  SUPPORT: '/support',
  TUTORIAL: '/tutorial',
  VALIDATE_RESET_PASSWORD: '/password/:email/:hash',
  WELCOME: '/welcome',
  ACCOUNT_DELETED: '/account-deleted',
  ADMIN_PANEL: {
    DASHBOARD: '/admin/dashboard',
    MANAGEMENT: '/admin/management',
    INVOICES: '/admin/invoices',
    TOOLS: '/admin/tools'
  }
}

/**
 * Dynamic routes are similar to normal routes, but they are parameterized.
 * @constant
 * @name DYNAMIC_ROUTES
 */
export const DYNAMIC_ROUTES = {
  CONTACTS: {
    LIST: q => `/contacts?${q}`,
    VIEW: id => `/contacts/${id}/view`
  },
  INVOICES: {
    LIST: q => `/invoices?${q}`,
    ADMIN_LIST: q => `/admin/invoices?${q}`
  },
  STATISTICS: q => `/statistics?${q}`,
  STATISTICS_EVALUATION: (id, page) =>
    `/statistics/${id}/evaluation/page/${page}`,
  ADMIN_STATISTICS: q => `/admin/dashboard?${q}`,
  ADMIN_DOWNLOAD_CSV_INVOICES: q => `/admin/tools?${q}`,
  ADMIN_MANAGEMENT: q => `/admin/management?${q}`,
  CONFIGURE_FORM: {
    CONNECTION: id => `/configure-form/connection/${id}/edit`,
    DESIGN: id => `/configure-form/design/${id}/edit`,
    EDIT_FORM: id => `/configure-form/start/${id}/edit`,
    EMAIL: id => `/configure-form/email/${id}/edit`,
    OPT_IN: id => `/configure-form/opt-in/${id}/edit`,
    PAYMENT: id => `/configure-form/payment/${id}/edit`,
    QUESTIONS: id => `/configure-form/questions/${id}/edit`,
    THANK_YOU: id => `/configure-form/thank-you/${id}/edit`
  },
  PAYMENTS: {
    START: id => `/transaction/${id}`,
    CONFIRMATION: id => `/transaction/${id}/confirmation`
  },
  VENDOR: {
    // @deprecated registration works differently now.
    // Added: Tue, 22 Sep 2020 12:56:56 +0200
    REGISTRATION: planId => `/register/${planId}/form`,
    // @deprecated registration works differently now.
    // Added: Tue, 22 Sep 2020 12:57:58 +0200
    REGISTRATION_CONFIRMATION: planId => `/register/${planId}/confirm`,
    BUY_PLAN: planId => `/buy-plan/${planId}/form`,
    BUY_PLAN_CONFIRMATION: planId => `/buy-plan/${planId}/confirm`
  },
  FORM: {
    ROOT: id => `/form/${id}`,
    VIEW: id => `/form/${id}/view`,
    START_PAGE: id => `/form/${id}/view/start`,
    PAGE: (id, page) => `/form/${id}/view/page/${page}`,
    INFORMATION: id => `/form/${id}/view/information`,
    SUMMARY: id => `/form/${id}/view/summary`,
    NOT_ACTIVE: id => `/form/${id}/view/not-active`,
    LICENSE_KEY: id => `/form/${id}/view/license`
  },
  FORM_PREVIEW: {
    ROOT: id => `/form-preview/${id}`,
    VIEW: id => `/form-preview/${id}/view`,
    START_PAGE: id => `/form-preview/${id}/view/start`,
    PAGE: (id, page) => `/form-preview/${id}/view/page/${page}`,
    INFORMATION: id => `/form-preview/${id}/view/information`,
    SUMMARY: id => `/form-preview/${id}/view/summary`,
    NOT_ACTIVE: id => `/form-preview/${id}/view/not-active`,
    LICENSE_KEY: id => `/form-preview/${id}/view/license`
  }
}

export const EXTERNAL_ROUTES = {
  FRONTLEAD: 'https://frontlead.io'
}

/**
 * Please note that `icon` property has to match one of `CUSTOM_ICONS` keys.
 * @name MAIN_MENU_ITEMS
 * @constant
 * @param {Function} t A translation interface scoped to 'MainNavigation'.
 */
export const MAIN_MENU_ITEMS = t => [
  {
    name: t('quizzes'),
    link: ROUTES.PROJECTS,
    icon: 'web',
    dataId: 'menu-projects'
  },
  {
    name: t('startQuiz'),
    link: ROUTES.CONFIGURE_FORM.ROOT,
    icon: 'create',
    dataId: 'menu-create-project'
  },
  {
    name: t('contacts'),
    link: ROUTES.CONTACTS.LIST,
    icon: 'people',
    dataId: 'menu-contacts'
  },
  {
    name: t('statistics'),
    link: ROUTES.STATISTICS,
    icon: 'pie-chart',
    dataId: 'menu-statistics'
  },
  {
    name: t('templates'),
    link: ROUTES.TEMPLATES,
    icon: 'article',
    dataId: 'menu-templates'
  }
]

/**
 * Please note that `icon` property has to match one of `CUSTOM_ICONS` keys.
 * @name MAIN_MENU_ITEMS_ADMIN
 * @constant
 * @param {Function} t A translation interface scoped to 'MainNavigationAdmin'.
 */
export const MAIN_MENU_ITEMS_ADMIN = t => [
  {
    name: t('dashboard'),
    link: ROUTES.ADMIN_PANEL.DASHBOARD,
    icon: 'dashboard',
    dataId: 'menu-dashboard'
  },
  {
    name: t('management'),
    link: ROUTES.ADMIN_PANEL.MANAGEMENT,
    icon: 'accounts',
    dataId: 'menu-management'
  },
  {
    name: t('invoices'),
    link: ROUTES.ADMIN_PANEL.INVOICES,
    icon: 'file',
    dataId: 'menu-invoices'
  },
  {
    name: t('tools'),
    link: ROUTES.ADMIN_PANEL.TOOLS,
    icon: 'settings',
    dataId: 'menu-tools'
  }
]

/**
 * Menu items presented in the top right-hand corner of the dashboard. Please
 * note that `icon` property has to match one of `CUSTOM_ICONS` keys. Logout
 * button is treated differently.
 * @name USER_MENU_ITEMS
 * @constant
 * @param {Function} t A translation interface scoped to 'UserDropdownNavigation'.
 */
export const USER_MENU_ITEMS = t => [
  {
    name: t('profile'),
    external: false,
    link: ROUTES.PROFILE
  },
  {
    name: t('subscription'),
    external: false,
    link: ROUTES.SUBSCRIPTION
  },
  {
    name: t('invoices'),
    external: false,
    link: ROUTES.INVOICES.LIST
  },
  {
    name: t('tutorial'),
    external: true,
    link: getLinkByLocale(TUTORIAL_LINK)
  },
  {
    name: t('templates'),
    external: true,
    link: getLinkByLocale(TEMPLATES_LINK)
  },
  {
    name: t('chatgpt'),
    external: true,
    link: getLinkByLocale(CHATGPT_LINK)
  },
  {
    name: t('support'),
    external: false,
    link: ROUTES.SUPPORT
  }
]
