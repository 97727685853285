import { LANGUAGE_CODES } from 'services/LanguageService'

export const LANDING_PAGE_URL = 'https://frontlead.io'

export const PAYMENTS_BASE_URL = 'https://securepayment.frontlead.io'
export const TEST_URL = 'https://quizchecks-front.test.desmart.live'

export const SUPPORT_EMAIL = 'support@frontlead.io'

export const TUTORIAL_LINK = {
  [LANGUAGE_CODES.GERMAN]: 'https://frontlead.io/de/tutorial/',
  [LANGUAGE_CODES.FRENCH]: 'https://frontlead.io/fr/tutorial/',
  [LANGUAGE_CODES.PORTUGUESE]: 'https://frontlead.io/pt/tutorial/',
  [LANGUAGE_CODES.SPANISH]: 'https://frontlead.io/es/tutorial/',
  [LANGUAGE_CODES.ENGLISH]: 'https://frontlead.io/tutorial/'
}

export const CHATGPT_LINK = {
  [LANGUAGE_CODES.GERMAN]: 'https://frontlead.io/de/chatgpt/',
  [LANGUAGE_CODES.FRENCH]: 'https://frontlead.io/fr/chatgpt/',
  [LANGUAGE_CODES.PORTUGUESE]: 'https://frontlead.io/pt/chatgpt/',
  [LANGUAGE_CODES.SPANISH]: 'https://frontlead.io/es/chatgpt/',
  [LANGUAGE_CODES.ENGLISH]: 'https://frontlead.io/chatgpt/'
}

export const TEMPLATES_LINK = {
  [LANGUAGE_CODES.GERMAN]: 'https://frontlead.io/de/templates/',
  [LANGUAGE_CODES.FRENCH]: 'https://frontlead.io/fr/templates/',
  [LANGUAGE_CODES.PORTUGUESE]: 'https://frontlead.io/pt/templates/',
  [LANGUAGE_CODES.SPANISH]: 'https://frontlead.io/es/templates/',
  [LANGUAGE_CODES.ENGLISH]: 'https://frontlead.io/templates/'
}
