/**
 * @module epics/app
 * @description Business logic connected with how the app works.
 */
import { combineEpics, ofType } from 'redux-observable'
import { of, from } from 'rxjs'
import { mergeMap, catchError } from 'rxjs/operators'

import * as types from 'core/redux/types/app'
import * as actions from 'core/redux/actions/app'

import LanguageService from 'services/LanguageService'

const changeAppLanguage = action$ =>
  action$.pipe(
    ofType(types.CHANGE_APP_LANGUAGE),
    mergeMap(action =>
      from(LanguageService.changeLanguage(action.data)).pipe(
        mergeMap(data => of(actions.appLanguageChangeSuccess(action.data))),
        catchError(err => of(actions.appLanguageChangeFailed(err)))
      )
    )
  )

export const appEpics = combineEpics(changeAppLanguage)
