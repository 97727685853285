/**
 * A class representing application's redux store, served as a singleton.
 * @class
 * @author Rafał Wyszomirski
 */

import { createStore, applyMiddleware } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import { createLogger } from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'

import rootReducer from 'core/redux/reducers'
import rootEpic from 'core/redux/epics'

import { getSessionStorage, isDebug } from 'utils/app'

class ReduxStore {
  constructor() {
    if (typeof ReduxStore.instance === 'object') {
      return ReduxStore.instance
    }

    /**
     * A middleware that is required for `redux-observable` to work.
     **/
    this.epicMiddleware = createEpicMiddleware()

    /**
     * An array of middlewares used, regardless of the environment.
     */
    this.middlewares = [this.epicMiddleware]

    // Include logger for the browser if this is a development environment.
    if (isDebug()) {
      this.middlewares.push(createLogger())
    }

    /**
     * Redux-persist configuration. The application used sessionStorage by
     * default and all resources are fetched during runtime. This is to avoid
     * issues with using the app in public places, where localStorage could be
     * extracted easily.
     */
    const persistConfig = {
      key: 'root',
      storage: getSessionStorage(),
      blacklist: ['forms', 'question', 'quiz']
    }

    const persistedReducer = persistReducer(persistConfig, rootReducer)

    /**
     * An instance of redux app store.
     */
    this.store = createStore(
      persistedReducer,
      applyMiddleware(...this.middlewares)
    )

    this.persistor = persistStore(this.store)

    // Make sure that the redux-observable middleware starts listening for
    // actions.
    this.epicMiddleware.run(rootEpic)

    ReduxStore.instance = this

    return this
  }
}

export default new ReduxStore()
