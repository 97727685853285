import axios from 'axios'
import { mergeDeepRight, pipe } from 'ramda'
import EmbeddedStorage from 'core/EmbeddedStorage'

import { STORAGE_KEYS } from 'constants/storage'
import { isEmbeddedExternally } from 'utils/dom'

/**
 *
 * @class ApiClient
 * @description A client for handling requests made to FRONTLEAD
 * API. Initialized as a singleton.
 */
class ApiClient {
  constructor() {
    if (typeof ApiClient.instance === 'object') {
      return ApiClient.instance
    }

    this.isStoragePersistent = () => {
      if (isEmbeddedExternally()) {
        return false
      }

      return window.localStorage.getItem(STORAGE_KEYS.PERSIST) === 'true'
    }

    /**
     * Check what kind of session has user chosen before. By default, assume
     * that it is going to be `sessionStorage`. Use `localStorage` or any other
     * persistent storage for saving that information that has a compatible
     * interface for setting and getting values.
     *
     * **Notice:** Please note that in case of default Web Storage APIs, boolean
     * values are cast to string.
     * @memberOf ApiClient
     * @member {Storage}
     */
    if (isEmbeddedExternally()) {
      this.storage = EmbeddedStorage
    } else {
      this.storage = this.isStoragePersistent()
        ? window.localStorage
        : window.sessionStorage
    }

    /**
     * Keeps information about the API token that is used for authorized
     * requests.
     * @memberOf ApiClient
     * @member {String}
     */
    this.token = this.storage.getItem(STORAGE_KEYS.TOKEN) || ''

    /**
     * A base URL of the QuizChecks API.
     * @memberOf ApiClient
     * @member {String}
     */
    this.baseUrl = import.meta.env.VITE_API_URL

    this.defaultConfig = {
      baseURL: this.baseUrl,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }

    this.axios = axios.create(this.defaultConfig)

    ApiClient.instance = this

    return this
  }

  /**
   * Sets the type of storage used for the app.
   * @name setStorageType
   * @method
   * @static
   * @memberOf ApiClient
   * @param {boolean} shouldPersist
   */
  setStorageType(shouldPersist) {
    let storageType

    if (isEmbeddedExternally()) {
      storageType = EmbeddedStorage
    } else {
      window.localStorage.setItem(STORAGE_KEYS.PERSIST, shouldPersist)
      storageType = shouldPersist ? window.localStorage : window.sessionStorage
    }

    this.storage = storageType
  }

  /**
   * Sets the authorization token for API requests in previously set storage
   * solution. This can be either `localStorage` or `sessionStorage`. It depends
   * on the user and the choice they've made during logging in.
   * @name setAuthorizationToken
   * @method
   * @static
   * @memberOf ApiClient
   * @param {String} token A regular JWT token returned from successful `/login` response.
   */
  setAuthorizationToken(token) {
    this.storage.setItem(STORAGE_KEYS.TOKEN, token)
    this.token = token
  }

  removeSession() {
    this.storage.removeItem(STORAGE_KEYS.TOKEN)
    this.token = ''
  }

  get authConfig() {
    return this.token !== '' ? { headers: { token: this.token } } : {}
  }

  mergeRequestConfig(config = {}) {
    return pipe(
      obj => mergeDeepRight(obj, this.defaultConfig),
      obj => mergeDeepRight(obj, this.authConfig),
      obj => mergeDeepRight(obj, config)
    )({})
  }

  get({ url, config }) {
    return this.axios.get(url, this.mergeRequestConfig(config))
  }

  post({ url, data, config }) {
    return this.axios.post(url, data, this.mergeRequestConfig(config))
  }

  put({ url, data, config }) {
    return this.axios.put(url, data, this.mergeRequestConfig(config))
  }

  patch({ url, data, config }) {
    return this.axios.patch(url, data, this.mergeRequestConfig(config))
  }

  delete({ url, params }) {
    return this.axios.delete(url, this.mergeRequestConfig({ params }))
  }
}

export default new ApiClient()
