/**
 * Used for initial quiz creation.
 */
export const REQUEST_CREATE_QUIZ = 'REQUEST_CREATE_QUIZ'
export const CREATE_QUIZ_SUCCESS = 'CREATE_QUIZ_SUCCESS'
export const CREATE_QUIZ_FAILED = 'CREATE_QUIZ_FAILED'

/**
 * Used for fetching a list of quizzes.
 */
export const REQUEST_GET_QUIZZES = 'REQUEST_GET_QUIZZES'
export const GET_QUIZZES_SUCCESS = 'GET_QUIZZES_SUCCESS'
export const GET_QUIZZES_FAILED = 'GET_QUIZZES_FAILED'

/**
 * Used for fetching a list of templates.
 */
export const REQUEST_GET_TEMPLATES = 'REQUEST_GET_TEMPLATES'
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS'
export const GET_TEMPLATES_FAILED = 'GET_TEMPLATES_FAILED'

/**
 * Used for editing/updating the quiz.
 */
export const REQUEST_UPDATE_QUIZ = 'REQUEST_UPDATE_QUIZ'
export const UPDATE_QUIZ_SUCCESS = 'UPDATE_QUIZ_SUCCESS'
export const UPDATE_QUIZ_FAILED = 'UPDATE_QUIZ_FAILED'

/**
 * Used for fetching single quiz. Useful for initializing after page is visited
 * directly.
 */
export const REQUEST_GET_QUIZ_BY_ID = 'REQUEST_GET_QUIZ_BY_ID'
export const GET_QUIZ_BY_ID_SUCCESS = 'GET_QUIZ_BY_ID_SUCCESS'
export const GET_QUIZ_BY_ID_FAILED = 'GET_QUIZ_BY_ID_FAILED'

/**
 * Used for creating questions for quiz.
 */
export const REQUEST_CREATE_SCORE_EMAIL_REPLY =
  'REQUEST_CREATE_SCORE_EMAIL_REPLY'
export const CREATE_SCORE_EMAIL_REPLY_SUCCESS =
  'CREATE_SCORE_EMAIL_REPLY_SUCCESS'
export const CREATE_SCORE_EMAIL_REPLY_FAILED = 'CREATE_SCORE_EMAIL_REPLY_FAILED'

/**
 * Used for creating questions for quiz.
 */
export const REQUEST_CREATE_QUESTION = 'REQUEST_CREATE_QUESTION'
export const CREATE_QUESTION_SUCCESS = 'CREATE_QUESTION_SUCCESS'
export const CREATE_QUESTION_FAILED = 'CREATE_QUESTION_FAILED'

/**
 * Used for creating grouped questions with new question group settings for quiz.
 */
export const REQUEST_CREATE_GROUPED_QUESTION_WITH_SETTINGS =
  'REQUEST_CREATE_GROUPED_QUESTION_WITH_SETTINGS'
export const CREATE_GROUPED_QUESTION_WITH_SETTINGS_SUCCESS =
  'CREATE_GROUPED_QUESTION_WITH_SETTINGS_SUCCESS'
export const CREATE_GROUPED_QUESTION_WITH_SETTINGS_FAILED =
  'CREATE_GROUPED_QUESTION_WITH_SETTINGS_FAILED'

/**
 * Used for creating grouped questions with already defined question group settings for quiz.
 */
export const REQUEST_CREATE_GROUPED_QUESTION = 'REQUEST_CREATE_GROUPED_QUESTION'
export const CREATE_GROUPED_QUESTION_SUCCESS = 'CREATE_GROUPED_QUESTION_SUCCESS'
export const CREATE_GROUPED_QUESTION_FAILED = 'CREATE_GROUPED_QUESTION_FAILED'

/**
 * Used for update grouped question settings for quiz.
 */
export const REQUEST_UPDATE_GROUPED_QUESTION_SETTINGS =
  'REQUEST_UPDATE_GROUPED_QUESTION_SETTINGS'
export const UPDATE_GROUPED_QUESTION_SETTINGS_SUCCESS =
  'UPDATE_GROUPED_QUESTION_SETTINGS_SUCCESS'
export const UPDATE_GROUPED_QUESTION_SETTINGS_FAILED =
  'UPDATE_GROUPED_QUESTION_SETTINGS_FAILED'

/**
 * Used for deleting questions from particular quiz.
 */
export const REQUEST_DELETE_QUESTION = 'REQUEST_DELETE_QUESTION'
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS'
export const DELETE_QUESTION_FAILED = 'DELETE_QUESTION_FAILED'

/**
 * Used for updating single question.
 */
export const REQUEST_UPDATE_QUESTION = 'REQUEST_UPDATE_QUESTION'
export const UPDATE_QUESTION_SUCCESS = 'UPDATE_QUESTION_SUCCESS'
export const UPDATE_QUESTION_FAILED = 'UPDATE_QUESTION_FAILED'

/**
 * Used for updating single question its answers.
 */
export const REQUEST_UPDATE_QUESTION_WITH_ANSWERS =
  'REQUEST_UPDATE_QUESTION_WITH_ANSWERS'
export const UPDATE_QUESTION_WITH_ANSWERS_SUCCESS =
  'UPDATE_QUESTION_WITH_ANSWERS_SUCCESS'
export const UPDATE_QUESTION_WITH_ANSWERS_FAILED =
  'UPDATE_QUESTION_WITH_ANSWERS_FAILED'

/**
 * Used for creating answer to a question.
 */
export const REQUEST_CREATE_ANSWER = 'REQUEST_CREATE_ANSWER'
export const CREATE_ANSWER_SUCCESS = 'CREATE_ANSWER_SUCCESS'
export const CREATE_ANSWER_FAILED = 'CREATE_ANSWER_FAILED'

/**
 * Used for deleting particular answer from a question.
 */
export const REQUEST_DELETE_ANSWER = 'REQUEST_DELETE_ANSWER'
export const DELETE_ANSWER_SUCCESS = 'DELETE_ANSWER_SUCCESS'
export const DELETE_ANSWER_FAILED = 'DELETE_ANSWER_FAILED'

/**
 * Used for updating single answer.
 */
export const REQUEST_UPDATE_ANSWER = 'REQUEST_UPDATE_ANSWER'
export const UPDATE_ANSWER_SUCCESS = 'UPDATE_ANSWER_SUCCESS'
export const UPDATE_ANSWER_FAILED = 'UPDATE_ANSWER_FAILED'

/**
 * Used for updating single email score.
 */
export const REQUEST_SAVE_ALL_EMAIL_SCORING = 'REQUEST_SAVE_ALL_EMAIL_SCORING'
export const UPDATE_EMAIL_FOR_SCORING_SUCCESS =
  'UPDATE_EMAIL_FOR_SCORING_SUCCESS'
export const UPDATE_EMAIL_FOR_SCORING_FAILED = 'UPDATE_EMAIL_FOR_SCORING_FAILED'

/**
 * Used for updating single email score.
 */
export const REQUEST_UPDATE_EMAIL_FOR_SCORING =
  'REQUEST_UPDATE_EMAIL_FOR_SCORING'
export const SAVE_ALL_EMAIL_SCORING_SUCCESS = 'SAVE_ALL_EMAIL_SCORING_SUCCESS'
export const SAVE_ALL_EMAIL_SCORING_FAILED = 'SAVE_ALL_EMAIL_SCORING_FAILED'

/**
 * Used for deleting single email score quiz.
 */
export const REQUEST_DELETE_SINGLE_EMAIL_FOR_SCORING =
  'REQUEST_DELETE_SINGLE_EMAIL_FOR_SCORING'
export const DELETE_SINGLE_EMAIL_FOR_SCORING_SUCCESS =
  'DELETE_SINGLE_EMAIL_FOR_SCORING_SUCCESS'
export const DELETE_SINGLE_EMAIL_FOR_SCORING_FAILED =
  'DELETE_SINGLE_EMAIL_FOR_SCORING_FAILED'

/**
 * Used for deleting all emails score quiz.
 */
export const REQUEST_DELETE_ALL_EMAIL_FOR_SCORING =
  'REQUEST_DELETE_ALL_EMAIL_FOR_SCORING'
export const DELETE_ALL_EMAIL_FOR_SCORING_SUCCESS =
  'DELETE_ALL_EMAIL_FOR_SCORING_SUCCESS'
export const DELETE_ALL_EMAIL_FOR_SCORING_FAILED =
  'DELETE_ALL_EMAIL_FOR_SCORING_FAILED'

/**
 * Used for creating page title.
 */
export const REQUEST_CREATE_TITLE = 'REQUEST_CREATE_TITLE'
export const CREATE_TITLE_SUCCESS = 'CREATE_TITLE_SUCCESS'
export const CREATE_TITLE_FAILED = 'CREATE_TITLE_FAILED'

/**
 * Used for deleting title quiz.
 */
export const REQUEST_DELETE_TITLE = 'REQUEST_DELETE_TITLE'
export const DELETE_TITLE_SUCCESS = 'DELETE_TITLE_SUCCESS'
export const DELETE_TITLE_FAILED = 'DELETE_TITLE_FAILED'

/**
 * Used for updating title and subtitle of the page in quiz.
 */
export const REQUEST_UPDATE_TITLE = 'REQUEST_UPDATE_TITLE'
export const UPDATE_TITLE_SUCCESS = 'UPDATE_TITLE_SUCCESS'
export const UPDATE_TITLE_FAILED = 'UPDATE_TITLE_FAILED'

/**
 * Used for mass update of the quiz entities.
 */
export const REQUEST_BULK_QUIZ_UPDATE = 'REQUEST_BULK_QUIZ_UPDATE'
export const BULK_QUIZ_UPDATE_SUCCESS = 'BULK_QUIZ_UPDATE_SUCCESS'
export const BULK_QUIZ_UPDATE_FAILED = 'BULK_QUIZ_UPDATE_FAILED'

/**
 * Used for deleting the quiz.
 */
export const REQUEST_DELETE_QUIZ = 'REQUEST_DELETE_QUIZ'
export const DELETE_QUIZ_SUCCESS = 'DELETE_QUIZ_SUCCESS'
export const DELETE_QUIZ_FAILED = 'DELETE_QUIZ_FAILED'

/**
 * Used for quiz duplication.
 */
export const REQUEST_DUPLICATE_QUIZ = 'REQUEST_DUPLICATE_QUIZ'
export const DUPLICATE_QUIZ_SUCCESS = 'DUPLICATE_QUIZ_SUCCESS'
export const DUPLICATE_QUIZ_FAILED = 'DUPLICATE_QUIZ_FAILED'

/**
 * Used for fetching quiz in respondent flow.
 */
export const REQUEST_GET_QUIZ_FOR_FLOW = 'REQUEST_GET_QUIZ_FOR_FLOW'
export const GET_QUIZ_FOR_FLOW_SUCCESS = 'GET_QUIZ_FOR_FLOW_SUCCESS'
export const GET_QUIZ_FOR_FLOW_FAILED = 'GET_QUIZ_FOR_FLOW_FAILED'

/**
 * Used to create respondent in free quiz flow.
 */
export const REQUEST_CREATE_RESPONDENT = 'REQUEST_CREATE_RESPONDENT'
export const CREATE_RESPONDENT_SUCCESS = 'CREATE_RESPONDENT_SUCCESS'
export const CREATE_RESPONDENT_FAILED = 'CREATE_RESPONDENT_FAILED'

/**
 * Used to create respondent by coupon in free quiz flow.
 */
export const REQUEST_CREATE_RESPONDENT_BY_COUPON =
  'REQUEST_CREATE_RESPONDENT_BY_COUPON'
export const CREATE_RESPONDENT_BY_COUPON_SUCCESS =
  'CREATE_RESPONDENT_BY_COUPON_SUCCESS'
export const CREATE_RESPONDENT_BY_COUPON_FAILED =
  'CREATE_RESPONDENT_BY_COUPON_FAILED'

/**
 * Action type for GET_QUIZ_BY_RESPONDENT_UID.
 * Added: Thu, 23 Dec 2021 10:53:52 +0100
 */
export const REQUEST_GET_QUIZ_BY_RESPONDENT_UID =
  'REQUEST_GET_QUIZ_BY_RESPONDENT_UID'
export const GET_QUIZ_BY_RESPONDENT_UID_SUCCESS =
  'GET_QUIZ_BY_RESPONDENT_UID_SUCCESS'
export const GET_QUIZ_BY_RESPONDENT_UID_FAILED =
  'GET_QUIZ_BY_RESPONDENT_UID_FAILED'

/**
 * Used for bulk post of respondent answers.
 */
export const REQUEST_POST_RESPONDENT_ANSWERS = 'REQUEST_POST_RESPONDENT_ANSWERS'
export const POST_RESPONDENT_ANSWERS_SUCCESS = 'POST_RESPONDENT_ANSWERS_SUCCESS'
export const POST_RESPONDENT_ANSWERS_FAILED = 'POST_RESPONDENT_ANSWERS_FAILED'

/**
 * Used for updating respondent info, for example on information to gather page.
 */
export const REQUEST_UPDATE_RESPONDENT = 'REQUEST_UPDATE_RESPONDENT'
export const UPDATE_RESPONDENT_SUCCESS = 'UPDATE_RESPONDENT_SUCCESS'
export const UPDATE_RESPONDENT_FAILED = 'UPDATE_RESPONDENT_FAILED'

/**
 * Used for fetching quiz statistics. Most probably should be moved to separate
 * `statistics` module.
 */
export const REQUEST_GET_STATISTICS = 'REQUEST_GET_STATISTICS'
export const GET_STATISTICS_SUCCESS = 'GET_STATISTICS_SUCCESS'
export const GET_STATISTICS_FAILED = 'GET_STATISTICS_FAILED'

/**
 * Used for fetching quiz when doing payments.
 */
export const REQUEST_GET_QUIZ_FOR_TRANSACTION =
  'REQUEST_GET_QUIZ_FOR_TRANSACTION'
export const GET_QUIZ_FOR_TRANSACTION_SUCCESS =
  'GET_QUIZ_FOR_TRANSACTION_SUCCESS'
export const GET_QUIZ_FOR_TRANSACTION_FAILED = 'GET_QUIZ_FOR_TRANSACTION_FAILED'

/**
 * Used for validating and fetching paid quiz.
 */
export const REQUEST_GET_PAID_QUIZ_BY_ID = 'REQUEST_GET_PAID_QUIZ_BY_ID'
export const GET_PAID_QUIZ_BY_ID_SUCCESS = 'GET_PAID_QUIZ_BY_ID_SUCCESS'
export const GET_PAID_QUIZ_BY_ID_FAILED = 'GET_PAID_QUIZ_BY_ID_FAILED'

/**
 * Used for fetching list of respondents (contacts).
 */
export const REQUEST_GET_RESPONDENTS = 'REQUEST_GET_RESPONDENTS'
export const GET_RESPONDENTS_SUCCESS = 'GET_RESPONDENTS_SUCCESS'
export const GET_RESPONDENTS_FAILED = 'GET_RESPONDENTS_FAILED'

/**
 * Used on respondent details view, showing respondent answers to quiz.
 */
export const REQUEST_GET_RESPONDENT_QUIZ = 'REQUEST_GET_RESPONDENT_QUIZ'
export const GET_RESPONDENT_QUIZ_SUCCESS = 'GET_RESPONDENT_QUIZ_SUCCESS'
export const GET_RESPONDENT_QUIZ_FAILED = 'GET_RESPONDENT_QUIZ_FAILED'

/**
 * Used for actions connected with deleting quiz respondent.
 */
export const REQUEST_DELETE_RESPONDENT = 'REQUEST_DELETE_RESPONDENT'
export const DELETE_RESPONDENT_SUCCESS = 'DELETE_RESPONDENT_SUCCESS'
export const DELETE_RESPONDENT_FAILED = 'DELETE_RESPONDENT_FAILED'

/**
 * Used for sending analysis manually from contacts list.
 */
export const REQUEST_SEND_ANALYSIS_BY_EMAIL = 'REQUEST_SEND_ANALYSIS_BY_EMAIL'
export const SEND_ANALYSIS_BY_EMAIL_SUCCESS = 'SEND_ANALYSIS_BY_EMAIL_SUCCESS'
export const SEND_ANALYSIS_BY_EMAIL_FAILED = 'SEND_ANALYSIS_BY_EMAIL_FAILED'

/**
 * Used for resetting single quiz statistics.
 */
export const REQUEST_RESET_QUIZ_STATISTICS = 'REQUEST_RESET_QUIZ_STATISTICS'
export const RESET_QUIZ_STATISTICS_SUCCESS = 'RESET_QUIZ_STATISTICS_SUCCESS'
export const RESET_QUIZ_STATISTICS_FAILED = 'RESET_QUIZ_STATISTICS_FAILED'

/**
 * Used for resetting single quiz evaluation.
 */
export const REQUEST_RESET_QUIZ_EVALUATION = 'REQUEST_RESET_QUIZ_EVALUATION'
export const RESET_QUIZ_EVALUATION_SUCCESS = 'RESET_QUIZ_EVALUATION_SUCCESS'
export const RESET_QUIZ_EVALUATION_FAILED = 'RESET_QUIZ_EVALUATION_FAILED'

/**
 * Used for get statistic evaluation.
 */
export const REQUEST_GET_STATISTIC_EVALUATION_BY_ID =
  'REQUEST_GET_STATISTIC_EVALUATION_BY_ID'
export const GET_STATISTIC_EVALUATION_SUCCESS =
  'GET_STATISTIC_EVALUATION_SUCCESS'
export const GET_STATISTIC_EVALUATION_FAILED = 'GET_STATISTIC_EVALUATION_FAILED'

/**
 * Determines whether respondent started quiz.
 */
export const REQUEST_MARK_QUIZ_AS_STARTED = 'REQUEST_MARK_QUIZ_AS_STARTED'
export const MARK_QUIZ_AS_STARTED_SUCCESS = 'MARK_QUIZ_AS_STARTED_SUCCESS'
export const MARK_QUIZ_AS_STARTED_FAILED = 'MARK_QUIZ_AS_STARTED_FAILED'

/**
 * Determines whether respondent finished quiz.
 */
export const REQUEST_MARK_QUIZ_AS_FINISHED = 'REQUEST_MARK_QUIZ_AS_FINISHED'
export const MARK_QUIZ_AS_FINISHED_SUCCESS = 'MARK_QUIZ_AS_FINISHED_SUCCESS'
export const MARK_QUIZ_AS_FINISHED_FAILED = 'MARK_QUIZ_AS_FINISHED_FAILED'
/**
 * Used for updating quiz specifically in OptIn step.
 * Added: Thu, 21 Jan 2021 13:55:27 +0100
 */
export const REQUEST_UPDATE_OPT_IN_STEP = 'REQUEST_UPDATE_OPT_IN_STEP'
export const UPDATE_OPT_IN_STEP_SUCCESS = 'UPDATE_OPT_IN_STEP_SUCCESS'
export const UPDATE_OPT_IN_STEP_FAILED = 'UPDATE_OPT_IN_STEP_FAILED'

/**
 * Used for updating quiz OptInInformationToGather specifically in OptIn step.
 * Added: Thu, 21 Jan 2021 13:55:27 +0100
 */
export const REQUEST_UPDATE_OPT_IN_INFORMATION_TO_GATHER =
  'REQUEST_UPDATE_OPT_IN_INFORMATION_TO_GATHER'
export const UPDATE_OPT_IN_INFORMATION_TO_GATHER_SUCCESS =
  'UPDATE_OPT_IN_INFORMATION_TO_GATHER_SUCCESS'
export const UPDATE_OPT_IN_INFORMATION_TO_GATHER_FAILED =
  'UPDATE_OPT_IN_INFORMATION_TO_GATHER_FAILED'

export const REQUEST_CREATE_DEFAULT_ANSWERS_FOR_GROUPED_QUESTION =
  'REQUEST_CREATE_DEFAULT_ANSWERS_FOR_GROUPED_QUESTION'
export const CREATE_DEFAULT_ANSWERS_FOR_GROUPED_QUESTION_SUCCESS =
  'CREATE_DEFAULT_ANSWERS_FOR_GROUPED_QUESTION_SUCCESS'
export const CREATE_DEFAULT_ANSWERS_FOR_GROUPED_QUESTION_FAILED =
  'CREATE_DEFAULT_ANSWERS_FOR_GROUPED_QUESTION_FAILED'
