import { persistReducer, createMigrate } from 'redux-persist'
import * as types from 'core/redux/types/user'
import { DELETE_ACCOUNT_STATUS, INVOICES_PER_PAGE } from 'constants/user'

import { getSessionStorage, isDebug } from 'utils/app'

const defaultState = {
  profile: {
    user: {}
  },
  deleteAccountStatus: DELETE_ACCOUNT_STATUS.INITIAL,
  subscription: {
    // A list of plans available for vendor to choose from.
    plans: [],
    // A plan that vendor is paying for during registration.
    payment: {}
  },
  invoices: {
    list: [],
    pagination: {
      offset: 0,
      total: 0,
      done: false
    },
    perPage: INVOICES_PER_PAGE,
    // A map that stores answers to quiz questions given by respondents.
    entries: {}
  }
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS: {
      return {
        ...state,
        profile: action.data.user
      }
    }
    case types.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.data
      }
    case types.UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          user: {
            ...state.profile.user,
            ...action.data.user
          }
        }
      }
    case types.INITIALIZE_DELETE_ACCOUNT:
      return {
        ...state,
        deleteAccountStatus: DELETE_ACCOUNT_STATUS.INITIAL
      }
    case types.REQUEST_DELETE_ACCOUNT:
      return {
        ...state,
        deleteAccountStatus: DELETE_ACCOUNT_STATUS.IN_PROGRESS
      }
    case types.DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        deleteAccountStatus: DELETE_ACCOUNT_STATUS.SUCCESS
      }
    case types.DELETE_ACCOUNT_FAILED:
      return {
        ...state,
        deleteAccountStatus: DELETE_ACCOUNT_STATUS.FAILED
      }
    case types.GET_PROFILE_SUCCESS:
    case types.DELETE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        profile: action.data
      }
    case types.SET_SUBSCRIPTION_PLAN:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          payment: action.data
        }
      }
    case types.CANCEL_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        profile: action.data
      }
    case types.UPDATE_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          subscription: action?.data.subscription
        }
      }
    case types.GET_SUBSCRIPTION_PLANS_SUCCESS:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          plans: action.data
        }
      }
    case types.GET_SUBSCRIPTION_PLAN_BY_ID_SUCCESS:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          payment: action.data
        }
      }
    case types.GET_VENDOR_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          list: action.data.results,
          pagination: action.data.status
        }
      }
    case types.LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

const migrations = {
  0: state => {
    return {
      ...state,
      deleteAccountStatus: DELETE_ACCOUNT_STATUS.INITIAL
    }
  },
  1: state => {
    return {
      ...state,
      invoices: {
        list: [],
        pagination: {
          offset: 0,
          total: 0,
          done: false
        },
        perPage: INVOICES_PER_PAGE,
        // A map that stores answers to quiz questions given by respondents.
        entries: {}
      }
    }
  }
}

const userPersistConfig = {
  version: 1,
  key: 'user',
  storage: getSessionStorage(),
  blacklist: ['deleteAccountStatus', 'subscription'],
  migrate: createMigrate(migrations, { debug: isDebug() })
}

export default persistReducer(userPersistConfig, reducer)
