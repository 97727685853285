import qs from 'qs'
import ApiClient from 'core/ApiClient'
import { DELETE_ACCOUNT_STATUS, INVOICES_PER_PAGE } from 'constants/user'

/**
 *
 * @module services/UserService
 * @description A service mostly used handling requests connected with the user
 * entity.
 */
const UserService = {
  /**
   * Checks if delete account is in progress. Uses DELETE_ACCOUNT_STATUS enum.
   * @name isDeleteAccountInProgress
   * @method
   * @static
   * @param {DeleteAccountStatus} status Check {@link module:constants/user.DELETE_ACCOUNT_STATUS} enum.
   * @returns {boolean}
   */
  isDeleteAccountInProgress: status =>
    status === DELETE_ACCOUNT_STATUS.IN_PROGRESS,
  /**
   * Get profile details. Useful when user closes their browser and his session
   * and details have to be restored. Again, for security reasons, state is not
   * persisted to local storage.
   * @name getProfile
   * @method
   * @static
   * @returns {Promise}
   */
  getProfile: () =>
    ApiClient.get({
      url: '/user'
    }),
  /**
   * Used for new registration flow.
   * @name createAccount
   * @method
   * @static
   * @param {Object} payload
   * @returns {Promise}
   */
  createAccount: payload =>
    ApiClient.post({
      url: '/user',
      data: payload,
      config: { headers: { token: '' } }
    }),
  updateProfile: payload =>
    ApiClient.put({
      url: '/user',
      data: payload
    }),
  /**
   * Update password as an authorized user.
   * @name updatePassword
   * @method
   * @static
   * @param {Object} payload
   * @param {String} payload.password Current user password.
   * @param {String} payload.newPassword New password to set.
   * @returns {Promise}
   */
  updatePassword: payload =>
    ApiClient.put({
      url: '/password',
      data: payload
    }),
  deleteAccount: () =>
    ApiClient.delete({
      url: '/user'
    }),
  getSubscriptionPlans: () =>
    ApiClient.get({
      url: '/payment/subscription/plan'
    }),
  /**
   * Returns information about subscription plan. If `isCompany` is set to
   * `true`, i.e. vendor provided both company name and tax number, send request
   * with tax number. Otherwise, use generated string to invalidate the query
   * for tax number.
   * @name getSubscriptionPlanById
   * @method
   * @static
   * @param
   * @returns {Promise<Object>}
   */
  getSubscriptionPlanById: ({ planId, country, isCompany, taxNumber, coupon }) => {
    const _taxNumber = isCompany && taxNumber ? taxNumber : ''
    let url = country
      ? `/payment/subscription/plan/${planId}/${country}/${_taxNumber}`
      : `/payment/subscription/plan/${planId}`

    if (coupon) {
      url += `?coupon=${coupon}`
    }

    return ApiClient.get({
      url
    })
  },
  updateSubscriptionPlan: ({planId, coupon}) => {
    const url = coupon
      ? `/payment/subscription/${planId}?coupon=${coupon}`
      : `/payment/subscription/${planId}`

    return ApiClient.put({url})
  },
  cancelSubscriptionPlan: () =>
    ApiClient.delete({
      url: '/payment/subscription'
    }),
  updatePaymentMethod: paymentMethodId =>
    ApiClient.put({
      url: `/payment/method/${paymentMethodId}`
    }),
  deletePaymentMethod: () =>
    ApiClient.delete({
      url: '/payment/method'
    }),
  createSetupIntent: () =>
    ApiClient.get({
      url: '/payment/setupintent'
    }),
  generateStripeNonce: () =>
    ApiClient.post({
      url: '/user/csrf'
    }),
  finalizeStripeConnectTransaction: payload =>
    ApiClient.post({
      url: '/user/stripe/account/connection',
      data: payload
    }),
  generateStripeLoginLink: () =>
    ApiClient.get({
      url: '/user/stripe/account/login'
    }),
  createVendor: (payload, config = {}) =>
    ApiClient.post({
      url: '/user',
      data: payload,
      config
    }),
  confirmEmail: payload =>
    ApiClient.post({
      url: '/user/email/confirm',
      data: payload
    }),
  emailExists: email =>
    ApiClient.get({
      url: `/user/email/check/${email}`
    }),
  downloadVendorInvoice: data =>
    ApiClient.get({
      url: `/invoice/?${qs.stringify(data)}`,
      config: {
        responseType: 'blob'
      }
    }),
  getVendorInvoices: params => {
    const defaultConfig = {
      limit: INVOICES_PER_PAGE,
      page: 0
    }
    const { limit, page } = {
      ...defaultConfig,
      ...params
    }

    return ApiClient.get({
      url: `/invoice/${limit}/${page}`
    })
  }
}

export default UserService
