import { clamp } from 'ramda'

/**
 * @module constants/validation
 * @description Set of constants used mainly for form validation.
 */

export const DYNAMIC_VALIDATION_PATTERN = {
  UNICODE: count => new RegExp(`^.{0,${count}}$`, 'u'),
  SIMPLE_NAME: count => new RegExp(`^.{0,${count}}$`),
  NAME: count => {
    return new RegExp(
      `^((?![1-9^!@#$*~ <>?]).)((?![1-9^!@#$*~<>?]).){0,${count}}((?![1-9^!@#$*~ <>?]))$`,
      'u'
    )
  },
  MULTILINE_TEXT: count => {
    return new RegExp(`^[^\r\n][\\s\\S]{0,${clamp(1, 100000, count - 1)}}$`)
  },
  MULTILINE_TEXT_EMAIL: count => {
    return new RegExp(`^[\\s\\S]{0,${clamp(1, 100000, count - 1)}}$`)
  },
  VENDOR_PHONE: count => {
    return new RegExp(`^\\+[1-9]{1}[0-9]{${count},14}$`)
  },
  EMPTY_HTML: (value) => {
    return /<[^>]+>/g.test(value);
  }
}

/**
 * An enum that holds validation patterns used with `pattern` method of
 * react-hook-forms` validation.
 * @constant
 * @name VALIDATION_PATTERN
 */
export const VALIDATION_PATTERN = {
  // eslint-disable-next-line no-control-regex
  EMAIL:
    /^[\s]*(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])[\s]*$/i,
  URL: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/u,
  URL_SECONDARY:
    /^\s*(?:http(s)?:\/\/)?[\S.-]+(?:\.[\S.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+\s*$/,
  PRICE: /^(?!^0\.00$)(([1-9][\d]{0,6})|([0]))\.[\d]{2}$/,
  NAME: DYNAMIC_VALIDATION_PATTERN.NAME(80),
  PHONE: /^[+]*[(]{0,1}[0-9]{0,4}[)]{0,1}[-\s./0-9]{0,20}$/,
  COMPANY_NAME: DYNAMIC_VALIDATION_PATTERN.NAME(73),
  HOST: /^[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
  ATTACHMENT_IMAGE_VIDEO_URL: /^$|^(https?:\/\/.+)$/,
  ATTACHMENT_URL:
    /^\s*(?:http(s)?:\/\/)[\S.-]+(?:\.[\S.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+\s*$/,
  ICON_URL:
    /^\s*(?:http(s)?:\/\/)[\S.-]+(?:\.[\S.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+\s*$/,
  PAGE_TITLE: DYNAMIC_VALIDATION_PATTERN.NAME(60),
  LANGUAGE_CODE:
    /^(AF|AL|DZ|AS|AD|AO|AI|AQ|AG|AR|AM|AW|AU|AT|AZ|BS|BH|BD|BB|BY|BE|BZ|BJ|BM|BT|BO|BQ|BA|BW|BV|BR|IO|BN|BG|BF|BI|CV|KH|CM|CA|KY|CF|TD|CL|CN|CX|CC|CO|KM|CD|CG|CK|CR|HR|CU|CW|CY|CZ|CI|DK|DJ|DM|DO|EC|EG|SV|GQ|ER|EE|SZ|ET|FK|FO|FJ|FI|FR|GF|PF|TF|GA|GM|GE|DE|GH|GI|GR|GL|GD|GP|GU|GT|GG|GN|GW|GY|HT|HM|VA|HN|HK|HU|IS|IN|ID|IR|IQ|IE|IM|IL|IT|JM|JP|JE|JO|KZ|KE|KI|KP|KR|KW|KG|LA|LV|LB|LS|LR|LY|LI|LT|LU|MO|MG|MW|MY|MV|ML|MT|MH|MQ|MR|MU|YT|MX|FM|MD|MC|MN|ME|MS|MA|MZ|MM|NA|NR|NP|NL|NC|NZ|NI|NE|NG|NU|NF|MP|NO|OM|PK|PW|PS|PA|PG|PY|PE|PH|PN|PL|PT|PR|QA|MK|RO|RU|RW|RE|BL|SH|KN|LC|MF|PM|VC|WS|SM|ST|SA|SN|RS|SC|SL|SG|SX|SK|SI|SB|SO|ZA|GS|SS|ES|LK|SD|SR|SJ|SE|CH|SY|TW|TJ|TZ|TH|TL|TG|TK|TO|TT|TN|TR|TM|TC|TV|UG|UA|AE|GB|UM|US|UY|UZ|VU|VE|VN|VG|VI|WF|EH|YE|ZM|ZW|AX)$/,
  SCORE_POINTS: /^([0-9][0-9]{0,2}|1000)$/,
  SCORE_RANGE_POINTS: /^([0-9]{0,6}|1000000)$/,
  LICENSE_KEY_SETTINGS_URL_LENGTH: /^.{4,400}$/
}

export const maxLengthPattern = (t, max) => ({
  value: new RegExp(`^.{0,${max}}$`, 's'),
  message: t('validation.tooLong', {
    count: max
  })
})
