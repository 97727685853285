import qs from 'qs'
import { prop, pipe, has } from 'ramda'

import { PAYMENT_INTERVAL } from 'constants/payments'

import { USER_CURRENCY } from 'constants/quiz'

/*
 * maximum amount for the function is 99.999.999.99
 * for now quiz is blocked:
 * API less and equal to 10000
 * mongoDB by INT32 -> less and equal to 9999999999
 */
export const formatPrice = (price = 0, currency = 'eur') => {
  const currencySymbol =
    currency.toLowerCase() === 'eur'
      ? '€' // if EUR
      : currency.toLowerCase() === 'usd'
      ? '$' // if USD
      : '€' // if user don't have currency (old european users)
  const _price = (price / 100).toFixed(2)
  let finalPrice = `${currencySymbol} ${_price}`

  if (_price.length === 7) {
    // price more and equal to 1000 but less and equal to 9.999
    finalPrice = `${currencySymbol} ${[
      _price.slice(0, 1),
      '.',
      _price.slice(1)
    ].join('')}`
  }
  if (_price.length === 8) {
    // price more and equal to 10000 but less and equal to 99.999
    finalPrice = `${currencySymbol} ${[
      _price.slice(0, 2),
      '.',
      _price.slice(2)
    ].join('')}`
  }
  if (_price.length === 9) {
    // price more and equal to 100000 but less and equal to 999.999
    finalPrice = `${currencySymbol} ${[
      _price.slice(0, 3),
      '.',
      _price.slice(3)
    ].join('')}`
  }
  if (_price.length === 10) {
    // price more and equal to 1000000 but less and equal to 9.999.999
    finalPrice = `${currencySymbol} ${[
      _price.slice(0, 1),
      '.',
      _price.slice(1, 4),
      '.',
      _price.slice(4)
    ].join('')}`
  }
  if (_price.length === 11) {
    // price more and equal to 10000000 but less and equal to 99.999.999
    finalPrice = `${currencySymbol} ${[
      _price.slice(0, 2),
      '.',
      _price.slice(2, 5),
      '.',
      _price.slice(5)
    ].join('')}`
  }

  return finalPrice
}

/**
 * Changes intiger to amount with currency.
 */
export const toCurrency = (totalPrice, currency = USER_CURRENCY.EUR) =>
  `${parseFloat(totalPrice).toFixed(2)} ${currency}`

/**
 * These two methods are used for obfuscating the paymentIntent.
 */
export const flattenIntent = intent => {
  const [, i1, , i2] = intent.split('_')

  return [i2, i1].join('@')
}

export const expandIntent = (truncatedIntent = '') => {
  const [i2, i1] = truncatedIntent.split('@')

  return ['pi', i1, 'secret', i2].join('_')
}

export const getIntentFromQueryString = pipe(
  s => qs.parse(s, { ignoreQueryPrefix: true }),
  prop('i'),
  expandIntent
)

export const getPricePerPeriod =
  t =>
  ({ currency, netPrice, paymentInterval }) => {
    return `${formatPrice(netPrice, currency)} / ${
      PAYMENT_INTERVAL(t)[paymentInterval]
    }`
  }

export const getGrossPricePerPeriod =
  t =>
  ({ totalPrice, paymentInterval }) => {
    return `${formatPrice(totalPrice)} / ${
      PAYMENT_INTERVAL(t)[paymentInterval]
    }`
  }

export const prepopulateDataForConnect = vendor =>
  qs.stringify(
    {
      stripe_user: {
        email: vendor?.user.email,
        url: vendor?.website,
        country: vendor?.address?.country,
        phone_number: vendor?.phone,
        business_name: vendor?.company?.name,
        first_name: vendor?.firstName,
        last_name: vendor?.lastName,
        street_address: vendor?.address?.line1,
        city: vendor?.address?.city,
        state: vendor?.address?.state,
        zip: vendor?.address?.postalCode
      }
    },
    { encode: true }
  )

export const handleStripeConnectStatus = (location, onSuccess, onError) => {
  const { connectedToStripe } = qs.parse(location.search, {
    ignoreQueryPrefix: true
  })

  switch (connectedToStripe) {
    case 'true':
      return onSuccess(connectedToStripe)
    case 'false':
      return onError(connectedToStripe)
    default:
      return null
  }
}

/**
 * Used for invalidating tax number query. UTNC stands for "Unknown Tax Number
 * Code".
 * @constant
 * @name buildUnknownTaxNumber
 */
export const buildUnknownTaxNumber = () =>
  `UTNC_${Math.round(Math.random().toFixed(3) * 100)}`

export const buildCacheKey = ({ country, isCompany, taxNumber }) =>
  `${country}-${isCompany}-${taxNumber}`

export const isPlanCached = (country, isCompany, taxNumber) => queries => {
  return has(buildCacheKey({ country, isCompany, taxNumber }))(queries)
}

export const numberToCurrency =
  (currency = '€') =>
  value =>
    `${currency} ${parseFloat(value).toFixed(2)}`

export const toEuros = numberToCurrency()
