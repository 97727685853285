import sessionStorage from 'redux-persist/lib/storage/session'

import LanguageService from 'services/LanguageService'

import { LINKS, VENDOR_THANK_YOU_LINKS, PRICING_LINK } from 'constants/legal'
import { TUTORIAL_LINK } from 'constants/app'
import { isEmbeddedExternally } from './dom'
import { AsyncEmbeddedStorage } from 'core/EmbeddedStorage'

export const isDebug = () => import.meta.env.NODE_ENV === 'development'

/**
 * Uses a `Common` namespace.
 */
export const getLegalLinksByLocale = ({ role }) => {
  const currentLanguage = LanguageService.getCurrentLocale()

  return LINKS[role][currentLanguage]
}

export const getVendorThankYouLinkByLocale = () => {
  const currentLanguage = LanguageService.getCurrentLocale()
  return VENDOR_THANK_YOU_LINKS[currentLanguage]
}

export const getPricingLinkByLocale = () => {
  const currentLanguage = LanguageService.getCurrentLocale()

  return PRICING_LINK[currentLanguage]
}

export const getTutorialLinkByLocale = () => {
  const currentLanguage = LanguageService.getCurrentLocale()

  return TUTORIAL_LINK[currentLanguage]
}

/**
 * TODO: Refactor rest of the functions to the generic solution.
 * @author Rafał Wyszomirski Thu, 24 Sep 2020 11:09:25 +0200
 */
export const getLinkByLocale = linkObj => {
  const currentLanguage = LanguageService.getCurrentLocale()

  return linkObj[currentLanguage] || linkObj['en']
}

/**
 * Return storage interface for redux-persist, depending on the `window` context
 * (whether app is embedded in an iframe or not).
 * Added: Thu, 25 Mar 2021 17:04:24 +0100
 * @name getSessionStorage
 * @author Rafał Wyszomirski <ralf@desmart.com>
 * @method
 * @static
 * @returns {Object} storage - an object with `getItem`, `setItem` and `removeItem` asynchronous methods
 */
export const getSessionStorage = () => {
  return isEmbeddedExternally() ? AsyncEmbeddedStorage : sessionStorage
}
