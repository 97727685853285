import { LANGUAGE_CODES } from 'services/LanguageService'

export const LINKS = {
  VENDOR: {
    [LANGUAGE_CODES.GERMAN]: {
      PRIVACY_POLICY: 'https://frontlead.io/de/v-datenschutzerklaerung',
      TERMS_AND_CONDITIONS: 'https://frontlead.io/de/v-agb'
    },
    [LANGUAGE_CODES.ENGLISH]: {
      PRIVACY_POLICY: 'https://frontlead.io/v-privacy-policy',
      TERMS_AND_CONDITIONS: 'https://frontlead.io/v-terms-and-conditions'
    },
    [LANGUAGE_CODES.SPANISH]: {
      PRIVACY_POLICY: 'https://frontlead.io/v-privacy-policy',
      TERMS_AND_CONDITIONS: 'https://frontlead.io/v-terms-and-conditions'
    },
    [LANGUAGE_CODES.FRENCH]: {
      PRIVACY_POLICY: 'https://frontlead.io/v-privacy-policy',
      TERMS_AND_CONDITIONS: 'https://frontlead.io/v-terms-and-conditions'
    },
    [LANGUAGE_CODES.HINDI]: {
      PRIVACY_POLICY: 'https://frontlead.io/v-privacy-policy',
      TERMS_AND_CONDITIONS: 'https://frontlead.io/v-terms-and-conditions'
    },
    [LANGUAGE_CODES.ITALIAN]: {
      PRIVACY_POLICY: 'https://frontlead.io/v-privacy-policy',
      TERMS_AND_CONDITIONS: 'https://frontlead.io/v-terms-and-conditions'
    },
    [LANGUAGE_CODES.JAPANESE]: {
      PRIVACY_POLICY: 'https://frontlead.io/v-privacy-policy',
      TERMS_AND_CONDITIONS: 'https://frontlead.io/v-terms-and-conditions'
    },
    [LANGUAGE_CODES.DUTCH]: {
      PRIVACY_POLICY: 'https://frontlead.io/v-privacy-policy',
      TERMS_AND_CONDITIONS: 'https://frontlead.io/v-terms-and-conditions'
    },
    [LANGUAGE_CODES.POLISH]: {
      PRIVACY_POLICY: 'https://frontlead.io/v-privacy-policy',
      TERMS_AND_CONDITIONS: 'https://frontlead.io/v-terms-and-conditions'
    },
    [LANGUAGE_CODES.PORTUGUESE]: {
      PRIVACY_POLICY: 'https://frontlead.io/v-privacy-policy',
      TERMS_AND_CONDITIONS: 'https://frontlead.io/v-terms-and-conditions'
    },
    [LANGUAGE_CODES.RUSSIAN]: {
      PRIVACY_POLICY: 'https://frontlead.io/v-privacy-policy',
      TERMS_AND_CONDITIONS: 'https://frontlead.io/v-terms-and-conditions'
    },
    [LANGUAGE_CODES.TURKISH]: {
      PRIVACY_POLICY: 'https://frontlead.io/v-privacy-policy',
      TERMS_AND_CONDITIONS: 'https://frontlead.io/v-terms-and-conditions'
    },
    [LANGUAGE_CODES.CHINESE]: {
      PRIVACY_POLICY: 'https://frontlead.io/v-privacy-policy',
      TERMS_AND_CONDITIONS: 'https://frontlead.io/v-terms-and-conditions'
    }
  },
  RESPONDENT: {
    [LANGUAGE_CODES.GERMAN]: {
      PRIVACY_POLICY: 'https://frontlead.io/de/e-datenschutzerklaerung',
      TERMS_AND_CONDITIONS: 'https://frontlead.io/de/e-nutzungsbedingungen'
    },
    [LANGUAGE_CODES.ENGLISH]: {
      PRIVACY_POLICY: 'https://frontlead.io/e-privacy-policy',
      TERMS_AND_CONDITIONS: 'https://frontlead.io/e-terms-of-use'
    },
    [LANGUAGE_CODES.SPANISH]: {
      PRIVACY_POLICY: 'https://frontlead.io/e-privacy-policy',
      TERMS_AND_CONDITIONS: 'https://frontlead.io/e-terms-of-use'
    },
    [LANGUAGE_CODES.FRENCH]: {
      PRIVACY_POLICY: 'https://frontlead.io/e-privacy-policy',
      TERMS_AND_CONDITIONS: 'https://frontlead.io/e-terms-of-use'
    },
    [LANGUAGE_CODES.HINDI]: {
      PRIVACY_POLICY: 'https://frontlead.io/e-privacy-policy',
      TERMS_AND_CONDITIONS: 'https://frontlead.io/e-terms-of-use'
    },
    [LANGUAGE_CODES.ITALIAN]: {
      PRIVACY_POLICY: 'https://frontlead.io/e-privacy-policy',
      TERMS_AND_CONDITIONS: 'https://frontlead.io/e-terms-of-use'
    },
    [LANGUAGE_CODES.JAPANESE]: {
      PRIVACY_POLICY: 'https://frontlead.io/e-privacy-policy',
      TERMS_AND_CONDITIONS: 'https://frontlead.io/e-terms-of-use'
    },
    [LANGUAGE_CODES.DUTCH]: {
      PRIVACY_POLICY: 'https://frontlead.io/e-privacy-policy',
      TERMS_AND_CONDITIONS: 'https://frontlead.io/e-terms-of-use'
    },
    [LANGUAGE_CODES.POLISH]: {
      PRIVACY_POLICY: 'https://frontlead.io/e-privacy-policy',
      TERMS_AND_CONDITIONS: 'https://frontlead.io/e-terms-of-use'
    },
    [LANGUAGE_CODES.PORTUGUESE]: {
      PRIVACY_POLICY: 'https://frontlead.io/e-privacy-policy',
      TERMS_AND_CONDITIONS: 'https://frontlead.io/e-terms-of-use'
    },
    [LANGUAGE_CODES.RUSSIAN]: {
      PRIVACY_POLICY: 'https://frontlead.io/e-privacy-policy',
      TERMS_AND_CONDITIONS: 'https://frontlead.io/e-terms-of-use'
    },
    [LANGUAGE_CODES.TURKISH]: {
      PRIVACY_POLICY: 'https://frontlead.io/e-privacy-policy',
      TERMS_AND_CONDITIONS: 'https://frontlead.io/e-terms-of-use'
    },
    [LANGUAGE_CODES.CHINESE]: {
      PRIVACY_POLICY: 'https://frontlead.io/e-privacy-policy',
      TERMS_AND_CONDITIONS: 'https://frontlead.io/e-terms-of-use'
    }
  }
}

/**
 * TODO: Refactor these constants with use of some factory for multilingual
 * links.
 * Added: Sat, 24 Oct 2020 11:16:31 +0200
 */

// After Vendor buy subscription forward to this thank you pages
// (Only for subscirption not for end user payment form.)
export const VENDOR_THANK_YOU_LINKS = {
  [LANGUAGE_CODES.GERMAN]: {
    LIGHT: 'https://frontlead.io/de/danke-light/',
    PREMIUM: 'https://frontlead.io/de/danke-premium/',
    PLATINUM: 'https://frontlead.io/de/danke-platinum/',
    PLAN40: 'https://frontlead.io/de/danke-light/'
  },
  [LANGUAGE_CODES.ENGLISH]: {
    LIGHT: 'https://frontlead.io/thank-you-light/',
    PREMIUM: 'https://frontlead.io/thank-you-premium/',
    PLATINUM: 'https://frontlead.io/thank-you-platinum/',
    PLAN40: 'https://frontlead.io/thank-you-light/'
  }
}

export const PRICING_LINK = {
  [LANGUAGE_CODES.GERMAN]: 'https://frontlead.io/de/preise/',
  [LANGUAGE_CODES.ENGLISH]: 'https://frontlead.io/pricing/',
  [LANGUAGE_CODES.SPANISH]: 'https://frontlead.io/es/precios/',
  [LANGUAGE_CODES.FRENCH]: 'https://frontlead.io/fr/tarifs/',
  [LANGUAGE_CODES.HINDI]: 'https://frontlead.io/pricing/',
  [LANGUAGE_CODES.ITALIAN]: 'https://frontlead.io/pricing/',
  [LANGUAGE_CODES.JAPANESE]: 'https://frontlead.io/pricing/',
  [LANGUAGE_CODES.DUTCH]: 'https://frontlead.io/pricing/',
  [LANGUAGE_CODES.POLISH]: 'https://frontlead.io/pricing/',
  [LANGUAGE_CODES.PORTUGUESE]: 'https://frontlead.io/pt/precos/',
  [LANGUAGE_CODES.RUSSIAN]: 'https://frontlead.io/pricing/',
  [LANGUAGE_CODES.TURKISH]: 'https://frontlead.io/pricing/',
  [LANGUAGE_CODES.CHINESE]: 'https://frontlead.io/pricing/'
}

export const TRIAL_LINK = {
  [LANGUAGE_CODES.GERMAN]: 'https://frontlead.io/de/confirm-free-trial/',
  [LANGUAGE_CODES.ENGLISH]: 'https://frontlead.io/confirm-free-trial/',
  [LANGUAGE_CODES.SPANISH]: 'https://frontlead.io/confirm-free-trial/',
  [LANGUAGE_CODES.FRENCH]: 'https://frontlead.io/confirm-free-trial/',
  [LANGUAGE_CODES.HINDI]: 'https://frontlead.io/confirm-free-trial/',
  [LANGUAGE_CODES.ITALIAN]: 'https://frontlead.io/confirm-free-trial/',
  [LANGUAGE_CODES.JAPANESE]: 'https://frontlead.io/confirm-free-trial/',
  [LANGUAGE_CODES.DUTCH]: 'https://frontlead.io/confirm-free-trial/',
  [LANGUAGE_CODES.POLISH]: 'https://frontlead.io/confirm-free-trial/',
  [LANGUAGE_CODES.PORTUGUESE]: 'https://frontlead.io/confirm-free-trial/',
  [LANGUAGE_CODES.RUSSIAN]: 'https://frontlead.io/confirm-free-trial/',
  [LANGUAGE_CODES.TURKISH]: 'https://frontlead.io/confirm-free-trial/',
  [LANGUAGE_CODES.CHINESE]: 'https://frontlead.io/confirm-free-trial/'
}

export const TRIAL_CONFIRMED_LINK = {
  [LANGUAGE_CODES.GERMAN]: 'https://frontlead.io/de/free-trial-confirmed/',
  [LANGUAGE_CODES.ENGLISH]: 'https://frontlead.io/free-trial-confirmed/',
  [LANGUAGE_CODES.SPANISH]: 'https://frontlead.io/free-trial-confirmed/',
  [LANGUAGE_CODES.FRENCH]: 'https://frontlead.io/free-trial-confirmed/',
  [LANGUAGE_CODES.HINDI]: 'https://frontlead.io/free-trial-confirmed/',
  [LANGUAGE_CODES.ITALIAN]: 'https://frontlead.io/free-trial-confirmed/',
  [LANGUAGE_CODES.JAPANESE]: 'https://frontlead.io/free-trial-confirmed/',
  [LANGUAGE_CODES.DUTCH]: 'https://frontlead.io/free-trial-confirmed/',
  [LANGUAGE_CODES.POLISH]: 'https://frontlead.io/free-trial-confirmed/',
  [LANGUAGE_CODES.PORTUGUESE]: 'https://frontlead.io/free-trial-confirmed/',
  [LANGUAGE_CODES.RUSSIAN]: 'https://frontlead.io/free-trial-confirmed/',
  [LANGUAGE_CODES.TURKISH]: 'https://frontlead.io/free-trial-confirmed/',
  [LANGUAGE_CODES.CHINESE]: 'https://frontlead.io/free-trial-confirmed/'
}

/**
 * TODO: Write an utility to handle development vs. production URLs.
 * Added: Sat, 24 Oct 2020 11:17:00 +0200
 */
export const REGISTER_LINK = {
  [LANGUAGE_CODES.GERMAN]: '/register/price_1H1tcCLHoonfRvvVCxxW7CuG/form?lang=de',
  [LANGUAGE_CODES.ENGLISH]: '/register/price_1H1tcCLHoonfRvvVCxxW7CuG/form?lang=en',
  [LANGUAGE_CODES.SPANISH]: '/register/price_1H1tcCLHoonfRvvVCxxW7CuG/form?lang=es',
  [LANGUAGE_CODES.FRENCH]: '/register/price_1H1tcCLHoonfRvvVCxxW7CuG/form?lang=fr',
  [LANGUAGE_CODES.HINDI]: '/register/price_1H1tcCLHoonfRvvVCxxW7CuG/form?lang=hi',
  [LANGUAGE_CODES.ITALIAN]: '/register/price_1H1tcCLHoonfRvvVCxxW7CuG/form?lang=it',
  [LANGUAGE_CODES.JAPANESE]: '/register/price_1H1tcCLHoonfRvvVCxxW7CuG/form?lang=ja',
  [LANGUAGE_CODES.DUTCH]: '/register/price_1H1tcCLHoonfRvvVCxxW7CuG/form?lang=nl',
  [LANGUAGE_CODES.POLISH]: '/register/price_1H1tcCLHoonfRvvVCxxW7CuG/form?lang=pl',
  [LANGUAGE_CODES.PORTUGUESE]: '/register/price_1H1tcCLHoonfRvvVCxxW7CuG/form?lang=pt',
  [LANGUAGE_CODES.RUSSIAN]: '/register/price_1H1tcCLHoonfRvvVCxxW7CuG/form?lang=ru',
  [LANGUAGE_CODES.TURKISH]: '/register/price_1H1tcCLHoonfRvvVCxxW7CuG/form?lang=tr',
  [LANGUAGE_CODES.CHINESE]: '/register/price_1H1tcCLHoonfRvvVCxxW7CuG/form?lang=zh'
}
