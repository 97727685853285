/**
 * https://stackoverflow.com/a/58254980/2420343
 * Added: Sat, 24 Oct 2020 10:29:40 +0200
 */
export const capitalize = str =>
  str.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase())

export const convertNumberPropertiesToString = obj => {
  return Object.keys(obj).reduce((acc, key) => {
    acc[key] = typeof obj[key] === 'number' ? obj[key].toString() : obj[key]
    return acc
  }, {})
}