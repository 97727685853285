import { propOr } from 'ramda'

function EmbeddedStorageFactory() {
  const data = {}

  return {
    setItem(key, value) {
      data[key] = value
    },
    getItem(key) {
      return propOr(null, key, data)
    },
    removeItem(key) {
      data[key] = null
    },
    getAll() {
      return data
    }
  }
}

function AsyncEmbeddedStorageFactory() {
  const data = {}

  return {
    setItem: (key, value) => {
      return new Promise((resolve, reject) => {
        data[key] = value

        resolve(value)
      })
    },
    getItem: key => {
      return new Promise((resolve, reject) => {
        resolve(data[key])
      })
    },
    removeItem: key => {
      return new Promise((resolve, reject) => {
        data[key] = null
        resolve(true)
      })
    }
  }
}

const EmbeddedStorage = EmbeddedStorageFactory()

export const AsyncEmbeddedStorage = AsyncEmbeddedStorageFactory()

export default EmbeddedStorage
