export const PAYMENT_INTERVAL = t => ({
  month: t('monthly'),
  year: t('yearly')
})

export const WITH_PAYMENT_INTERVAL = t => ({
  month: t('withMonthly'),
  year: t('withYearly')
})

/**
 * Trial length in days.
 */
export const TRIAL_LENGTH = 14

/**
 * The list of countries for which Stripe Connect is enabled. This list can be
 * found and configured here:
 *
 * https://dashboard.stripe.com/settings/applications/express
 *
 * This data is returned in one of the requests that arre triggered during page load:
 * https://dashboard.stripe.com/ajax/connect/onboarding_config/express
 *
 * @constant
 * @name STRIPE_CONNECT_ENABLED_COUNTRIES
 */
export const STRIPE_CONNECT_ENABLED_COUNTRIES = [
  'AT',
  'AU',
  'BE',
  'CA',
  'CH',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB',
  'GR',
  'HK',
  'IE',
  'IT',
  'JP',
  'LT',
  'LU',
  'LV',
  'NL',
  'NO',
  'NZ',
  'PL',
  'PT',
  'SE',
  'SG',
  'SI',
  'SK',
  'US'
]

export const AGREEMENT = {
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS'
}

export const PAYMENT_METHOD = {
  NONE: 'NONE',
  CARD: 'CARD',
  SEPA: 'SEPA'
}
