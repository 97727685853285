/**
 * @module utils/subscription
 * @description Set of tools used handling `subscription` model.
 */
import { formatDate } from 'utils/date'
import { formatPrice } from 'utils/payments'
import { capitalize } from 'utils/string'

/**
 * Used for formatting subscription to use easily in rendering labels.
 * @name formatSubscriptionPlanData
 * @method
 * @static
 * @param
 * @returns {Object}
 */
export const formatSubscriptionPlanData = plan => ({
  name: capitalize(plan?.name),
  end: formatDate(plan?.end),
  start: formatDate(plan?.start),
  totalPrice: formatPrice(plan?.totalPrice),
  netPrice: formatPrice(plan?.netPrice),
  vatRate: plan?.vatRate
})

/**
 * Use different translation depending on the subscription details.
 * Added: Wed, 28 Oct 2020 11:45:52 +0100
 * @name formatSubscriptionNotice
 * @author Rafał Wyszomirski <ralf@desmart.com>
 * @method
 * @param {Object} config
 * @returns {string}
 */
export const formatSubscriptionNotice =
  ({ t, withVatKey, withoutVatKey }) =>
  subscription => {
    const data = formatSubscriptionPlanData(subscription)

    if (subscription?.trial) {
      return t('subscriptionDetailsTrial', data)
    }

    return subscription?.vatRate === 0
      ? t(withoutVatKey, data)
      : t(withVatKey, data)
  }

export const isSubscriptionPlanTrial = subscription => subscription.trial

export const isStripeConnectionAllowed = subscription => subscription.allowPaidQuizzes

export const isRespondentLimitExhausted = subscription =>
  subscription.respondentsPerMonth <= subscription.respondentsUsedInMonth
