/**
 * @module epics/quiz
 * @description Business logic with managing quizzes.
 */
import { combineEpics, ofType } from 'redux-observable'
import { of, from, concat, forkJoin } from 'rxjs'
import {
  concatMap,
  catchError,
  filter,
  finalize,
  map,
  mapTo,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators'
import { propOr } from 'ramda'
import { isEmpty } from 'lodash'

import * as types from 'core/redux/types/quiz'
import * as actions from 'core/redux/actions/quiz'
import { requestGetProfile } from 'core/redux/actions/user'

import { getQuizzesListPageLimitByUserProfile } from 'utils/quiz'

import QuizService from 'services/QuizService'
import EmailService from 'services/EmailService'
import LanguageService from 'services/LanguageService'
import UserService from 'services/UserService'
import { logger } from 'utils/logger'

const l = logger({ name: 'EPIC::QUIZ' })

/**
 * Used for triggering get user profile data request when something happens with
 * quizzes.
 * @constant
 * @name modifiedTypes
 */
const modifiedTypes = [
  types.CREATE_QUIZ_SUCCESS,
  types.DELETE_QUIZ_SUCCESS,
  types.DUPLICATE_QUIZ_SUCCESS
]

const requestCreateQuiz = action$ =>
  action$.pipe(
    ofType(types.REQUEST_CREATE_QUIZ),
    mergeMap(action =>
      from(QuizService.createQuiz(action.data)).pipe(
        mergeMap(response =>
          of(actions.createQuizSuccess(response.data)).pipe(
            finalize(() => action?.meta?.onSubmitSuccess(response.data))
          )
        ),
        catchError(err =>
          of(actions.createQuizFailed(err)).pipe(
            finalize(() => action?.meta?.onSubmitFailed(err))
          )
        )
      )
    )
  )

/**
 * Triggered every time the quiz was updated. Basically: refetch the
 * quiz.
 * Added: Mon, 09 Nov 2020 09:46:42 +0100
 */
const updateQuiz$ = action$ =>
  action$.pipe(
    filter(action => action.type === types.UPDATE_QUIZ_SUCCESS),
    map(action => actions.requestGetQuizById(action?.data?._id))
  )

const quizzesModified$ = action$ =>
  action$.pipe(
    filter(action => modifiedTypes.includes(action.type)),
    mapTo(requestGetProfile())
  )

const requestUpdateOptInStep = action$ =>
  action$.pipe(
    ofType(types.REQUEST_UPDATE_OPT_IN_STEP),
    concatMap(action =>
      from(
        QuizService.bulkCreateInformationToGather(
          action.id,
          action.data.informationToGather,
          action.data.settings
        )
      ).pipe(
        concatMap(_ =>
          from(
            QuizService.updateQuiz(action.id, {
              optIn: action.data.optIn,
              popUp: action.data.popUp
            })
          )
        ),
        concatMap(response =>
          of(actions.updateOptInStepSuccess(response.data)).pipe(
            finalize(() => action?.meta?.onSuccess(response.data))
          )
        ),
        catchError(err =>
          of(actions.updateOptInStepFailed(err)).pipe(
            finalize(() => action?.meta?.onError(err))
          )
        )
      )
    ),
    catchError(err => of({ type: 'CRITICAL_UPDATE_OPT_IN_ERROR', error: err }))
  )

const requestUpdateOptInInformationToGather = action$ =>
  action$.pipe(
    ofType(types.REQUEST_UPDATE_OPT_IN_INFORMATION_TO_GATHER),
    mergeMap(action =>
      from(
        QuizService.bulkCreateInformationToGather(
          action.id,
          action.data.informationToGather
        )
      ).pipe(
        mergeMap(response =>
          from(QuizService.getQuizById(action.id)).pipe(
            mergeMap(quizResponse =>
              of(
                actions.updateOptInInformationToGatherSuccess(quizResponse.data)
              ).pipe(finalize(() => action?.meta?.onSuccess(response.data)))
            ),
            catchError(err =>
              of(actions.updateOptInInformationToGatherFailed(err)).pipe(
                finalize(() => action?.meta?.onError(err))
              )
            )
          )
        )
      )
    )
  )

const requestUpdateQuiz = action$ =>
  action$.pipe(
    ofType(types.REQUEST_UPDATE_QUIZ),
    mergeMap(action =>
      from(QuizService.updateQuiz(action.id, action.data)).pipe(
        mergeMap(response =>
          of(actions.updateQuizSuccess(response.data)).pipe(
            finalize(() => action?.meta?.onSubmitSuccess(response.data))
          )
        ),
        catchError(err =>
          of(actions.updateQuizFailed(err)).pipe(
            finalize(() => action?.meta?.onSubmitFailed(err))
          )
        )
      )
    )
  )

const requestDeleteQuiz = action$ =>
  action$.pipe(
    ofType(types.REQUEST_DELETE_QUIZ),
    mergeMap(action =>
      from(QuizService.deleteQuiz(action.id)).pipe(
        mergeMap(_ =>
          of(actions.deleteQuizSuccess(action.id)).pipe(
            finalize(() => action?.meta?.onSuccess())
          )
        ),
        catchError(err =>
          of(actions.deleteQuizFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

const requestDeleteSingleEmailScore = action$ =>
  action$.pipe(
    ofType(types.REQUEST_DELETE_SINGLE_EMAIL_FOR_SCORING),
    mergeMap(action =>
      from(QuizService.deleteSingleEmailScore(action.data.id)).pipe(
        mergeMap(_ =>
          from(QuizService.getQuizById(action.data.quizId)).pipe(
            mergeMap(response =>
              of(
                actions.deleteSingleEmailForScoringSuccess(response.data)
              ).pipe(finalize(() => action?.meta?.onSuccess()))
            ),
            catchError(err =>
              of(actions.deleteSingleEmailForScoringFailed(err)).pipe(
                finalize(() => action?.meta?.onError())
              )
            )
          )
        )
      )
    )
  )

const requestDeleteAllEmailScore = action$ =>
  action$.pipe(
    ofType(types.REQUEST_DELETE_ALL_EMAIL_FOR_SCORING),
    mergeMap(action =>
      from(QuizService.deleteAllEmailScore(action.data)).pipe(
        mergeMap(_ =>
          from(QuizService.getQuizById(action.data.quizId)).pipe(
            mergeMap(response =>
              of(actions.deleteAllEmailForScoringSuccess(response.data)).pipe(
                finalize(() => action?.meta?.onSuccess())
              )
            ),
            catchError(err =>
              of(actions.deleteAllEmailForScoringFailed(err)).pipe(
                finalize(() => action?.meta?.onError())
              )
            )
          )
        )
      )
    )
  )

const requestDuplicateQuiz = (action$, state$) =>
  action$.pipe(
    ofType(types.REQUEST_DUPLICATE_QUIZ),
    mergeMap(action =>
      from(QuizService.duplicateQuiz(action.id)).pipe(
        mergeMap(response =>
          from(QuizService.updateQuiz(response.data._id, action.data)).pipe(
            mergeMap(_ =>
              concat(
                of(
                  actions.requestGetQuizzes({
                    limit: getQuizzesListPageLimitByUserProfile(
                      state$.value.user.profile
                    ),
                    page: 0
                  })
                ),
                of(actions.duplicateQuizSuccess(action.id)).pipe(
                  finalize(() => action?.meta?.onSuccess())
                )
              )
            )
          )
        ),
        catchError(err =>
          of(actions.duplicateQuizFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

const requestGetQuizById = action$ =>
  action$.pipe(
    ofType(types.REQUEST_GET_QUIZ_BY_ID),
    mergeMap(action =>
      from(QuizService.getQuizById(action.id)).pipe(
        mergeMap(response =>
          of(actions.getQuizByIdSuccess(response.data)).pipe(
            finalize(() => action?.meta?.onSuccess())
          )
        ),
        catchError(err =>
          of(actions.getQuizByIdFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

const requestGetQuizzes = action$ =>
  action$.pipe(
    ofType(types.REQUEST_GET_QUIZZES),
    mergeMap(action =>
      from(QuizService.getQuizzes(action.data)).pipe(
        mergeMap(response =>
          of(actions.getQuizzesSuccess(response.data)).pipe(
            finalize(() => action?.meta?.onSuccess(response.data))
          )
        ),
        catchError(err =>
          of(actions.getQuizzesFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

const requestGetTemplates = action$ =>
  action$.pipe(
    ofType(types.REQUEST_GET_TEMPLATES),
    mergeMap(action =>
      from(QuizService.getTemplates(action.data)).pipe(
        mergeMap(response =>
          of(actions.getTemplatesSuccess(response.data)).pipe(
            finalize(() => action?.meta?.onSuccess(response.data))
          )
        ),
        catchError(err =>
          of(actions.getTemplatesFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

const requestCreateQuestion = action$ =>
  action$.pipe(
    ofType(types.REQUEST_CREATE_QUESTION),
    switchMap(action =>
      from(QuizService.createQuestion(action.data)).pipe(
        mergeMap(questionResponse =>
          from(QuizService.getQuizById(action.data.quiz)).pipe(
            mergeMap(response =>
              of(actions.createQuestionSuccess(response.data)).pipe(
                finalize(() => action?.meta?.onSuccess(questionResponse.data))
              )
            )
          )
        ),
        catchError(err => of(actions.createQuestionFailed(err)))
      )
    )
  )

const requestCreateScoreEmailReply = action$ =>
  action$.pipe(
    ofType(types.REQUEST_CREATE_SCORE_EMAIL_REPLY),
    mergeMap(action =>
      from(QuizService.createScoreEmailReply(action.data)).pipe(
        mergeMap(emailResponse =>
          from(QuizService.getQuizById(action.data.quiz)).pipe(
            mergeMap(response =>
              of(actions.createQuestionSuccess(response.data)).pipe(
                finalize(() => action?.meta?.onSuccess(emailResponse.data))
              )
            )
          )
        ),
        catchError(err => of(actions.createQuestionFailed(err)))
      )
    )
  )

const generateGroupedQuestionAnswerPayload = (question, answer) => ({
  quiz: question.quiz,
  question: question._id,
  content: answer,
  emailAnalysis: false,
  emailContent: '<p></p>'
})

const requestCreateDefaultAnswersForGroupedQuestion = action$ =>
  action$.pipe(
    ofType(types.REQUEST_CREATE_DEFAULT_ANSWERS_FOR_GROUPED_QUESTION),
    mergeMap(action =>
      forkJoin(
        QuizService.createAnswer(
          generateGroupedQuestionAnswerPayload(action.data.question, 'yes')
        ),
        QuizService.createAnswer(
          generateGroupedQuestionAnswerPayload(action.data.question, 'no')
        )
      ).pipe(
        mergeMap(_ =>
          from(QuizService.getQuizById(action.data.question.quiz)).pipe(
            mergeMap(response =>
              of(
                actions.createDefaultAnswersForGroupedQuestionSuccess(
                  response.data
                )
              )
            )
          )
        ),
        catchError(err =>
          of(actions.createDefaultAnswersForGroupedQuestionFailed(err))
        )
      )
    )
  )

const requestCreateGroupedQuestionWithSettings = action$ =>
  action$.pipe(
    ofType(types.REQUEST_CREATE_GROUPED_QUESTION_WITH_SETTINGS),
    mergeMap(action =>
      from(
        QuizService.createQuestionGroupSetting(action.data.groupSettingsPayload)
      ).pipe(
        mergeMap(questionGroupSettingResponse =>
          from(
            QuizService.updateQuestion({
              id: action.data.id,
              payload: {
                ...action.data.payload,
                required: true,
                isMain: true,
                gid: questionGroupSettingResponse.data._id
              }
            })
          ).pipe(
            mergeMap(questionResponse =>
              from(
                QuizService.createAnswer(
                  generateGroupedQuestionAnswerPayload(
                    questionResponse.data,
                    'yes'
                  )
                )
              ).pipe(
                mergeMap(_ =>
                  from(
                    QuizService.createAnswer(
                      generateGroupedQuestionAnswerPayload(
                        questionResponse.data,
                        'no'
                      )
                    )
                  ).pipe(
                    mergeMap(_ =>
                      from(
                        QuizService.getQuizById(questionResponse.data.quiz)
                      ).pipe(
                        mergeMap(response =>
                          of(
                            actions.createGroupedQuestionWithSettingsSuccess(
                              response.data
                            )
                          ).pipe(finalize(() => action?.meta?.onSuccess()))
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        ),
        catchError(err =>
          of(actions.createGroupedQuestionWithSettingsFailed(err))
        )
      )
    )
  )

const requestCreateGroupedQuestion = action$ =>
  action$.pipe(
    ofType(types.REQUEST_CREATE_GROUPED_QUESTION),
    mergeMap(action =>
      from(QuizService.createQuestion(action.data)).pipe(
        mergeMap(questionResponse =>
          from(QuizService.getQuizById(questionResponse.data.quiz)).pipe(
            mergeMap(response =>
              of(actions.createGroupedQuestionSuccess(response.data)).pipe(
                finalize(() =>
                  action?.meta?.onSuccess({
                    question: questionResponse.data
                  })
                )
              )
            )
          )
        ),
        catchError(err => of(actions.createGroupedQuestionFailed(err)))
      )
    )
  )

/**
 * TODO: Refactor this epic.
 * Added: Mon, 12 Oct 2020 12:28:35 +0200
 */
const requestUpdateQuestionGroupSettings = action$ =>
  action$.pipe(
    ofType(types.REQUEST_UPDATE_GROUPED_QUESTION_SETTINGS),
    mergeMap(action =>
      from(QuizService.updateQuestionGroupSetting(action.data.payload)).pipe(
        mergeMap(_ =>
          from(QuizService.getQuizById(action.data.quizId)).pipe(
            mergeMap(response =>
              of(
                actions.updateGroupedQuestionSettingsSuccess(response.data)
              ).pipe(finalize(() => action?.meta?.onSuccess()))
            ),
            catchError(err =>
              of(actions.updateGroupedQuestionSettingsFailed(err))
            )
          )
        ),
        catchError(err => of(actions.updateGroupedQuestionSettingsFailed(err)))
      )
    )
  )

const requestDeleteQuestion = action$ =>
  action$.pipe(
    ofType(types.REQUEST_DELETE_QUESTION),
    mergeMap(action =>
      from(QuizService.deleteQuestion(action.data.id)).pipe(
        mergeMap(_ =>
          from(QuizService.getQuizById(action.data.quizId)).pipe(
            mergeMap(response =>
              of(actions.deleteQuestionSuccess(response.data)).pipe(
                finalize(() => action?.meta?.onSuccess())
              )
            ),
            catchError(err =>
              of(actions.deleteQuestionFailed(err)).pipe(
                finalize(() => action?.meta?.onError())
              )
            )
          )
        )
      )
    )
  )

/**
 * Please note that after successful update on question has been performed, the
 * whole quiz is fetched again. This is by design of the API layer.
 * @method
 * @static
 * @name requestUpdateQuestion
 */
const requestUpdateQuestion = action$ =>
  action$.pipe(
    ofType(types.REQUEST_UPDATE_QUESTION),
    concatMap(action =>
      from(QuizService.updateQuestion(action.data)).pipe(
        mergeMap(response =>
          from(QuizService.getQuizById(response.data.quiz)).pipe(
            mergeMap(response =>
              of(actions.updateQuestionSuccess(response.data)).pipe(
                finalize(() => action?.meta?.onSuccess())
              )
            )
          )
        ),
        catchError(err => of(actions.updateQuestionFailed(err)))
      )
    )
  )

const requestUpdateQuestionWithAnswers = action$ =>
  action$.pipe(
    ofType(types.REQUEST_UPDATE_QUESTION_WITH_ANSWERS),
    concatMap(action => {
      /**
       * Skip bulk answer update if no answers are passed in payload.
       * Added: Mon, 08 Feb 2021 13:16:01 +0100
       */
      const result$ =
        action?.data?.answersData?.payload.length === 0
          ? of('ok')
          : from(QuizService.bulkUpdateAnswer(action.data.answersData.payload))

      return result$.pipe(
        mergeMap(_ =>
          from(QuizService.updateQuestion(action.data.questionData)).pipe(
            mergeMap(response =>
              from(QuizService.getQuizById(response.data.quiz)).pipe(
                mergeMap(response =>
                  of(
                    actions.updateQuestionWithAnswersSuccess(response.data)
                  ).pipe(finalize(() => action?.meta?.onSuccess()))
                ),
                catchError(err =>
                  of(actions.updateQuestionWithAnswersFailed(err))
                )
              )
            )
          )
        )
      )
    })
  )

const requestCreateAnswer = action$ =>
  action$.pipe(
    ofType(types.REQUEST_CREATE_ANSWER),
    switchMap(action =>
      from(QuizService.createAnswer(action.data)).pipe(
        mergeMap(answerResponse =>
          from(QuizService.getQuizById(action.data.quiz)).pipe(
            mergeMap(response =>
              of(actions.createAnswerSuccess(response.data)).pipe(
                finalize(() => action?.meta?.onSuccess(answerResponse.data))
              )
            )
          )
        ),
        catchError(err => of(actions.createAnswerFailed(err)))
      )
    )
  )

const requestDeleteAnswer = action$ =>
  action$.pipe(
    ofType(types.REQUEST_DELETE_ANSWER),
    mergeMap(action =>
      from(QuizService.deleteAnswer(action.data.id)).pipe(
        mergeMap(_ =>
          from(QuizService.getQuizById(action.data.quizId)).pipe(
            mergeMap(response =>
              of(actions.deleteAnswerSuccess(response.data)).pipe(
                finalize(() => action?.meta?.onSuccess())
              )
            ),
            catchError(err =>
              of(actions.deleteAnswerFailed(err)).pipe(
                finalize(() => action?.meta?.onError())
              )
            )
          )
        )
      )
    )
  )

const requestUpdateAnswer = action$ =>
  action$.pipe(
    ofType(types.REQUEST_UPDATE_ANSWER),
    mergeMap(action =>
      from(QuizService.updateAnswer(action.data)).pipe(
        mergeMap(response =>
          from(QuizService.getQuizById(response.data.quiz)).pipe(
            mergeMap(response =>
              of(actions.updateAnswerSuccess(response.data)).pipe(
                finalize(() => action?.meta?.onSuccess())
              )
            )
          )
        ),
        catchError(err =>
          of(actions.updateAnswerFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

const requestSaveAllEmailScoring = action$ =>
  action$.pipe(
    ofType(types.REQUEST_SAVE_ALL_EMAIL_SCORING),
    mergeMap(action =>
      from(QuizService.bulkUpdateEmailScore(action.data)).pipe(
        mergeMap(response =>
          of(actions.saveAllEmailScoringSuccess(response.data)).pipe(
            finalize(() => action?.meta?.onSuccess())
          )
        ),
        catchError(err =>
          of(actions.saveAllEmailScoringFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

const requestUpdateEmailScore = action$ =>
  action$.pipe(
    ofType(types.REQUEST_UPDATE_EMAIL_FOR_SCORING),
    mergeMap(action =>
      from(QuizService.updateEmailScoring(action.data)).pipe(
        mergeMap(response =>
          of(actions.updateEmailForScoringSuccess(response.data)).pipe(
            finalize(() => action?.meta?.onSuccess())
          )
        ),
        catchError(err =>
          of(actions.updateEmailForScoringFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

const requestCreateTitle = action$ =>
  action$.pipe(
    ofType(types.REQUEST_CREATE_TITLE),
    mergeMap(action =>
      from(QuizService.createTitle(action.data)).pipe(
        mergeMap(response =>
          from(QuizService.getQuizById(response.data.quiz)).pipe(
            mergeMap(response =>
              of(actions.createTitleSuccess(response.data)).pipe(
                finalize(() => action.meta.onSuccess())
              )
            )
          )
        ),
        catchError(err => of(actions.createTitleFailed(err)))
      )
    )
  )

const requestUpdateTitle = action$ =>
  action$.pipe(
    ofType(types.REQUEST_UPDATE_TITLE),
    mergeMap(action =>
      from(QuizService.updateTitle(action.data)).pipe(
        mergeMap(response =>
          from(QuizService.getQuizById(response.data.quiz)).pipe(
            mergeMap(response =>
              of(actions.updateTitleSuccess(response.data)).pipe(
                finalize(() => action?.meta?.onSuccess())
              )
            )
          )
        ),
        catchError(err =>
          of(actions.updateTitleFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

const requestDeleteTitle = action$ =>
  action$.pipe(
    ofType(types.REQUEST_DELETE_TITLE),
    mergeMap(action =>
      from(QuizService.deleteTitle(action.data.id)).pipe(
        mergeMap(_ =>
          from(QuizService.getQuizById(action.data.quizId)).pipe(
            mergeMap(response =>
              of(actions.deleteTitleSuccess(response.data)).pipe(
                finalize(() => action?.meta?.onSuccess())
              )
            ),
            catchError(err =>
              of(actions.deleteTitleFailed(err)).pipe(
                finalize(() => action?.meta?.onError())
              )
            )
          )
        )
      )
    )
  )

const requestBulkQuizUpdate = action$ =>
  action$.pipe(
    ofType(types.REQUEST_BULK_QUIZ_UPDATE),
    mergeMap(action =>
      from(QuizService.bulkQuizUpdate(action.data.payload)).pipe(
        mergeMap(_ =>
          from(QuizService.getQuizById(action.data.quizId)).pipe(
            mergeMap(response =>
              of(actions.bulkQuizUpdateSuccess(response.data)).pipe(
                finalize(() => action?.meta?.onSuccess(response.data))
              )
            )
          )
        ),
        catchError(err => of(actions.bulkQuizUpdateFailed(err)))
      )
    )
  )

const requestSendAnalysisByEmail = action$ =>
  action$.pipe(
    ofType(types.REQUEST_SEND_ANALYSIS_BY_EMAIL),
    mergeMap(action =>
      from(EmailService.sendAnalysisToEmail(action.data)).pipe(
        mergeMap(_response =>
          of(actions.sendAnalysisByEmailSuccess(action.data)).pipe(
            finalize(() => action?.meta?.onSuccess(action.data))
          )
        ),
        catchError(err =>
          of(actions.sendAnalysisByEmailFailed(err)).pipe(
            finalize(() => action?.meta?.onError(err.response.data))
          )
        )
      )
    )
  )

/**
 * This request should be performed as unauthorized request at all times.
 * P.S. THIS EPIC IS HORRIBLE AND SHOULD BE REFACTORED. It should be refactored
 * to something more reactive, since language change is just bad...
 *
 * tue, 26 may 2020, 14:26:21 CEST Update: it's not "so" horrible, because it
 * works.
 */
const requestGetQuizForFlow = action$ => {
  return action$.pipe(
    ofType(types.REQUEST_GET_QUIZ_FOR_FLOW),
    mergeMap(action =>
      from(QuizService.getQuizForFlow(action.id, action.config)).pipe(
        tap(response =>
          LanguageService.changeLanguage(response?.quiz?.language)
        ),
        mergeMap(response => {
          return of(actions.getQuizForFlowSuccess(response)).pipe(
            finalize(() => action?.meta?.onSuccess(response))
          )
        }),
        catchError(err =>
          of(actions.getQuizForFlowFailed(err)).pipe(
            finalize(() => action?.meta?.onError(err))
          )
        )
      )
    )
  )
}

const requestGetPaidQuizById = action$ =>
  action$.pipe(
    ofType(types.REQUEST_GET_PAID_QUIZ_BY_ID),
    mergeMap(action =>
      from(
        QuizService.getPaidQuizById(action.id, action.uid, {
          headers: { token: '' }
        })
      ).pipe(
        mergeMap(response =>
          of(
            actions.getPaidQuizByIdSuccess({
              quiz: response.data,
              uid: action.uid
            })
          ).pipe(finalize(() => action?.meta?.onSuccess()))
        ),
        catchError(err =>
          of(actions.getPaidQuizByIdFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

const requestCreateRespondentByCoupon = action$ =>
  action$.pipe(
    ofType(types.REQUEST_CREATE_RESPONDENT_BY_COUPON),
    mergeMap(action =>
      from(
        QuizService.getPaidQuizByIdUserWithCoupon(action.id, action.uid, {
          headers: { token: '' }
        })
      ).pipe(
        mergeMap(response =>
          of(actions.createRespondentByCouponSuccess(response.data)).pipe(
            finalize(() => action?.meta?.onSuccess(response.data))
          )
        ),
        catchError(err =>
          of(actions.createRespondentByCouponFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

/**
 * Used for fetching the quiz in external flow, i.e. the flow for respondents.
 * Added: Thu, 23 Dec 2021 10:52:45 +0100
 */
const requestGetQuizByRespondentUid = action$ =>
  action$.pipe(
    ofType(types.REQUEST_GET_QUIZ_BY_RESPONDENT_UID),
    mergeMap(action =>
      from(
        QuizService.getQuizById(action.data, {
          headers: { token: '' }
        })
      ).pipe(
        mergeMap(response =>
          of(
            actions.getQuizByRespondentUidSuccess({
              quiz: response.data,
              respondentUid: action.data
            })
          ).pipe(finalize(() => action?.meta?.onSuccess()))
        ),
        catchError(err =>
          of(actions.getQuizByRespondentUidFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

const requestGetRespondentQuiz = action$ =>
  action$.pipe(
    ofType(types.REQUEST_GET_RESPONDENT_QUIZ),
    mergeMap(action =>
      from(QuizService.getRespondentAnalysis(action.id)).pipe(
        mergeMap(response =>
          of(actions.getRespondentQuizSuccess(response.data)).pipe(
            finalize(() => action?.meta?.onSuccess())
          )
        ),
        catchError(err =>
          of(actions.getRespondentQuizFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

const requestGetQuizForTransaction = action$ =>
  action$.pipe(
    ofType(types.REQUEST_GET_QUIZ_FOR_TRANSACTION),
    mergeMap(action =>
      from(QuizService.getQuizById(action.id, { headers: { token: '' } })).pipe(
        mergeMap(response =>
          of(actions.getQuizForTransactionSuccess(response.data)).pipe(
            finalize(() => action?.meta?.onSuccess())
          )
        ),
        catchError(err =>
          of(actions.getQuizForTransactionFailed(err)).pipe(
            finalize(() => action?.meta?.onError(err))
          )
        )
      )
    )
  )

const requestCreateRespondent = action$ =>
  action$.pipe(
    ofType(types.REQUEST_CREATE_RESPONDENT),
    mergeMap(action =>
      from(QuizService.createRespondent(action.data)).pipe(
        mergeMap(response =>
          of(actions.createRespondentSuccess(response.data)).pipe(
            finalize(() => action?.meta?.onSuccess())
          )
        ),
        catchError(err =>
          of(actions.createRespondentFailed(err)).pipe(
            finalize(() => action?.meta?.onError(err))
          )
        )
      )
    )
  )

const requestMarkQuizAsStarted = action$ =>
  action$.pipe(
    ofType(types.REQUEST_MARK_QUIZ_AS_STARTED),
    mergeMap(action =>
      from(QuizService.markQuizAsStarted(action.data)).pipe(
        mergeMap(response =>
          of(actions.markQuizAsStartedSuccess(action.data)).pipe(
            finalize(() => action?.meta?.onSuccess())
          )
        ),
        catchError(err =>
          of(actions.markQuizAsStartedFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

const requestMarkQuizAsFinished = action$ =>
  action$.pipe(
    ofType(types.REQUEST_MARK_QUIZ_AS_FINISHED),
    mergeMap(action =>
      from(QuizService.markQuizAsFinished(action.uid)).pipe(
        mergeMap(response =>
          of(actions.markQuizAsFinishedSuccess(response.data)).pipe(
            finalize(() => action?.meta?.onSuccess())
          )
        ),
        catchError(err =>
          of(actions.markQuizAsFinishedFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

const requestUpdateRespondent = action$ =>
  action$.pipe(
    ofType(types.REQUEST_UPDATE_RESPONDENT),
    mergeMap(action =>
      from(QuizService.updateRespondent(action.uid, action.data)).pipe(
        mergeMap(response =>
          of(actions.updateRespondentSuccess(response.data)).pipe(
            finalize(() => action?.meta?.onSuccess())
          )
        ),
        catchError(err =>
          of(actions.updateRespondentFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

const requestDeleteRespondent = action$ =>
  action$.pipe(
    ofType(types.REQUEST_DELETE_RESPONDENT),
    mergeMap(action =>
      from(QuizService.deleteRespondent(action.id)).pipe(
        mergeMap(response =>
          of(actions.deleteRespondentSuccess(action.id)).pipe(
            finalize(() => action?.meta?.onSuccess())
          )
        ),
        catchError(err =>
          of(actions.deleteRespondentFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

const requestGetRespondents = action$ =>
  action$.pipe(
    ofType(types.REQUEST_GET_RESPONDENTS),
    mergeMap(action =>
      from(QuizService.getRespondents(action.config)).pipe(
        mergeMap(response =>
          of(actions.getRespondentsSuccess(response.data)).pipe(
            finalize(() => action.meta?.onSuccess(response.data))
          )
        ),
        catchError(err =>
          of(actions.getRespondentsFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

const requestPostRespondentAnswers = action$ =>
  action$.pipe(
    ofType(types.REQUEST_POST_RESPONDENT_ANSWERS),
    concatMap(action => {
      const result$ =
        action?.data?.answersToDelete?.length > 0
          ? from(
              QuizService.deleteRespondentAnswers(
                action.uid,
                action?.data?.answersToDelete
              )
            )
          : of('noop')

      return result$.pipe(
        mergeMap(_ =>
          from(
            QuizService.postRespondentQuizData({
              uid: action.uid,
              payload: action.data
            })
          ).pipe(
            mergeMap(response =>
              of(actions.postRespondentAnswersSuccess(response)).pipe(
                finalize(() => action?.meta?.onSuccess(response))
              )
            )
          )
        ),
        catchError(err =>
          of(actions.postRespondentAnswersFailed(err)).pipe(
            finalize(() => action?.meta?.onError(err))
          )
        )
      )
    })
  )

const requestGetStatistics = (action$, state$) =>
  action$.pipe(
    ofType(types.REQUEST_GET_STATISTICS),
    mergeMap(action => {
      const _profile = state$.value.user.profile

      l.log('Attempting to fetch statistics', { currentProfile: _profile })

      const statistics$ = profile =>
        from(
          QuizService.getStatistics({
            ...action.data,
            limit: getQuizzesListPageLimitByUserProfile(profile)
          })
        ).pipe(
          mergeMap(response =>
            of(actions.getStatisticsSuccess(response.data)).pipe(
              finalize(() => action?.meta?.onSuccess())
            )
          ),
          catchError(err =>
            of(actions.getStatisticsFailed(err)).pipe(
              finalize(() => action?.meta?.onError())
            )
          )
        )

      if (isEmpty(propOr({}, 'user', _profile))) {
        l.log(
          'Profile not available, fetching it from API to determine email address...'
        )
        return from(UserService.getProfile()).pipe(
          mergeMap(profileResponse => {
            l.log('Profile data fetched, attempting to fetch statistics...')
            return statistics$(profileResponse.data)
          })
        )
      }

      l.log('Profile data is already loaded, fetching the statistics')

      return statistics$(_profile)
    })
  )

const requestResetQuizStatistics = action$ =>
  action$.pipe(
    ofType(types.REQUEST_RESET_QUIZ_STATISTICS),
    mergeMap(action =>
      from(
        QuizService.updateQuiz(action.id, {
          conversionRate: { finished: 0, all: 0 }
        })
      ).pipe(
        mergeMap(response => {
          return of(actions.resetQuizStatisticsSuccess(response.data)).pipe(
            finalize(() => action?.meta?.onSuccess(response.data))
          )
        }),
        catchError(err =>
          of(actions.resetQuizStatisticsFailed(err)).pipe(
            finalize(() => action?.meta?.onError(err?.response))
          )
        )
      )
    )
  )

const requestResetQuizEvaluation = action$ =>
  action$.pipe(
    ofType(types.REQUEST_RESET_QUIZ_EVALUATION),
    mergeMap(action =>
      from(QuizService.resetEvaluation(action.id)).pipe(
        mergeMap(response => {
          return of(actions.resetQuizStatisticsSuccess(response.data)).pipe(
            finalize(() => action?.meta?.onSuccess(response.data))
          )
        }),
        catchError(err =>
          of(actions.resetQuizStatisticsFailed(err)).pipe(
            finalize(() => action?.meta?.onError(err?.response))
          )
        )
      )
    )
  )

const requestStatisticsEvaluationById = action$ =>
  action$.pipe(
    ofType(types.REQUEST_GET_STATISTIC_EVALUATION_BY_ID),
    mergeMap(action =>
      from(QuizService.getStatisticsEvaluationById(action.id)).pipe(
        mergeMap(response =>
          of(actions.getStatisticsEvaluationSuccess(response.data)).pipe(
            finalize(() => action?.meta?.onSuccess())
          )
        ),
        catchError(err =>
          of(actions.getStatisticsEvaluationFailed(err)).pipe(
            finalize(() => action?.meta?.onError())
          )
        )
      )
    )
  )

export const quizEpics = combineEpics(
  updateQuiz$,
  quizzesModified$,
  requestMarkQuizAsStarted,
  requestMarkQuizAsFinished,
  requestResetQuizStatistics,
  requestResetQuizEvaluation,
  requestSendAnalysisByEmail,
  requestBulkQuizUpdate,
  requestCreateAnswer,
  requestCreateQuestion,
  requestCreateScoreEmailReply,
  requestCreateQuiz,
  requestCreateRespondent,
  requestCreateRespondentByCoupon,
  requestGetQuizByRespondentUid,
  requestCreateTitle,
  requestDeleteAnswer,
  requestDeleteQuestion,
  requestDeleteQuiz,
  requestDeleteSingleEmailScore,
  requestDeleteAllEmailScore,
  requestDeleteRespondent,
  requestDeleteTitle,
  requestDuplicateQuiz,
  requestGetPaidQuizById,
  requestGetQuizById,
  requestGetQuizForFlow,
  requestGetQuizForTransaction,
  requestGetQuizzes,
  requestGetRespondentQuiz,
  requestGetRespondents,
  requestGetStatistics,
  requestGetTemplates,
  requestStatisticsEvaluationById,
  requestPostRespondentAnswers,
  requestUpdateAnswer,
  requestSaveAllEmailScoring,
  requestUpdateEmailScore,
  requestUpdateQuestion,
  requestUpdateQuestionWithAnswers,
  requestUpdateOptInStep,
  requestUpdateOptInInformationToGather,
  requestUpdateQuiz,
  requestUpdateRespondent,
  requestUpdateTitle,
  requestCreateGroupedQuestion,
  // requestCreateGroupedQuestionWithSettings,
  requestUpdateQuestionGroupSettings,
  requestCreateDefaultAnswersForGroupedQuestion
)
