import { noop } from 'ramda-adjunct'

const developmentConfig = ({ name, logger }) => ({
  log: (...args) => {
    return logger.log(`%c ${name}::`, 'color: green;font-weight:bold;', ...args)
  },
  warn: (...args) => {
    return logger.warn(
      `%c ${name}::`,
      'color: orange;font-weight:bold;',
      ...args
    )
  },
  error: (...args) => {
    return logger.error(`%c ${name}::`, 'color: red;font-weight:bold;', ...args)
  }
})

const productionConfig = () => ({
  log: noop,
  warn: noop,
  error: noop
})

const DEPLOY_TARGET = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production'
}

const loggerTargets = [DEPLOY_TARGET.STAGING, DEPLOY_TARGET.DEVELOPMENT]

/**
 * A simple logger that uses console babel plugin macro. Takes a configuration
 * object with `name`, which will be used as a logger label, `logger`, which is
 * an object that has to implement three `console` methods and `isDev` which is
 * a predicate used for choosing logger config.
 * Added: Mon, 23 Nov 2020 11:16:36 +0100
 * @name logger
 * @author Rafał Wyszomirski <ralf@desmart.com>
 * @method
 * @static
 * @param {Object} config
 * @param {string} name name of the logger
 * @param {Object} logger a console-like object
 * @param {boolean} isDev
 * @returns {Object}
 */
export const logger = ({
  name = 'DefaultLogger',
  logger = console,
  isDev = import.meta.env.NODE_ENV === 'development' ||
    loggerTargets.includes(import.meta.env.VITE_DEPLOY_TARGET)
}) => {
  return isDev ? developmentConfig({ name, logger }) : productionConfig()
}
