import { useEffect } from 'react'
import qs from 'qs'

import LanguageService, { LANGUAGE_CODES } from 'services/LanguageService'

const useDefaultLanguage = () => {
  // set language for the app if lang query is present in the URL. Do it only
  // once on app bootstrap.
  useEffect(() => {
    const { lang } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    })

    if (lang && Object.values(LANGUAGE_CODES).includes(lang)) {
      LanguageService.changeLanguage(lang)
    }
  }, [])
}

export default useDefaultLanguage
