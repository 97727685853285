/**
 *
 * @module services/LanguageService
 * @description A service that works as an abstraction over i18n interface.
 */
import i18n from 'core/i18n'
import { logger } from 'utils/logger'

export const API_LANGUAGE_STRING = {
  GERMAN: 'German',
  ENGLISH: 'English',
  SPANISH: 'Spanish',
  FRENCH: 'French',
  HINDI: 'Hindi',
  ITALIAN: 'Italian',
  JAPANESE: 'Japanese',
  DUTCH: 'Dutch',
  POLISH: 'Polish',
  PORTUGUESE: 'Portuguese',
  RUSSIAN: 'Russian',
  TURKISH: 'Turkish',
  CHINESE: 'Chinese'
}

export const LANGUAGE_CODES = {
  GERMAN: 'de',
  ENGLISH: 'en',
  SPANISH: 'es',
  FRENCH: 'fr',
  HINDI: 'hi',
  ITALIAN: 'it',
  JAPANESE: 'ja',
  DUTCH: 'nl',
  POLISH: 'pl',
  PORTUGUESE: 'pt',
  RUSSIAN: 'ru',
  TURKISH: 'tr',
  CHINESE: 'zh'
}

export const LANGUAGE_TO_COUNTRY_CODE = {
  English: 'en',
  German: 'de',
  Spanish: 'es',
  French: 'fr',
  Hindi: 'hi',
  Italian: 'it',
  Japanese: 'ja',
  Dutch: 'nl',
  Polish: 'pl',
  Portuguese: 'pt',
  Russian: 'ru',
  Turkish: 'tr',
  Chinese: 'zh'
}

const API_LANGUAGES = [
  'English',
  'German',
  'Spanish',
  'French',
  'Hindi',
  'Italian',
  'Japanese',
  'Dutch',
  'Polish',
  'Portuguese',
  'Russian',
  'Turkish',
  'Chinese'
]

export const LANGUAGE_OPTIONS = t => [
  {
    value: 'English',
    label: t('Common:language.english')
  },
  {
    value: 'German',
    label: t('Common:language.german')
  },
  {
    value: 'Spanish',
    label: t('Common:language.spanish')
  },
  {
    value: 'French',
    label: t('Common:language.french')
  },
  {
    value: 'Hindi',
    label: t('Common:language.hindi')
  },
  {
    value: 'Italian',
    label: t('Common:language.italian')
  },
  {
    value: 'Japanese',
    label: t('Common:language.japanese')
  },
  {
    value: 'Dutch',
    label: t('Common:language.dutch')
  },
  {
    value: 'Polish',
    label: t('Common:language.polish')
  },
  {
    value: 'Portuguese',
    label: t('Common:language.portuguese')
  },
  {
    value: 'Russian',
    label: t('Common:language.russian')
  },
  {
    value: 'Turkish',
    label: t('Common:language.turkish')
  },
  {
    value: 'Chinese',
    label: t('Common:language.chinese')
  }
]

export const LANGUAGE_STRINGS = t => ({
  [API_LANGUAGE_STRING.ENGLISH]: t('Common:language.english'),
  [API_LANGUAGE_STRING.GERMAN]: t('Common:language.german'),
  [API_LANGUAGE_STRING.SPANISH]: t('Common:language.spanish'),
  [API_LANGUAGE_STRING.FRENCH]: t('Common:language.french'),
  [API_LANGUAGE_STRING.HINDI]: t('Common:language.hindi'),
  [API_LANGUAGE_STRING.ITALIAN]: t('Common:language.italian'),
  [API_LANGUAGE_STRING.JAPANESE]: t('Common:language.japanese'),
  [API_LANGUAGE_STRING.DUTCH]: t('Common:language.dutch'),
  [API_LANGUAGE_STRING.POLISH]: t('Common:language.polish'),
  [API_LANGUAGE_STRING.PORTUGUESE]: t('Common:language.portuguese'),
  [API_LANGUAGE_STRING.RUSSIAN]: t('Common:language.russian'),
  [API_LANGUAGE_STRING.TURKISH]: t('Common:language.turkish'),
  [API_LANGUAGE_STRING.CHINESE]: t('Common:language.chinese')
})

/**
 * Adapts payload for i18n translation interface. Since the API accepts
 * different format for languages, the payload has to be transformed to country
 * codes.
 * @constant
 * @name mapLanguageToCountryCode
 */
const mapLanguageToCountryCode = payload =>
  API_LANGUAGES.includes(payload) ? LANGUAGE_TO_COUNTRY_CODE[payload] : payload

const LanguageService = {
  logger: logger({ name: 'LanguageService' }),
  changeLanguage: language => {
    LanguageService.logger.log('changeLanguage', language)
    return i18n.changeLanguage(mapLanguageToCountryCode(language))
  },
  getCurrentLocale: () => i18n.language,
  getCurrentLanguageOption: () => {
    const currentLanguage = LanguageService.getCurrentLocale()
    switch (currentLanguage) {
      case LANGUAGE_CODES.GERMAN:
        return 'German'
      case LANGUAGE_CODES.ENGLISH:
        return 'English'
      case LANGUAGE_CODES.SPANISH:
        return 'Spanish'
      case LANGUAGE_CODES.FRENCH:
        return 'French'
      case LANGUAGE_CODES.HINDI:
        return 'Hindi'
      case LANGUAGE_CODES.ITALIAN:
        return 'Italian'
      case LANGUAGE_CODES.JAPANESE:
        return 'Japanese'
      case LANGUAGE_CODES.DUTCH:
        return 'Dutch'
      case LANGUAGE_CODES.POLISH:
        return 'Polish'
      case LANGUAGE_CODES.PORTUGUESE:
        return 'Portuguese'
      case LANGUAGE_CODES.RUSSIAN:
        return 'Russian'
      case LANGUAGE_CODES.TURKISH:
        return 'Turkish'
      case LANGUAGE_CODES.CHINESE:
        return 'Chinese'
      default:
        return 'German'
    }
  },
  getTimezoneForLocale: () => {
    const currentLanguage = LanguageService.getCurrentLocale()
    LanguageService.logger.log('Determine timezone for current locale...')

    let _timezone

    switch (currentLanguage) {
      case LANGUAGE_CODES.GERMAN:
        _timezone = 'Europe/Berlin'
        break
      case LANGUAGE_CODES.ENGLISH:
        _timezone = 'Europe/London'
        break
      case LANGUAGE_CODES.SPANISH:
        _timezone = 'Europe/Madrit'
        break
      case LANGUAGE_CODES.FRENCH:
        _timezone = 'Europe/Paris'
        break
      case LANGUAGE_CODES.HINDI:
        _timezone = 'Europe/New Delhi'
        break
      case LANGUAGE_CODES.ITALIAN:
        _timezone = 'Europe/Rome'
        break
      case LANGUAGE_CODES.JAPANESE:
        _timezone = 'Europe/Tokyo'
        break
      case LANGUAGE_CODES.DUTCH:
        _timezone = 'Europe/Amsterdam'
        break
      case LANGUAGE_CODES.POLISH:
        _timezone = 'Europe/Warsaw'
        break
      case LANGUAGE_CODES.PORTUGUESE:
        _timezone = 'Europe/Lisbon'
        break
      case LANGUAGE_CODES.RUSSIAN:
        _timezone = 'Europe/Moscow'
        break
      case LANGUAGE_CODES.TURKISH:
        _timezone = 'Europe/Ankara'
        break
      case LANGUAGE_CODES.CHINESE:
        _timezone = 'Europe/Beijing'
        break
      default:
        _timezone = 'Europe/Berlin'
        break
    }

    LanguageService.logger.log('Current locale/timezone configuration', {
      timezone: _timezone,
      language: currentLanguage
    })

    return _timezone
  }
}

export default LanguageService
