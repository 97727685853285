import { Suspense } from 'react'
import SpinnerIcon from 'components/SpinnerIcon'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/styles'

const PageWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  minHeight: '100vh',
  minWidth: '100vw',
  display: 'grid',
  placeItems: 'center'
}))

export const SuspenseSpinner = ({ children }) => {
  const theme = useTheme()
  return (
    <Suspense
      fallback={
        <PageWrapper>
          <SpinnerIcon sx={{ height: 128, width: 128 }} color={theme.palette.custom.base} />
        </PageWrapper>
      }
    >
      {children}
    </Suspense>
  )
}
