/**
 * @file A file containing definitions for main routes used in the application.
 * @author Rafał Wyszomirski
 */
import React, { lazy } from 'react'
import {
  BrowserRouter as Router,
  Routes as ReactRouterRoutes,
  Route,
  Navigate
} from 'react-router-dom'

import { ROUTES } from 'constants/navigation'
import { VENDOR_REGISTRATION_FLOW_TYPE } from 'constants/user'

import { RequireAuth, RequireSubscription } from './RouteGuards'
import { SuspenseSpinner } from './SuspenseSpinner'

/**
 * Vendor pages
 */
const AccountDeletedPage = lazy(() => import('pages/AccountDeletedPage'))
const ForgotPasswordPage = lazy(() => import('pages/ForgotPasswordPage'))
const LoginPage = lazy(() => import('pages/LoginPage'))
const RegistrationPage = lazy(() => import('pages/RegistrationPage'))
const NotFoundPage = lazy(() => import('pages/NotFoundPage'))
const ProfileSettingsPage = lazy(() => import('pages/ProfileSettingsPage'))
const SubscriptionPage = lazy(() => import('pages/SubscriptionPage'))
const StartQuizPage = lazy(() => import('pages/StartQuizPage'))
const SupportPage = lazy(() => import('pages/SupportPage'))
const TutorialPage = lazy(() => import('pages/TutorialPage'))
const ValidateResetPasswordPage = lazy(() =>
  import('pages/ValidateResetPasswordPage')
)
const WelcomePage = lazy(() => import('pages/WelcomePage'))
const QuizzesListPage = lazy(() => import('pages/QuizzesListPage'))
const TemplatesPage = lazy(() => import('pages/TemplatesPage'))
const StatisticsPage = lazy(() => import('pages/StatisticsPage'))
const StatisticEvaluation = lazy(() =>
  import('pages/StatisticsPage/Evaluation')
)
const StripeConnectCallbackPage = lazy(() =>
  import('pages/StripeConnectCallbackPage')
)
const ConfirmEmailPage = lazy(() => import('pages/ConfirmEmailPage'))

/**
 * Contacts pages
 */
const ContactsPage = lazy(() => import('pages/ContactsPage'))
const RespondentDetailsPage = lazy(() => import('pages/RespondentDetailsPage'))

/**
 * Invoice page
 */
const InvoicesPage = lazy(() => import('pages/InvoicesPage'))

/**
 * Payment pages
 */
const PaymentsPage = lazy(() => import('pages/PaymentsPage'))
const PaymentConfirmationPage = lazy(() =>
  import('pages/PaymentConfirmationPage')
)
const VendorRegistrationPage = lazy(() =>
  import('pages/VendorRegistrationPage')
)
const RegistrationConfirmationPage = lazy(() =>
  import('pages/RegistrationConfirmationPage')
)
const EmbedRegistrationPage = lazy(() =>
  import('pages/EmbedRegistrationPage.js')
)

/**
 * Preview
 */
const QuizPreviewFlowPage = lazy(() => import('pages/QuizPreviewFlowPage'))

/**
 * Respondent pages
 */
const QuizFlowPage = lazy(() => import('pages/QuizFlowPage'))
const QuizNotActivePage = lazy(() =>
  import('pages/QuizFlowPage/QuizNotActivePage')
)

/**
 * AdminPanel pages
 */
const AdminPanel = lazy(() => import('pages/AdminPanel/'))
const Management = lazy(() => import('pages/AdminPanel/ManagementPage'))
const Invoices = lazy(() => import('pages/AdminPanel/InvoicesPage'))
const Tools = lazy(() => import('pages/AdminPanel/ToolsPage'))

const Routes = () => {
  return (
    <Router>
      <ReactRouterRoutes>
        <Route
          path='/'
          element={
            <SuspenseSpinner>
              <RequireAuth>
                <RequireSubscription>
                  <Navigate to={ROUTES.PROJECTS} replace />
                </RequireSubscription>
              </RequireAuth>
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.LOGIN}
          element={
            <SuspenseSpinner>
              <LoginPage />
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.FORGOT_PASSWORD}
          element={
            <SuspenseSpinner>
              <ForgotPasswordPage />
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.WELCOME}
          element={
            <SuspenseSpinner>
              <RequireAuth>
                <RequireSubscription>
                  <WelcomePage />
                </RequireSubscription>
              </RequireAuth>
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.TUTORIAL}
          element={
            <SuspenseSpinner>
              <RequireAuth>
                <RequireSubscription>
                  <TutorialPage />
                </RequireSubscription>
              </RequireAuth>
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.PROFILE}
          element={
            <SuspenseSpinner>
              <RequireAuth>
                <ProfileSettingsPage />
              </RequireAuth>
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.SUBSCRIPTION}
          element={
            <SuspenseSpinner>
              <RequireAuth>
                <SubscriptionPage />
              </RequireAuth>
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.SUPPORT}
          element={
            <SuspenseSpinner>
              <RequireAuth>
                <SupportPage />
              </RequireAuth>
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.PROJECTS}
          element={
            <SuspenseSpinner>
              <RequireAuth>
                <RequireSubscription>
                  <QuizzesListPage />
                </RequireSubscription>
              </RequireAuth>
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.CONFIGURE_FORM.ROOT}
          element={
            <SuspenseSpinner>
              <RequireAuth>
                <RequireSubscription>
                  <StartQuizPage />
                </RequireSubscription>
              </RequireAuth>
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.TEMPLATES}
          element={
            <SuspenseSpinner>
              <RequireAuth>
                <RequireSubscription>
                  <TemplatesPage />
                </RequireSubscription>
              </RequireAuth>
            </SuspenseSpinner>
          }
        />

        <Route
          path={ROUTES.ADMIN_PANEL.DASHBOARD}
          element={
            <SuspenseSpinner>
              <RequireAuth>
                <RequireSubscription>
                  <AdminPanel />
                </RequireSubscription>
              </RequireAuth>
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.ADMIN_PANEL.MANAGEMENT}
          element={
            <SuspenseSpinner>
              <RequireAuth>
                <RequireSubscription>
                  <Management />
                </RequireSubscription>
              </RequireAuth>
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.ADMIN_PANEL.INVOICES}
          element={
            <SuspenseSpinner>
              <RequireAuth>
                <RequireSubscription>
                  <Invoices />
                </RequireSubscription>
              </RequireAuth>
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.ADMIN_PANEL.TOOLS}
          element={
            <SuspenseSpinner>
              <RequireAuth>
                <RequireSubscription>
                  <Tools />
                </RequireSubscription>
              </RequireAuth>
            </SuspenseSpinner>
          }
        />

        <Route
          path={ROUTES.STATISTICS_EVALUATION}
          element={
            <SuspenseSpinner>
              <RequireAuth>
                <RequireSubscription>
                  <StatisticEvaluation />
                </RequireSubscription>
              </RequireAuth>
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.STATISTICS}
          element={
            <SuspenseSpinner>
              <RequireAuth>
                <RequireSubscription>
                  <StatisticsPage />
                </RequireSubscription>
              </RequireAuth>
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.CONTACTS.VIEW}
          element={
            <SuspenseSpinner>
              <RequireAuth>
                <RequireSubscription>
                  <RespondentDetailsPage />
                </RequireSubscription>
              </RequireAuth>
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.CONTACTS.LIST}
          element={
            <SuspenseSpinner>
              <RequireAuth>
                <RequireSubscription>
                  <ContactsPage />
                </RequireSubscription>
              </RequireAuth>
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.INVOICES.LIST}
          element={
            <SuspenseSpinner>
              <RequireAuth>
                <InvoicesPage />
              </RequireAuth>
            </SuspenseSpinner>
          }
        />

        <Route
          path={ROUTES.STRIPE_CALLBACK}
          element={
            <SuspenseSpinner>
              <StripeConnectCallbackPage />
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.PAYMENTS.CONFIRMATION}
          element={
            <SuspenseSpinner>
              <PaymentConfirmationPage />
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.PAYMENTS.START}
          element={
            <SuspenseSpinner>
              <PaymentsPage />
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.EMBEDDED.REGISTRATION}
          element={
            <SuspenseSpinner>
              <EmbedRegistrationPage />
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.VENDOR.REGISTRATION}
          element={
            <SuspenseSpinner>
              <RegistrationPage />
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.VENDOR.REGISTRATION_CONFIRMATION}
          element={
            <SuspenseSpinner>
              <RegistrationConfirmationPage />
            </SuspenseSpinner>
          }
        />

        <Route
          path={ROUTES.VENDOR.UPDATE_PAYMENT_METHOD}
          element={
            <SuspenseSpinner>
              <RequireAuth>
                <VendorRegistrationPage
                  flowType={VENDOR_REGISTRATION_FLOW_TYPE.UPDATE_PAYMENT_METHOD}
                />
              </RequireAuth>
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.VENDOR.BUY_PLAN}
          element={
            <SuspenseSpinner>
              <RequireAuth>
                <VendorRegistrationPage />
              </RequireAuth>
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.VENDOR.BUY_PLAN_CONFIRMATION}
          element={
            <SuspenseSpinner>
              <RegistrationConfirmationPage />
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.FORM.NOT_ACTIVE}
          element={
            <SuspenseSpinner>
              <QuizNotActivePage />
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.FORM_PREVIEW.ROOT}
          element={
            <SuspenseSpinner>
              <QuizPreviewFlowPage />
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.FORM.ROOT}
          element={
            <SuspenseSpinner>
              <QuizFlowPage />
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.ACCOUNT_DELETED}
          element={
            <SuspenseSpinner>
              <AccountDeletedPage />
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.VENDOR.CONFIRM_EMAIL}
          element={
            <SuspenseSpinner>
              <ConfirmEmailPage />
            </SuspenseSpinner>
          }
        />
        <Route
          path={ROUTES.VALIDATE_RESET_PASSWORD}
          element={
            <SuspenseSpinner>
              <ValidateResetPasswordPage />
            </SuspenseSpinner>
          }
        />

        <Route
          path='*'
          element={
            <SuspenseSpinner>
              <NotFoundPage />
            </SuspenseSpinner>
          }
        />
      </ReactRouterRoutes>
    </Router>
  )
}

export default Routes
