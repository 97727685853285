import ApiClient from 'core/ApiClient'
import { STORAGE_KEYS } from 'constants/storage'

/**
 *
 * @module services/AuthService
 * @description A service for handling requests connected with authorization.
 */
const AuthService = {
  /**
   * Used for logging in to the app. The promise resolves with important user
   * details and their JWT token for performing authorized requests.
   * @name login
   * @method
   * @static
   * @param
   * @returns {Promise}
   */
  login: ({ email, password }) =>
    ApiClient.post({
      url: '/login/',
      data: {
        email,
        password
      }
    }),
  /**
   * Checks previously selected storage engine for the presence of JWT
   * token. The token is then parsed and if it looks like a JWT token,
   * `isAuthenticated` returns `true`. Returns false otherwise.
   * @name isAuthenticated
   * @method
   * @static
   * @param
   * @returns {Boolean}
   */
  isAuthenticated: () => {
    const token = ApiClient.storage.getItem(STORAGE_KEYS.TOKEN)

    try {
      const tokenObject = JSON.parse(window.atob(token.split('.')[1]))

      return !!tokenObject.id
    } catch (_) {
      return false
    }
  },
  /**
   * Removes the token from previously selected storage solution. Please bear in
   * mind that this method is not responsible for any redirects or other side
   * effects.
   * @name logout
   * @method
   * @static
   * @param
   */
  logout: () => ApiClient.removeSession(),
  /**
   * Initializes the reset password flow. It triggers sending a verification
   * link to the provided email.
   * @name requestResetPassword
   * @method
   * @static
   * @param {Object} payload
   * @param {String} payload.email Email of the account for which the reset
   * password request is triggered.
   * @returns {Promise}
   */
  requestResetPassword: ({ email }) =>
    ApiClient.post({
      url: '/password/remind',
      data: {
        email
      }
    }),
  /**
   * Used for validating the reset password request. User clicks the link in the
   * email and is redirect to the app, where credentials from the url (email and
   * hash)
   * @name validateResetPasswordRequest
   * @method
   * @static
   * @param {Object} payload
   * @param {String} payload.email User's email extracted from the URL.
   * @param {String} payload.hash A hash used for validating the reset password
   * request. Taken from the URL.
   * @returns {Promise}
   */
  validateResetPasswordRequest: ({ email, hash }) =>
    ApiClient.get({
      url: `/password/hash/validate/${email}/${hash}`
    }),
  /**
   * Sets the new password for the user. This is used as a final part of the
   * reset password flow.
   * @name setNewPassword
   * @method
   * @static
   * @param {Object} payload
   * @param {String} payload.email Email taken from the URL.
   * @param {String} payload.hash A hash taken from the URL.
   * @param {String} payload.password A new password to set.
   * @returns {Promise}
   */
  setNewPassword: payload =>
    ApiClient.put({
      url: '/password/reset',
      data: payload
    })
}

export default AuthService
