// TODO: 1. Refactor inner joins
import countries from 'i18n-iso-countries'
import {
  innerJoin,
  keys,
  map,
  pipe,
  prop,
  sortBy,
  toPairs
} from 'ramda'
import { STRIPE_CONNECT_ENABLED_COUNTRIES } from 'constants/payments'
import LanguageService from 'services/LanguageService'

import en from 'i18n-iso-countries/langs/en.json'
import de from 'i18n-iso-countries/langs/de.json'
import es from 'i18n-iso-countries/langs/es.json'
import fr from 'i18n-iso-countries/langs/fr.json'
import hi from 'i18n-iso-countries/langs/hi.json'
import it from 'i18n-iso-countries/langs/it.json'
import ja from 'i18n-iso-countries/langs/ja.json'
import nl from 'i18n-iso-countries/langs/nl.json'
import pl from 'i18n-iso-countries/langs/pl.json'
import pt from 'i18n-iso-countries/langs/pt.json'
import ru from 'i18n-iso-countries/langs/ru.json'
import tr from 'i18n-iso-countries/langs/tr.json'
import zh from 'i18n-iso-countries/langs/zh.json'

countries.registerLocale(en)
countries.registerLocale(de)
countries.registerLocale(es)
countries.registerLocale(fr)
countries.registerLocale(hi)
countries.registerLocale(it)
countries.registerLocale(ja)
countries.registerLocale(nl)
countries.registerLocale(pl)
countries.registerLocale(pt)
countries.registerLocale(ru)
countries.registerLocale(tr)
countries.registerLocale(zh)


const CountryRepository = {
  getCountries: () => {
    const locale = LanguageService.getCurrentLocale()

    return countries.getNames(locale)
  },
  getOptions: () => {
    const countries = CountryRepository.getCountries()

    return pipe(
      keys,
      map(k => ({ value: k, label: countries[k] })),
      sortBy(prop('label'))
    )(countries)
  },
  getStripeConnectSupportedCountries: (
    list = STRIPE_CONNECT_ENABLED_COUNTRIES
  ) => {
    const countries = CountryRepository.getCountries()
    // 1
    return pipe(
      innerJoin((record, id) => record[0] === id, toPairs(countries))
    )(list)
  },
  getLabelsFromCountryCodeList: list => {
    const countries = CountryRepository.getCountries()
    // 1
    return pipe(
      innerJoin((record, id) => record[0] === id, toPairs(countries)),
      map(([, label]) => label)
    )(list)
  }
}

export default CountryRepository
