import { not, head } from 'ramda'
import { isEmpty } from 'lodash'

export const isEmbeddedExternally = () => {
  try {
    return (
      window.self !== window.top &&
      window.self.location.hostname !== window.top.location.hostname
    )
  } catch (e) {
    return true
  }
}

export const handleExternalLink = link => {
  const a = document.createElement('a')
  a.href = link
  a.target = '_blank'
  a.rel = 'noopener'
  a.click()
}

export const setQuizPageTitleAndIcon = quiz => {
  let titleElement = document.querySelector('title')
  if (!titleElement) {
    titleElement = document.createElement('link')
    document.getElementsByTagName('head')[0].appendChild(titleElement)
  }

  if (quiz.design.iconUrl) {
    // Page title should be customized only when there's a custom page icon.
    // See https://desmart.atlassian.net/browse/QUIZ-1185
    titleElement.innerText = quiz.design.pageTitle || quiz.name
  }

  if (quiz.design.iconUrl) {
    const links = Array.from(document.querySelectorAll("link[rel~='icon']"))
    links.push(
      ...Array.from(document.querySelectorAll("link[rel~='apple-touch-icon']"))
    )

    if (!links.length) {
      const link = document.createElement('link')
      link.rel = 'icon'
      links.push(link)
      document.getElementsByTagName('head')[0].appendChild(link)
    }

    for (let link of links) {
      link.setAttribute('href', quiz.design.iconUrl)
    }
  }
}
