/**
 * @file A theme used by Material UI.
 * @author Rafał Wyszomirski
 */
import { alpha, createTheme } from '@mui/material/styles'
import { transparentize } from 'polished'
import { keyframes, css } from '@emotion/react'

const FONTS = {
  PRIMARY: '"Montserrat", sans-serif'
}

const BREAKPOINT_VALUES = {
  xs: 360,
  sm: 600,
  md: 1024,
  lg: 1366,
  xl: 1920
}

const colors = {
  primary: '#42b0ff',
  lightPrimary: '#42b0ff10',
  tertiary: '#5AC6AE',
  lightTertiary: '#5AC6AE55',
  success: '#52D677',
  active: '#238F2D',
  inactive: '#E29808',
  error: '#EF3C3C',
  textPrimary: 'rgba(0, 0, 0, 0.87)',
  textSecondary: '#7A7A7A',
  textTertiary: '#A4BAC3',
  border: '#ddd',
  darkBorder: '#888',
  lightBorder: '#EAEAEA',
  textInverse: '#fff',
  white: '#fff',
  shadow: '#f5f5f5',
  searchField: '#F2F2F2',
  questionBackground: '#F2FBFF',
  borderSecondary: '#E5EEF2',
  paymentsPageBackground: '#fafafa',
  inputBorder: '#949494',
  editorDisabled: '#949494',
  bodyBackground: '#fafafa',
  tertiaryBackground: '#f7fcfb'
}

export const DEFAULT_THEME_CONFIG = {
  typography: {
    fontFamily: FONTS.PRIMARY,
    h2: {
      fontSize: '1.875rem',
      fontWeight: 700,
      textTransform: 'uppercase'
    },
    h3: {
      fontSize: '1.5rem',
      letterSpacing: '0.025rem',
      fontWeight: 700
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        label: {
          textTransform: 'none'
        },
        root: {
          borderRadius: 20,
          padding: '8px 48px'
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: '#53b5e0'
          }
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 32
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '0 32px'
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '24px 32px'
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 20
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          lineHeight: '1.3876em'
        }
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      }
    }
  },
  palette: {
    primary: {
      main: '#42b0ff',
      contrastText: '#fff'
    },
    secondary: {
      main: '#ef3c3c',
      contrastText: '#fff'
    },
    tertiary: {
      main: '#5AC6AE',
      contrastText: '#fff'
    },
    text: {
      secondary: '#7A7A7A'
    },
    error: {
      main: '#ef3c3c'
    },
    scoreFunctionSummary: {
      subTitle: '#42b0ff',
      answer: '#1d1a3c'
    },
    lock: {
      main: '#42b0ff',
      contrastText: '#fff'
    },
    unlock: {
      main: '#ef3c3c',
      contrastText: '#fff'
    },
    preview: {
      main: '#ff9142',
      lightBackground: '#f2fbff'
    },
    custom: {
      background: '#ffffff',
      base: '#eaeaea',
      baseLighten: alpha('#eaeaea', 0.15),
      outsideFrame: '#ffffff',
      text: '#000000',
      textLighten: alpha('#000000', 0.5)
    }
  },
  breakpoints: {
    values: BREAKPOINT_VALUES
  },
  circularProgress: {
    strokeLinecap: 'round',
    pathTransitionDuration: 0.5,
    pathColor: colors.primary,
    trailColor: '#42B0FF20'
  }
}

const theme = createTheme(DEFAULT_THEME_CONFIG)

const showUp = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`
export const animationShowUp = time => css`
  ${showUp} ${time}ms ease-in-out, linear;
`

theme.typography.h2 = {
  ...theme.typography.h2,
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.5rem'
  }
}

export const formControlsReduceMarginTop = {
  marginTop: '2rem'
}

// value         |0px     600px    960px    1280px   1920px
// key           |xs      sm       md       lg       xl

const styledTheme = {
  breakpoints: BREAKPOINT_VALUES,
  typography: {
    fontFamily: FONTS.PRIMARY
  },
  colors,
  elevation: {
    dropdown: 20,
    sidebar: 100,
    headerBar: 10,
    mobileNavigation: 110,
    fixed: 120
  },
  layout: {
    mobileNavigationHeight: 64,
    headerBarHeight: 100,
    sidebarWidth: 390,
    sidebarWidthTablet: 240,
    paymentSidebarWidth: 400
  }
}

styledTheme.shadows = {
  userMenu: `0 0 24px ${styledTheme.colors.border}`,
  formWrapper: `0 0 16px ${styledTheme.colors.border}`,
  sidebar: `8px 0 16px ${styledTheme.colors.shadow}`,
  profileDropdownIcon: `0 0 24px ${styledTheme.colors.border}`,
  closeColorPicker: `0 0 4px ${styledTheme.colors.border}`,
  quizListItem: `0 0 6px ${styledTheme.colors.border}`,
  quizPage: `0 0 6px ${styledTheme.colors.border}`,
  card: `0 0 8px ${styledTheme.colors.darkBorder}`,
  quizMobileListItem: `0px 0px 10px 0px ${transparentize(
    '0.8',
    styledTheme.colors.primary
  )}`
}

styledTheme.radii = {
  formWrapper: 16
}

theme.colors = colors

export { theme, styledTheme }
