import * as types from 'core/redux/types/quiz'

const defaultState = {
  entries: {},
  addQuestionInProgress: false
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.REQUEST_CREATE_QUESTION:
      return {
        ...state,
        addQuestionInProgress: true
      }
    case types.CREATE_QUESTION_SUCCESS:
      return {
        ...state,
        entries: {
          ...state.entries,
          [action.data._id]: action.data
        },
        addQuestionInProgress: false
      }
    case types.CREATE_QUESTION_FAILED:
      return {
        ...state,
        addQuestionInProgress: false
      }
    default:
      return state
  }
}

export default reducer
